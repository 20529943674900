import { Plus } from "@styled-icons/boxicons-regular";
import { IPeriod, Period } from "./Period";
import { FieldArray } from "formik";
import { addMonths, format } from "date-fns";
interface Props {
  periods: IPeriod[];
  errors?: any;
  intervalo: number;
  firstMonth: string;
  lastMonth: string;
}

function ListPeriods({ periods, errors, intervalo, firstMonth, lastMonth }: Props) {
  const canAddPeriod = periods.length < intervalo;
  // Ajustar o tamanho de periods para ser igual a intervalo
  if (periods.length < intervalo) {
    while (periods.length < intervalo) {
      let currentMonth = new Date(firstMonth);
      periods.push({
        date: format(addMonths(currentMonth, periods.length + 1), "yyyy-MM"),
        price: 0,
        qtd: 0,
        unit: "",
      });
    }
  } else if (periods.length > intervalo) {
    periods.splice(intervalo);
  }

  const renderErrorMessage = () => {
    if (intervalo === 0 || isNaN(intervalo)) {
      return <p className="text-red-500 mt-2">Sem período de anúncio definido</p>;
    }
    return null;
  };

  return (
    <div className="flex flex-col sm:flex-row flex-wrap justify-center">
      <FieldArray name="periods">
        {({ push, remove }) => (
          <div className="w-full sm:w-auto">
            {/* Renderizar os períodos somente se intervalo for maior que zero */}
            {intervalo > 0 &&
              periods.map((period, index) => (
                <Period key={index} index={index} period={period} remove={() => remove(index)} errors={errors} />
              ))}

            {canAddPeriod && (
              <button
                className="text-[#479632] d-flex justify-center items-center mt-4 sm:mt-0"
                type="button"
                onClick={() => push({ date: "", price: 0, qtd: 0, unit: "" })}
              >
                <span className="mr-1">Adicionar</span>
                <Plus size={22} />
              </button>
            )}

            {renderErrorMessage()}
          </div>
        )}
      </FieldArray>
    </div>
  );
}

export default ListPeriods;
