import { Edit } from "@styled-icons/boxicons-regular";
import { useEffect, useRef, useState } from "react";
import CheckboxList from "../Components/CheckboxList";
import { Form, Formik, useFormikContext, FormikProps } from "formik";
import FormikInput from "../../../components/FormikInput";
import FormikInputMask from "../../../components/FormikInputMask";
import FormikSelect from "../../../components/FormikSelect";
import FormikTextArea from "../../../components/FormikInputTextArea";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from "../../../components/Loading";
import Schema from "../Validations/validations";
import { fetchCities } from "../../../api/cities";
import { fetchStates } from "../../../api/states";
import http from "../../../plugins/http";
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog';
import { Message } from "primereact/message";

function ModalEditWarehouse({
  warehouse,
  onEdit,
  className = "",
}: {
  warehouse: any;
  onEdit: () => void;
  className?: string;
}) {
  const [modal, setModal] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [states, setStates] = useState<any[]>([]);
  const [cities, setCities] = useState<any[]>([]);
  const [images, setImages] = useState(["", "", "", ""]);
  const [certificationsOp, setCertifications] = useState<any[]>(warehouse.datas.certifications);
  const [equipamentsOp, setEquipaments] = useState<any[]>(warehouse.datas.equipaments);
  const [load_typesOp, setLoadTypes] = useState<any[]>(warehouse.datas.load_types);
  const [operationsOp, setOperations] = useState<any[]>(warehouse.datas.operations);
  const [structuresOp, setStructures] = useState<any[]>(warehouse.datas.structures);
  const formikRef = useRef<FormikProps<typeof initialValues>>(null);
  const [fieldAdress, setFieldAdress] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };

  const isAllUnchecked = (array: any) => array.every((item: any) => item.checked === false);

  const onImageSelected = (event: any, index: any) => {
    const file = event.target.files[0];
    if (file.size > 2 * 1024 * 1024) {
      alert("O arquivo é muito grande! Por favor, selecione um arquivo menor que 2MB.");
      event.target.value = "";
      return;
    }
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const base64 = reader.result;
        const updatedImages: any = [...images];
        updatedImages[index] = base64;
        setImages(updatedImages);
      };
      reader.readAsDataURL(file);
    }
  };

  const removeImage = (index: any) => {
    const updatedImages = [...images];
    updatedImages[index] = "";
    setImages(updatedImages);
  };

  const toggleItem = (id: number, items: any[], setItems: React.Dispatch<React.SetStateAction<any[]>>) => {
    const newItems = items.map(item => (item.id === id ? { ...item, checked: !item.checked } : item));
    setItems(newItems);
  };

  const loadAddressInfo = async (e: any) => {
    let cep = e.target.value;
    cep = cep.replace(/\D/g, "");
    if (cep === "") {
      setFieldAdress(false);
      return;
    }
    if (cep !== "") {
      const validacep = /^[0-9]{8}$/;
      if (validacep.test(cep)) {
        try {
          setLoading(true);
          const ans = await http.get(`v1/public/cep/${cep}`);
          if (ans.data && !ans.data.message) {
            formikRef.current?.setFieldValue("address", ans.data.address);
            formikRef.current?.setFieldValue("neighborhood", ans.data.neighborhood);
            formikRef.current?.setFieldValue("id_state", ans.data.id_state);
            stateSelected(ans.data.id_state);
            formikRef.current?.setFieldValue("id_city", ans.data.id_city);
          }
          setLoading(false);
        } catch (error) {
          console.error("Error fetching address:", error);
        }
      }
    }
  };

  function FormButtons() {
    const { submitForm } = useFormikContext();
    return (
      <div className="w-full flex justify-end p-4 gap-x-4">
        <Button
          type="submit"
          color="success"
          onClick={submitForm}
          // disabled={loading}
          className="uppercase text-[15px] font-nunito h-[38px] font-bold  hover:bg-[#479632] bg-green-500 border-0 hover:border-0  flex flex-wrap gap-x-4"
        >
          {loading && <Loading />}
          Salvar
        </Button>
        <Button
          onClick={toggle}
          disabled={loading}
          className="uppercase text-[15px] font-nunito h-[38px] font-bold text-[#146600] hover:text-white  bg-white hover:!bg-[#85D46C] border-1 hover:border-0"
        >
          Cancelar
        </Button>
      </div>
    );
  }

  const initialValues = {
    //dados cadastrais
    fantasy_name: warehouse.fantasy_name,
    social_reason: warehouse.social_reason,
    cnpj: warehouse.cnpj,
    id_state: warehouse.state?.id,
    id_city: warehouse.city?.id,
    cep: warehouse.cep,
    address: warehouse.address,
    neighborhood: warehouse.neighborhood,
    number: warehouse.number,
    complement: warehouse.complement,
    //dados de contato
    responsible: warehouse.contacts?.responsible,
    role: warehouse.contacts?.role,
    contact_1: warehouse.contacts?.contact_1,
    contact_2: warehouse.contacts?.contact_2,
    email: warehouse.contacts?.email,
    //observação
    obs: warehouse.obs,
    //datas
    images: "",
    measure: warehouse.datas.measures,
    //json de data e contacts
    contacts: "",
    datas: "",
  };

  function showError(message: string) {
    setError(message);
    setTimeout(() => {
      setError("");
    }, 3000);
  }

  const edit = async (values: any) => {
    try {
      setLoading(true);
      const contacts = {
        responsible: values.responsible,
        role: values.role,
        contact_1: values.contact_1,
        contact_2: values.contact_2,
        email: values.email,
      };

      const datas = {
        measures: values.measure,
        load_types: load_typesOp,
        structures: structuresOp,
        equipaments: equipamentsOp,
        operations: operationsOp,
        certifications: certificationsOp,
      };

      if (images.some((image: any) => image === "")) {
        showError("Todas as imagens devem ser preenchidas");
        return;
      }
      if (isAllUnchecked(load_typesOp)) {
        showError("Selecione pelo menos um tipo de carga");
        return;
      }
      if (isAllUnchecked(structuresOp)) {
        showError("Selecione pelo menos uma característica da estrutura");
        return;
      }
      if (isAllUnchecked(equipamentsOp)) {
        showError("Selecione pelo menos um tipo de equipamento");
        return;
      }
      if (isAllUnchecked(operationsOp)) {
        showError("Selecione pelo menos um tipo de operação");
        return;
      }
      if (isAllUnchecked(certificationsOp)) {
        showError("Selecione pelo menos uma certificação");
        return;
      }
      const { responsible, role, contact_1, contact_2, email, measure, ...rest } = values;
      const valores = { ...rest, contacts: contacts, datas: datas };
      JSON.parse(JSON.stringify(contacts));
      JSON.parse(JSON.stringify(datas));
      const response = await http.put(`v1/warehouses/${warehouse.id}`, { ...valores });
      if (response.status === 200) {
        for (let i = 0; i < images.length; i++) {
          if (typeof images[i] === "string")
            await http.put(`v1/warehouse-images/${warehouse.images[i].id}`, { image: images[i] });
        }
        toggle();
        setImages(["", "", "", ""]);
        if (onEdit != null) onEdit();
      } else if (response.data.hasOwnProperty("message")) {
        showError(response.data.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  async function stateSelected(id: number) {
    setLoading(true);
    initialValues.id_city = "";
    fetchCities({ id_state: id }).then(res => {
      setCities(res.data);
      setLoading(false);
    });
  }

  useEffect(() => {
    setImages([...warehouse.images]);
  }, [warehouse.images]);

  useEffect(() => {
    setLoading(true);
    fetchStates().then(res => {
      setStates(res.data);
      fetchCities({ id_state: warehouse.state?.id }).then(res => {
        setCities(res.data);
      });
      setLoading(false);
    });
  }, [warehouse.state?.id, warehouse.city?.id]);

  const footer = (
    <div className="w-full flex justify-end p-4 gap-x-4">
      <Button
        label="Fechar"
        onClick={toggle}
        className="uppercase text-[15px] font-nunito h-[38px] font-bold text-[#146600] hover:text-white bg-white hover:bg-[#85D46C] border-1 hover:border-0"
      />
    </div>
  );

  return (
    <div className="inline-flex">
      <Edit onClick={toggle} size={25} color="#1F5974" className="cursor-pointer ml-2" />
      <Dialog visible={modal} onHide={toggle} header="Editar Armazém" footer={footer} className="w-full md:w-2/3" modal>
        <div className="px-4 font-roboto">
          <Formik
            innerRef={formikRef}
            initialValues={initialValues}
            validationSchema={Schema}
            onSubmit={(values, actions) => {
              edit(values);
            }}
            key={"form-register-warehouse"}
          >
            <>
              <Form className="flex flex-wrap p-10 h-96 overflow-y-auto">
                <div className="w-1/2 pr-2 space-y-8">
                  <div className="dados_cadastrais space-y-4">
                    <label htmlFor="" className="text-lg text-[#146600]">
                      Dados Cadastrais
                    </label>
                    <FormikInput
                      className="w-full"
                      name="social_reason"
                      label="Razão Social"
                      placeholder="Digite a razão social"
                    />
                    <FormikInput
                      className="w-full"
                      name="fantasy_name"
                      label="Nome Fantasia"
                      placeholder="Digite o nome fantasia"
                    />
                    <FormikInputMask
                      mask="99.999.999/9999-99"
                      className="w-full"
                      name="cnpj"
                      label="CNPJ"
                      placeholder="Digite o CNPJ (apenas números)"
                    />
                    <div className="flex space-x-4">
                      <FormikSelect
                        label="Estado"
                        name="id_state"
                        className="w-1/2"
                        options={states?.map(state => ({ label: state?.name, value: state?.id }))}
                        onChange={e => stateSelected(parseInt(e))}
                        isDisabled={fieldAdress}
                      />
                      <FormikSelect
                        label="Cidade"
                        name="id_city"
                        className="w-1/2"
                        options={cities.length > 0 ? cities?.map(city => ({ label: city.name, value: city.id })) : []}
                        isDisabled={fieldAdress}
                      />
                      <FormikInputMask
                        mask="99999-999"
                        label="CEP"
                        name="cep"
                        className="w-1/2"
                        placeholder="Digite o CEP"
                        onChange={loadAddressInfo}
                      />
                    </div>
                    <div className="flex space-x-4">
                      <FormikInput
                        className="w-full"
                        name="address"
                        label="Endereço"
                        placeholder="Digite o endereço"
                        readOnly={fieldAdress}
                      />
                      <FormikInput
                        className="w-full"
                        name="neighborhood"
                        label="Bairro"
                        placeholder="Digite o bairro"
                        readOnly={fieldAdress}
                      />
                    </div>
                    <div className="flex space-x-4">
                      <FormikInput className="w-1/3" name="number" label="Número" placeholder="Digite o número" />
                      <FormikInput
                        className="w-2/3"
                        name="complement"
                        label="Complemento"
                        placeholder="Digite o complemento"
                      />
                    </div>
                  </div>
                  <div className="contact space-y-4">
                    <label htmlFor="" className="w-full text-lg text-[#146600]">
                      Dados de Contato
                    </label>
                    <div className="flex space-x-4">
                      <FormikInput
                        className="w-1/2"
                        name="responsible"
                        label="Responsável"
                        placeholder="Digite o nome do responsável"
                      />
                      <FormikInput className="w-1/2" name="role" label="Cargo" placeholder="Digite o cargo" />
                    </div>
                    <div className="flex space-x-9">
                      <FormikInputMask
                        mask="(99) 9999-9999"
                        className="w-1/2"
                        name="contact_1"
                        label="Contato 1"
                        placeholder="Digite o contato"
                      />
                      <FormikInputMask
                        mask="(99) 9999-9999"
                        className="w-1/2"
                        name="contact_2"
                        label="Contato 2"
                        placeholder="Digite o contato"
                      />
                    </div>
                    <div className="flex">
                      <FormikInput className="w-full" name="email" label="E-mail" placeholder="Digite o e-mail" />
                    </div>
                  </div>
                  <div className="flex w-full items-center space-y-8">
                    <div className="flex-grow h-px bg-gray-300"></div>
                  </div>
                  <div className="tipo_carga space-y-4">
                    <div className="space-y-4">
                      <label htmlFor="" className="w-full text-lg text-[#146600]">
                        Tipo de Carga
                      </label>
                      <div className="ml-2">
                        <CheckboxList
                          items={load_typesOp}
                          onToggle={id => toggleItem(id, load_typesOp, setLoadTypes)}
                        />
                      </div>
                      <FormikTextArea label="Observações ou Informações adicionais" name="obs" rows={7} />
                    </div>
                  </div>
                </div>
                <div className="w-1/2 pl-10 space-y-4">
                  <div className="photos space-y-2">
                    <label htmlFor="" className="text-lg text-[#146600]">
                      Fotos(mín. 04 fotos com 2mb cada)
                    </label>
                    <div className="grid grid-cols-2 lg:grid-cols-4 gap-2 relative border border-gray-400 m-4 rounded-md pt-2.5 pb-5 px-1.5 mx-0.5 ">
                      {images.map((image: any, index: any) => (
                        <div key={index} className="text-center">
                          <div
                            className={`relative border border-gray-400 rounded-md ${index === 0 ? "h-[102px]" : "h-[78px]"
                              }`}
                          >
                            <FontAwesomeIcon
                              icon={faImage}
                              className="text-green-600 text-2xl opacity-60 absolute top-4 left-1/2 transform -translate-x-1/2 pointer-events-none z-10"
                            />
                            <input
                              className="w-full h-full opacity-0 cursor-pointer"
                              type="file"
                              name={`image${index + 1}`}
                              accept="image/png, image/jpeg"
                              onChange={e => onImageSelected(e, index)}
                            />
                            <img
                              className={`w-full h-full object-cover absolute top-0 left-0 pointer-events-none z-20 ${image ? "block" : "hidden"
                                }`}
                              src={image?.url ? 'https://api.bioconex.com.br' + image.url : "" || image}
                              alt="preview"
                            />
                          </div>
                          <button
                            onClick={() => removeImage(index)}
                            type="button"
                            className={`text-red-500 mt-1 ${image ? "block" : "hidden"}`}
                          >
                            Remover
                          </button>
                        </div>
                      ))}
                    </div>
                    <FormikInput
                      className="w-full"
                      name="measure"
                      label="Medidas do armazém em m²"
                      placeholder="Insira as medidas"
                      type="text"
                    />
                  </div>
                  <div className="space-y-2">
                    <label htmlFor="structures" className="text-[#848484]">
                      Estrutura
                    </label>
                    <div>
                      <CheckboxList items={structuresOp} onToggle={id => toggleItem(id, structuresOp, setStructures)} />
                    </div>
                  </div>
                  <div className="space-y-2">
                    <label htmlFor="equipament" className="text-[#848484]">
                      Equipamento
                    </label>
                    <div>
                      <CheckboxList
                        items={equipamentsOp}
                        onToggle={id => toggleItem(id, equipamentsOp, setEquipaments)}
                      />
                    </div>
                  </div>
                  <div className="space-y-4">
                    <label htmlFor="equipament" className="text-[#848484]">
                      Operação
                    </label>
                    <div>
                      <CheckboxList items={operationsOp} onToggle={id => toggleItem(id, operationsOp, setOperations)} />
                    </div>
                  </div>
                  <div className="flex w-full items-center space-y-4">
                    <div className="flex-grow h-px bg-gray-300"></div>
                  </div>
                  <div className="certificacoes space-y-4">
                    <div className="space-y-4">
                      <label htmlFor="" className="w-full text-lg text-[#146600]">
                        Certificações
                      </label>
                      <div className="ml-2">
                        <CheckboxList
                          items={certificationsOp}
                          onToggle={id => toggleItem(id, certificationsOp, setCertifications)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
              <FormButtons />
              {error !== "" && (
                <Message className="w-full mt-4" color="danger">
                  {error}
                </Message>
              )}
            </>
          </Formik>
        </div>
      </Dialog>
    </div>
  );
}

export default ModalEditWarehouse;
