import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

interface ModalProps {
  state: boolean;
  title: string;
  body: string;
  color: 'success' | 'danger' | 'warning' | 'info';
  onClose: () => void;
}

function ModalAlert({ state = false, title, body, onClose, color }: ModalProps) {
  const titleColor = {
    success: 'text-green-500',
    danger: 'text-red-500',
    warning: 'text-yellow-500',
    info: 'text-blue-500',
  };

  const buttonClass = {
    success: 'p-button-success',
    danger: 'p-button-danger',
    warning: 'p-button-warning',
    info: 'p-button-info',
  };

  return (
    <Dialog visible={state} onHide={onClose} headerClassName={"font-bold text-[18px] font-roboto " + titleColor[color]} className="w-full md:w-2/3" modal>
      <div className="px-4 font-roboto">
        <h2 className={"font-bold text-[18px] font-roboto " + titleColor[color]}>{title}</h2>
        <p>{body}</p>
      </div>
      <div className="flex justify-end mt-4">
        <Button label="Fechar" onClick={onClose} className={buttonClass[color]} />
      </div>
    </Dialog>
  );
}

export default ModalAlert;
