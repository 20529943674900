import { Form, Formik, FormikProps, useFormikContext } from "formik";
import { useEffect, useRef, useState } from "react";
import FormikInput from "../../../../components/FormikInput";
import FormikSelect from "../../../../components/FormikSelect";
import { fetchCities } from "../../../../api/cities";
import FormikInputMask from "../../../../components/FormikInputMask";
import bancos from "../../../../assets/json/bancos.json";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Message } from "primereact/message";
import { Eye } from "@styled-icons/fa-solid";

function ModalUserDetail({ user, onEdit, className = "", roles, states }: { user: any; onEdit: () => void; className?: string, roles: any[], states: any[] }) {
  const [modal, setModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [cities, setCities] = useState<any[]>([]);
  const [setCommision] = useState<number>(user.commision_percentage);
  const [isRepresentativeSelected, setIsRepresentativeSelected] = useState(false);
  const [role, setRole] = useState(user.role.name);
  const formikRef = useRef<FormikProps<typeof initialValues>>(null);

  const toggle = () => {
    const isRepresentative = user.role.name === "representative";
    setRole(isRepresentative ? "Representante" : "");
    setIsRepresentativeSelected(isRepresentative);
    setModal(prevModal => !prevModal);
    if (!modal && user.id_state) {
      fetchCities({ id_state: user.id_state }).then((res: any) => {
        setCities(res.data);
      });
    }
  };


  let initialValues = {
    name: user.name,
    cpf_or_cnpj: user.cpf_or_cnpj,
    email: user.email,
    password: "",
    confirm_password: "",
    phone: user.phone,
    id_state: user.state?.id,
    id_city: user.city?.id,
    id_role: user.role.id,
    business_profile: user.business_profile,
    bank_name: user.bank_name,
    id_area: user.area?.id,
    bank_agency: user.bank_agency,
    commision_percentage: user.commision_percentage,
    bank_account: user.bank_account,
    pix_key: user.pix_key,
  };

  const perfilComercial = [
    { label: "Bebidas", value: "Bebidas" },
    { label: "Alimentos", value: "Alimentos" },
    { label: "Fármaco", value: "Fármaco" },
    { label: "Varejo", value: "Varejo" },
    { label: "Saúde", value: "Saúde" },
  ];

  useEffect(() => {
    if (user.role.name === "representative") {
      setRole("Representante");
      setIsRepresentativeSelected(true);
    }
  }, [user.role.name]);

  const getMaskCpfOrCnpjFormat = (value?: string) => {
    if (!value) return "999.999.999-99";
    value = value.replace(/\D/g, "");
    return value.length === 11 ? "999.999.999-99" : "99.999.999/9999-99";
  }


  return (
    <div className="inline-flex">
      <Eye
        onClick={toggle} size={25} className="cursor-pointer  text-yellow-500" />

      <Dialog header={"Dados do usuário  #" + user.id} maximizable visible={modal} onHide={toggle} className={className}>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          onSubmit={(values, actions) => {
          }}
          key={"form-edit-user"}
        >
          <>
            <Form
              className="flex flex-wrap space-y-2 p-4"
              style={isRepresentativeSelected ? { height: "40rem", overflowY: "scroll" } : {}}
            >
              <h3 className="font-bold">Dados pessoais</h3>
              <FormikInput disabled className="w-full" name="name" label="Nome completo" placeholder="Digite o nome" />
              <div className="w-full grid grid-cols-2 gap-2 pb-4">
                <FormikInput disabled defaultValue={user?.role?.public_name} name="role" label="Perfil" />
                <FormikInputMask
                  mask={getMaskCpfOrCnpjFormat(user?.cpf_or_cnpj)}
                  name="cpf_or_cnpj"
                  label="CNPJ"
                  placeholder="Digite o CNPJ"
                  disabled
                />

                <FormikInput disabled name="email" label="Email" placeholder="Digite o email" />
                <FormikInputMask
                  name="phone"
                  mask="(99) 99999-9999"
                  label="Celular"
                  placeholder="Digite o número de celular"
                  disabled
                />{" "}
                <FormikInput disabled defaultValue={user?.state?.name} name="state" label="Estado" />
                <FormikInput disabled defaultValue={user?.city?.name} name="city" label="Cidade" />
              </div>
              <h3 className="font-bold mt-4">Dados do perfil integrador</h3>
              <FormikInput disabled className="w-full" defaultValue={user.integration?.name} name="name" label="Razão Social" />
              <div className="w-full grid grid-cols-2 gap-2">
                <FormikInputMask
                  mask={getMaskCpfOrCnpjFormat(user.integration?.cpf_or_cnpj)}
                  name="user.integration.cpf_or_cnpj"
                  label="CNPJ"
                  value={user.integration?.cpf_or_cnpj}
                  placeholder="Digite o CNPJ"
                  disabled
                />
                <FormikInputMask
                  name="user.integration.phone"
                  value={user.integration?.phone}
                  mask="(99) 99999-9999"
                  label="Celular"
                  placeholder="Digite o número de celular"
                  disabled
                />{" "}
              </div>
              <div className="grid grid-cols-2 w-full gap-2">
                <FormikInput disabled defaultValue={user.integration?.state?.name} name="state" label="Estado" />
                <FormikInput disabled defaultValue={user.integration?.city?.name} name="city" label="Cidade" />
                <FormikInput className="col-span-2" disabled defaultValue={user.integration?.address} name="address" label="Endereço" />
                <FormikInput disabled defaultValue={user.integration?.number} name="number" label="Número" />
                <FormikInput disabled defaultValue={user.integration?.neighborhood} name="neighborhood" label="Bairro" />
                <FormikInput disabled defaultValue={user.integration?.cep} name="cep" label="CEP" />
              </div>

              {role === "Representante" && (
                <>
                  <div className="flex w-full items-center space-x-50">
                    <div className="flex-grow h-px bg-gray-300"></div>
                  </div>
                  <div className="flex w-full items-start space-x-40">
                    <div className="flex items-center mr-5">
                      <h2 style={{ color: "#146600" }}>Dados Comerciais</h2>
                    </div>
                    <div className="flex items-center">
                      <h2 className="font-sans Roboto font-normal ml-12" style={{ color: "#146600" }}>
                        Dados Bancários
                      </h2>
                    </div>
                  </div>
                  <div className="flex w-full space-x-10">
                    <FormikSelect
                      label="Perfil Comercial"
                      name="business_profile"
                      className="w-1/2"
                      options={perfilComercial}
                    />
                    <FormikSelect
                      className="w-1/2"
                      name="bank_name"
                      label="Banco"
                      placeholder="Digite o nome do banco"
                      options={bancos}
                    />
                  </div>
                  <div className="flex w-full space-x-10">
                    <FormikSelect
                      label="Área/Região"
                      name="id_area"
                      defaultValue="user.area.id"
                      className="w-1/2"
                      options={cities.length > 0 ? cities?.map(city => ({ label: city.name, value: city.id })) : []}
                    />
                    <FormikInput
                      className="w-1/2"
                      name="bank_agency"
                      label="Agência"
                      placeholder="Digite a agência"
                    />
                  </div>
                  <div className="flex w-full space-x-10">
                    <div className="w-1/2"></div>
                    <FormikInput
                      className="w-1/2"
                      name="pix_key"
                      label="Chave pix"
                      placeholder="Digite a chave pix"
                    />
                  </div>
                </>
              )}
            </Form>
          </>
        </Formik>
      </Dialog>
    </div>
  );
}

export default ModalUserDetail;
