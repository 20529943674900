import { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import http from "../../../plugins/http";
import TablePagination from "../../../components/TablePagination";
import Loading from "../../../components/Loading";
import { useToast } from "../../../components/Toast";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Message } from "primereact/message";
import { BreadCrumb } from "primereact/breadcrumb";

const ProductsTable: React.FC = () => {
  const today = new Date();
  const toast = useToast();
  const prevMonth = new Date();
  prevMonth.setMonth(today.getMonth() - 1);
  const [users, setUsers] = useState([]);
  const [meta, setMeta] = useState<any>({});
  const [productGroups, setProductGroups] = useState<any[]>([]);
  const [productTypes, setProductTypes] = useState<any[]>([]);
  const [productSubtypes, setProductSubtypes] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState<any>({});
  const [filters, setFilters] = useState({
    dateInitial: prevMonth.toISOString().split("T")[0],
    dateFinal: new Date().toISOString().split("T")[0],
    product_group: null,
    product_type: null,
    product_subtype: null,
    status: 99,
    page: 1,
    perPage: 10,
  });


  const situations = [
    { public_name: "Todos", value: 99 },
    { public_name: "Ativados", value: 2 },
    { public_name: "Desativado", value: 0 },
    { public_name: "Aguardando", value: 1 },
    { public_name: "Rejeitado", value: 3 },
  ]

  async function fetchProducts({
    page = currentPage,
    perPage = 10,
    search,
  }: { page?: number; perPage?: number; search?: string } = {}) {
    try {
      setLoading(true);
      const response = await http.post("v1/reports/products", {
        ...filters,
        page,
        perPage,
        search,
      });
      if (response.status === 200) {
        setUsers(response.data.data);
        setMeta(response.data.meta);
        totalizer()
      }
    } catch (error) {
      console.log(error);
    } finally {
      setCurrentPage(page)
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchProducts();
    fetchProductGroups();
  }, []);


  const handleFilterChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "product_group") {
      setProductSubtypes([]);
      if (value.id === null) {
        setProductTypes([{ id: null, name: "Todos" }]);
        setProductSubtypes([{ id: null, name: "Todos" }]);
      } else {
        setProductTypes([{ id: null, name: "Todos" }, ...value.groups]);
      }
    } else if (name === "product_type") {
      if (value.id === null) {
        setProductSubtypes([{ id: null, name: "Todos" }]);
      } else {
        setProductSubtypes([{ id: null, name: "Todos" }, ...value.types]);
      }
    }
    setFilters({ ...filters, [name]: value });
  };

  const generateReport = async () => {
    http.post("v1/reports/export-products", filters, {
      responseType: 'blob',
    }).then((response: any) => {
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'relatorio-produtos.xlsx'; // Nome do arquivo
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
      toast.current?.show({
        severity: "success",
        summary: "Sucesso",
        detail: "Relatório gerado com sucesso",
      });
    }).catch((error) => {
      console.log(error);
      toast.current?.show({
        severity: "error",
        summary: "Erro",
        detail: "Erro ao gerar relatório",
      });
    });
  }

  const statusTemplate = (rowData: any) => {
    if (rowData.status === 0) {
      return <p className="text-blue-500">Desativado</p>
    }
    if (rowData.status === 1) {
      return <p className="text-orange-500">Aguardando</p>
    }
    if (rowData.status === 2) {
      return <p className="text-green-500">Aprovado</p>
    }
    if (rowData.status === 3) {
      return <p className="text-red-500">Rejeitado</p>
    }

    return <p className="text-blue-500">-</p>
  };

  const dateTemplate = (rowData: any) => {
    return (
      <div className="text-center">
        {new Date(rowData.created_at).toLocaleDateString()}
      </div>
    );
  };

  async function fetchProductGroups() {
    try {
      setLoading(true);
      const response = await http.get("v1/product-groups?all=1");
      if (response.status === 200) {
        setProductGroups([{ id: null, name: "Todos" }, ...response.data]);
      }
      return []
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const totalizer = () => {
    let totais: any = {}
    users.forEach((user: any) => {
      if (!totais[user?.product_type?.name]) {
        totais[user?.product_type?.name] = 0
      }
      totais[user?.product_type?.name] += user?.detail?.qtd ?? 0
      totais[user?.product_type?.name + '-unit'] = user?.detail?.unit_of_measurement ?? ''
      totais[user?.product_type?.name + '-group'] = user?.product_group?.name ?? ''
    })

    for (const key in totais) {
      if (key.includes('-unit')) {
        continue
      }
      totais[key] = totais[key + '-group'] + totais[key] ?? ''
      totais[key] = totais[key] + ' ' + totais[key + '-unit'] ?? ''

      delete totais[key + '-unit']
    }

    setTotal(totais)
  }

  const items = [{ label: 'Relatórios', url: '/reports' }, { label: 'Relatório de Produtos' }];
  const home = { icon: 'pi pi-home', url: '/home' }

  return (
    <div className="w-full p-10">
      <BreadCrumb model={items} home={home} className="mb-4" />
      <div className="flex space-x-5">
        <div className="w-full grid grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
          <div className="flex flex-col pr-2">
            <label className="text-md font-bold mb-1">Cadeia</label>

            <Dropdown name="product_group" value={filters.product_group} options={productGroups} onChange={handleFilterChange} optionLabel="name" className="!w-full" placeholder="Cadeia" />
          </div>
          <div className="flex flex-col pr-2">
            <label className="text-md font-bold mb-1">Tipo</label>
            <Dropdown name="product_type" value={filters.product_type} options={productTypes} onChange={handleFilterChange} disabled={!filters.product_group} optionLabel="name" className="!w-full" placeholder="Tipo" />
          </div>
          <div className="flex flex-col pr-2">
            <label className="text-md font-bold mb-1">Subtipo</label>
            <Dropdown name="product_subtype" value={filters.product_subtype} options={productSubtypes} onChange={handleFilterChange} disabled={!filters.product_type} optionLabel="name" className="!w-full" placeholder="Subtipo" />
          </div>

          <div className="flex flex-col pr-2">
            <label className="text-md font-bold mb-1">Data Inicial</label>
            <InputText
              value={filters.dateInitial}
              name="dateInitial"
              onChange={handleFilterChange}
              type="date"
            />
          </div>
          <div className="flex flex-col pr-2">
            <label className="text-md font-bold mb-1">Data Final</label>
            <InputText
              value={filters.dateFinal}
              name="dateFinal"
              onChange={handleFilterChange}
              type="date"
            />
          </div>
          <div className="flex flex-col pr-2">
            <label className="text-md font-bold mb-1">Situação</label>
            <Dropdown name="status" value={filters.status} options={situations} onChange={handleFilterChange} optionLabel="public_name" placeholder="Situação" className="!w-full" />
          </div>
          <div className="flex  flex-row">
            <div className="flex flex-col w-full">
              <label className="text-md font-bold mb-1 pr-2">&nbsp;</label>
              <Button
                label="Filtrar"
                disabled={loading}
                onClick={() => fetchProducts()}
                severity="success"
              />
            </div>
            <div className="flex flex-col w-full pl-2">
              <label className="text-md font-bold mb-1">&nbsp;</label>
              <Button
                label="Exportar"
                disabled={loading}
                onClick={generateReport}
                severity="success"
              />
            </div>
          </div>
        </div>

      </div>

      {loading && (
        <div className="w-full h-36 grid place-content-center p-20">
          <Loading color="#212529" size="w-10" />
        </div>
      )}
      {!loading && users.length === 0 && (
        <div className="w-full h-36 grid place-content-center p-20">
          <p>Nenhum produto encontrado com os filtros aplicados</p>
        </div>
      )}
      {!loading && users.length > 0 && (
        <div className="h-full w-full mt-10 overflow-auto">
          {/* {total && Object.keys(total).map((key) => {
            return <Message severity="info" className="w-full mb-2 text-left" text={`Total de ${key}: ${total[key]}`} />
          })} */}

          <DataTable value={users} className="w-full font-roboto">
            <Column field="name" header="Nome" />
            <Column body={(row) => <span>{row?.product_type?.name}</span>} header="Tipo" />
            <Column body={(row) => <span>{row?.product_subtype?.name}</span>} header="Subtipo" />
            <Column body={(row) => <span>{row?.detail?.qtd} {row?.detail?.unit_of_measurement}</span>} header="Estoque atual" />
            <Column body={(row) => <span>{row?.user_full?.name}</span>} header="Vendedor" />
            <Column field="state.name" header="Estado" />
            <Column field="city.name" header="Cidade" />
            <Column body={statusTemplate} header="Situação" />
            <Column body={dateTemplate} header="Data de Cadastro" />
          </DataTable>
          <TablePagination
            meta={meta}
            onChange={page => fetchProducts(page)} />
        </div>
      )}
    </div>
  );
};

export default ProductsTable;
