import { Search } from "@styled-icons/evaicons-solid";
import ErrorMessage from "../ErrorMessage";
import { ISearchBar } from "./ISearchBar";
import { useEffect, useState, useImperativeHandle, forwardRef } from "react";
import tw from "twin.macro";

const SearchInput = tw.input`!border !border-[#479632] focus:border-[#479632] focus:!outline-none rounded-none p-2 h-[38px] bg-white`;
const SearchButton = tw.button`w-10 h-[38px] bg-[#479632] hover:bg-green-500 disabled:bg-[#CCCCCC] grid place-content-center cursor-pointer disabled:cursor-none`;

const SearchBar = forwardRef(
  (
    {
      name = "search-bar",
      label,
      placeholder,
      error = "Digite pelo menos 3 caracteres",
      style,
      classname = "ml-4",
      onSearch,
      width,
    }: ISearchBar,
    ref,
  ) => {
    const [search, setSearch] = useState("");
    const [searchError, setSearchError] = useState(false);

    function onPressSearch() {
      onSearch(search);
    }

    function handleSearch(e: React.KeyboardEvent<HTMLInputElement>) {
      if (e.key === "Enter") onPressSearch();
    }

    useImperativeHandle(ref, () => ({
      reset() {
        setSearch("");
        setSearchError(false);
      },
    }));

    useEffect(() => {
      if (search.length < 3 && search.length > 0) {
        setSearchError(true);
      } else {
        setSearchError(false);
      }
    }, [search]);

    return (
      <div>
        <div className={`flex items-center ${classname}`} style={{ ...style }}>
          <SearchInput
            aria-label={label}
            type="text"
            name={name}
            value={search}
            onChange={e => setSearch(e.target.value)}
            placeholder={placeholder}
            onKeyDown={handleSearch}
            style={{ width }}
          />
          <SearchButton name="search-btn" disabled={searchError} onClick={onPressSearch}>
            <Search size="20" className="fill-white" />
          </SearchButton>
        </div>
        {searchError && (
          <div className="ml-4">
            <ErrorMessage message={error} />
          </div>
        )}
      </div>
    );
  },
);

export default SearchBar;
