interface CheckboxListProps {
  items: { id: number; label: any; checked: boolean }[];
  onToggle: (id: number) => void;
}

const CheckboxList: React.FC<CheckboxListProps> = ({ items, onToggle }) => (
  <div>
    {items.map(item => (
      <div className="flex space-x-2" key={item.id}>
        <input type="checkbox" id={`checkbox-${item.id}`} checked={item.checked} onChange={() => onToggle(item.id)} />
        <label htmlFor={`checkbox-${item.id}`}>{item.label.pt}</label>
      </div>
    ))}
  </div>
);

export default CheckboxList;
