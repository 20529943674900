import { useState } from "react";

import FormikSelect from "../FormikSelect";
import { weightUnitOptions } from "./utils";
import FormikInput from "../FormikInput";

export interface IPeriod {
  date: string;
  price: number;
  qtd: number;
  unit: string;
  value?: string;
}

interface Props {
  index: number;
  period: IPeriod;
  remove?: () => void;
  errors?: any;
  value?: string;
}

export const Period = ({ index, remove, errors, value }: Props) => {
  const [unitsOptions] = useState(weightUnitOptions);

  //função que verifica se o número é maior que zero
  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.valueAsNumber;
    if (value <= 0 && isNaN(value)) {
      value = 0;
    }
    e.target.value = value.toString();
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 gap-2 mb-4">
      <div className="col-span-2 md:col-span-2">
        <FormikInput name={`periods.${index}.date`} label="Mês: " className="w-full" type="month" value={value} readOnly={true} />
      </div>
      <div className="col-span-1 md:col-span-1">
        <FormikInput
          label="Quantidade: "
          name={`periods.${index}.qtd`}
          type="number"
          className="w-full"
          placeholder="00000"
          onChanged={handleChangeInput}
          min={"0"}
        />
      </div>

      <div className="col-span-1 md:col-span-1">
        <FormikSelect
          name={`periods.${index}.unit`}
          label="Unidade: "
          className="w-full"
          options={unitsOptions}
          placeholder="Escolha"
          errors={errors?.periods?.[index]?.unit}
        />
      </div>

      <div className="col-span-1 md:col-span-1">
        <FormikInput
          name={`periods.${index}.price`}
          label="Valor/Mês: "
          type="number"
          className="w-full"
          placeholder="0,00"
          onChanged={handleChangeInput}
        />
      </div>
    </div>
  );
};
