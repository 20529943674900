import { Button } from "primereact/button";
import DataPrivacyControl from "../../components/DataPrivacyControl/DataPrivacyControl";
import { useEffect, useState } from "react";
import http from "../../plugins/http";
import ModalError from "./ModalError/ModalError";
import { useToast } from "../../components/Toast";
import { Link } from "react-router-dom";

const DataCategorization: React.FC = () => {
  const toast = useToast();
  const [datas, setDatas] = useState<any[]>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [CheckboxElements, setCheckboxElements] = useState<any[]>([]);
  const [groupValue, setGroupValue] = useState<string | undefined>();
  const [groupValue2, setGroupValue2] = useState<string | undefined>();
  const [message, setMessage] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);

  async function fetchDatas({
    page = 1,
    perPage = 10,
    search,
  }: { page?: number; perPage?: number; search?: string } = {}) {
    try {
      // setLoading(true);
      const response = await http.get("/v1/dashboards?all=y");
      const sorted = response.data.sort(function (a: any, b: any) {
        var c: any = new Date(a.updated_at);
        var d: any = new Date(b.updated_at);
        return d - c;
      });
      setDatas(sorted);
    } catch (error) {
      console.log(error);
    } finally {
      // setLoading(false);
    }
  }

  async function updatePrivacyDashboards() {
    if (groupValue === undefined && groupValue2 === undefined) {
      toast.current?.show({
        severity: "error",
        summary: "Erro",
        detail: `A privacidade de pelo menos 1 site deve ser selecionada!`,
      });

      return;
    }

    if (selectedRows.length === 0) {
      toast.current?.show({
        severity: "error",
        summary: "Erro",
        detail: `Selecione ao menos um dashboard!`,
      });
      return;
    }

    let qtd = 0;

    for (let data of selectedRows) {

      qtd++;

      const upt = {
        type: {
          public_bioconex: groupValue === undefined ? data.type.public_bioconex : groupValue === "publico",
          private_bioconex: groupValue === undefined ? data.type.private_bioconex : groupValue === "privado",
          public_BI: groupValue2 === undefined ? data.type.public_BI : groupValue2 === "publico",
          private_BI: groupValue2 === undefined ? data.type.private_BI : groupValue2 === "privado",
        },
        pages: data.pages,
      };
      await http.put("/v1/dashboards/" + data.id, upt);
    }
    await fetchDatas();
    toast.current?.show({
      severity: "success",
      summary: "Sucesso",
      detail: `Privacidade de ${qtd} dashboards alterados.`,
    });
  }

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    const fetchData = async () => {
      await fetchDatas();
    };
    fetchData();
  }, []);

  return (
    <div className="2xl:page-height p-10 2xl:overflow-auto">
      <ModalError isOpen={isOpen} closeModal={closeModal} message={message}></ModalError>
      <DataPrivacyControl
        setSelectedRows={setSelectedRows}
        data={datas}
        setCheckboxes={setCheckboxElements}
        setGroupValue={setGroupValue}
        groupValue={groupValue}
        setGroupValue2={setGroupValue2}
        groupValue2={groupValue2}
      ></DataPrivacyControl>
      <div className="flex justify-end space-x-5 pt-2">
        <Button
          severity="success"
          onClick={updatePrivacyDashboards}
        >
          SALVAR DADOS
        </Button>
      </div>
    </div>
  );
};

export default DataCategorization;
