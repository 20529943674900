import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputNumber } from 'primereact/inputnumber';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { fetchCities } from '../../api/cities';
import { Dropdown } from 'primereact/dropdown';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { Divider } from 'primereact/divider';
import validationSchema from "./validations";
import { Dialog } from "primereact/dialog";
import { ICityData, IStateData } from "../../interfaces/endpoints/models";
import { InputText } from "primereact/inputtext";

interface FreightAddProps {
  onSubmit: (data: FreightData) => void;
  initialData?: FreightData;
  states: IStateData[];
}

interface FreightData {
  id?: number;
  id_state_origin: number;
  id_city_origin: number;
  id_state_destination: number;
  id_city_destination: number;
  cep_origin: string;
  cep_destination: string;
  time_in_days: number;
  transport_value_including_insurance: number;
  invoice_value: number;
  weight_kg: number;
  volume_m3: number;
  saidaDomingo: boolean;
  saidaSegunda: boolean;
  saidaTerca: boolean;
  saidaQuarta: boolean;
  saidaQuinta: boolean;
  saidaSexta: boolean;
  saidaSabado: boolean;
}

const FreightAdd: React.FC<FreightAddProps> = ({ onSubmit, initialData, states }) => {

  const defaultValues: FreightData = {
    id: initialData?.id || 0,
    id_city_origin: initialData?.id_city_origin || 0,
    id_state_origin: initialData?.id_state_origin || 0,
    id_city_destination: initialData?.id_city_destination || 0,
    id_state_destination: initialData?.id_state_destination || 0,
    cep_origin: initialData?.cep_origin || '',
    cep_destination: initialData?.cep_destination || '',
    time_in_days: initialData?.time_in_days || 0,
    transport_value_including_insurance: initialData?.transport_value_including_insurance || 0,
    invoice_value: initialData?.invoice_value || 0,
    weight_kg: initialData?.weight_kg || 0,
    volume_m3: initialData?.volume_m3 || 0,
    saidaDomingo: initialData?.saidaDomingo || false,
    saidaSegunda: initialData?.saidaSegunda || false,
    saidaTerca: initialData?.saidaTerca || false,
    saidaQuarta: initialData?.saidaQuarta || false,
    saidaQuinta: initialData?.saidaQuinta || false,
    saidaSexta: initialData?.saidaSexta || false,
    saidaSabado: initialData?.saidaSabado || false,
  };

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [citiesOrigin, setCitiesOrigin] = useState<ICityData[]>([]);
  const [citiesDestination, setCitiesDestination] = useState<ICityData[]>([]);
  const [selectedCityOrigin, setSelectedCityOrigin] = useState<ICityData | null>(null);
  const [selectedCityDestination, setSelectedCityDestination] = useState<ICityData | null>(null);
  const [selectedStateOrigin, setSelectedStateOrigin] = useState<IStateData | null>(null);
  const [selectedStateDestination, setSelectedStateDestination] = useState<IStateData | null>(null);

  const { control, handleSubmit, formState: { errors } } = useForm<FreightData>({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues
  });


  const changedState = async (e: any, dest: string) => {
    try {
      setLoading(true);
      const state = states.find(s => s.id === e);
      if (!state) {
        throw new Error('State not found');
      }
      const data = await fetchCities({ id_state: state.id })
      if (data) {
        if (dest === 'origin') {
          setSelectedStateOrigin(state);
          setCitiesOrigin(data.data);
        } else if (dest === 'destination') {
          setSelectedStateDestination(state);
          setCitiesDestination(data.data);
        } else {
          throw new Error('Invalid destination');
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <div className='flex justify-end pb-2'>
        <Button label="Adicionar cotação" size='small' severity="success" onClick={() => setVisible(true)} />
      </div>
      <Dialog header="Cadastrar cotação" visible={visible} onHide={() => setVisible(false)} maximizable style={{ width: '50vw' }} >
        <form onSubmit={handleSubmit(onSubmit)} className="p-4 space-y-2 bg-white shadow-md rounded-md">
          <div className="grid grid-cols-3 gap-4">
            <div className="field">
              <label htmlFor="cep_origin" className="block text-sm font-medium text-gray-700">CEP (Origem)</label>
              <Controller name="cep_origin" control={control} render={({ field }) => (
                <InputText id="cep_origin" {...field} className={`p-inputtext w-full ${errors.cep_origin ? 'p-invalid' : ''}`} />
              )} />
              {errors.cep_origin && <small className="p-error">{errors.cep_origin.message}</small>}
            </div>

            <div className="field">
              <label htmlFor="cep_destination" className="block text-sm font-medium text-gray-700">CEP (Destino)</label>
              <Controller name="cep_destination" control={control} render={({ field }) => (
                <InputText id="cep_destination" {...field} className={`p-inputtext w-full ${errors.cep_destination ? 'p-invalid' : ''}`} />
              )} />
              {errors.cep_destination && <small className="p-error">{errors.cep_destination.message}</small>}
            </div>
          </div>
          <Divider className='!my-4' />

          <div className="w-full flex flex-wrap justify-between">
            <div className="field w-1/2 pr-2">
              <label htmlFor="id_state_origin" className="block text-sm font-medium text-gray-700">Estado de origem</label>
              <Controller name="id_state_origin" control={control} render={({ field }) => (
                <div className="flex w-full space-x-10">
                  <Dropdown value={selectedStateOrigin?.id} onChange={(e: DropdownChangeEvent) => changedState(e.value, 'origin')} options={states} optionLabel="name" optionValue="id"
                    placeholder="Selecione o estado de origem" className="!w-full" />
                </div>
              )} />
              {errors.id_state_origin && <small className="p-error">{errors.id_state_origin.message}</small>}
            </div>

            <div className="field w-1/2 pl-2">
              <label htmlFor="id_city_origin" className="block text-sm font-medium text-gray-700">Cidade de origem</label>
              <Controller name="id_city_origin" control={control} render={({ field }) => (
                <div className="flex w-full space-x-10">
                  <Dropdown value={selectedCityOrigin} onChange={(e: DropdownChangeEvent) => setSelectedCityOrigin(e.value)} options={citiesOrigin} disabled={!selectedStateOrigin} optionLabel="name" optionValue="id"
                    placeholder="Selecione a cidade de origem" className="!w-full" />
                </div>
              )} />
              {errors.id_city_origin && <small className="p-error">{errors.id_city_origin.message}</small>}
            </div>
          </div>
          <Divider className='!my-4' />

          <div className="w-full flex flex-wrap justify-between">
            <div className="field w-1/2 pr-2">
              <label htmlFor="id_state_destination" className="block text-sm font-medium text-gray-700">Estado de destino</label>
              <Controller name="id_state_destination" control={control} render={({ field }) => (
                <div className="flex w-full space-x-10">
                  <Dropdown value={selectedStateDestination?.id} onChange={(e: DropdownChangeEvent) => changedState(e.value, 'destination')} options={states} optionLabel="name" optionValue="id"
                    placeholder="Selecione o estado de destino" className="!w-full" />
                </div>
              )} />
              {errors.id_state_destination && <small className="p-error">{errors.id_state_destination.message}</small>}
            </div>

            <div className="field w-1/2 pl-2">
              <label htmlFor="id_city_destination" className="block text-sm font-medium text-gray-700">Cidade de destino</label>
              <Controller name="id_city_destination" control={control} render={({ field }) => (
                <div className="flex w-full space-x-10">
                  <Dropdown value={selectedCityDestination} disabled={!selectedStateDestination} onChange={(e: DropdownChangeEvent) => setSelectedCityDestination(e.value)} options={citiesDestination} optionLabel="name" optionValue="id"
                    placeholder="Selecione a cidade de destino" className="!w-full" />
                </div>
              )} />
              {errors.id_city_destination && <small className="p-error">{errors.id_city_destination.message}</small>}
            </div>
          </div>
          <Divider className='!my-4' />
          <div className="grid grid-cols-3 gap-4">
            <div className="field">
              <label htmlFor="time_in_days" className="block text-sm font-medium text-gray-700">Tempo (Dias)</label>
              <Controller name="time_in_days" control={control} render={({ field }) => (
                <InputNumber id="time_in_days" {...field} className={`p-inputtext w-full ${errors.time_in_days ? 'p-invalid' : ''}`} />
              )} />
              {errors.time_in_days && <small className="p-error">{errors.time_in_days.message}</small>}
            </div>

            <div className="field">
              <label htmlFor="transport_value_including_insurance" className="block text-sm font-medium text-gray-700">Valor Transporte</label>
              <Controller name="transport_value_including_insurance" control={control} render={({ field }) => (
                <InputNumber id="transport_value_including_insurance" {...field} className={`p-inputtext w-full ${errors.transport_value_including_insurance ? 'p-invalid' : ''}`} />
              )} />
              {errors.transport_value_including_insurance && <small className="p-error">{errors.transport_value_including_insurance.message}</small>}
            </div>

            <div className="field">
              <label htmlFor="invoice_value" className="block text-sm font-medium text-gray-700">Valor Nota Fiscal</label>
              <Controller name="invoice_value" control={control} render={({ field }) => (
                <InputNumber id="invoice_value" {...field} className={`p-inputtext w-full ${errors.invoice_value ? 'p-invalid' : ''}`} />
              )} />
              {errors.invoice_value && <small className="p-error">{errors.invoice_value.message}</small>}
            </div>

            <div className="field">
              <label htmlFor="peso" className="block text-sm font-medium text-gray-700">Peso (kg)</label>
              <Controller name="weight_kg" control={control} render={({ field }) => (
                <InputNumber id="weight_kg" {...field} className={`p-inputtext w-full ${errors.weight_kg ? 'p-invalid' : ''}`} />
              )} />
              {errors.weight_kg && <small className="p-error">{errors.weight_kg.message}</small>}
            </div>

            <div className="field">
              <label htmlFor="volume_m3" className="block text-sm font-medium text-gray-700">Volume (m³)</label>
              <Controller name="volume_m3" control={control} render={({ field }) => (
                <InputNumber id="volume_m3" {...field} className={`p-inputtext w-full ${errors.volume_m3 ? 'p-invalid' : ''}`} />
              )} />
              {errors.volume_m3 && <small className="p-error">{errors.volume_m3.message}</small>}
            </div>
          </div>
          <Divider className='!my-4' />
          <div className="grid grid-cols-4 gap-4">
            <div className="field flex space-x-2">

              <Controller name="saidaDomingo" control={control} render={({ field }) => (
                <Checkbox id="saidaDomingo" {...field} checked={field.value ?? false} />
              )} />
              <label htmlFor="saidaDomingo" className="block text-sm font-medium text-gray-700">Domingo</label>
            </div>

            <div className="field flex space-x-2">

              <Controller name="saidaSegunda" control={control} render={({ field }) => (
                <Checkbox id="saidaSegunda" {...field} checked={field.value ?? false} />
              )} />
              <label htmlFor="saidaSegunda" className="block text-sm font-medium text-gray-700">Segunda</label>
            </div>

            <div className="field flex space-x-2">

              <Controller name="saidaTerca" control={control} render={({ field }) => (
                <Checkbox id="saidaTerca" {...field} checked={field.value ?? false} />
              )} />
              <label htmlFor="saidaTerca" className="block text-sm font-medium text-gray-700">Terça</label>
            </div>

            <div className="field flex space-x-2">

              <Controller name="saidaQuarta" control={control} render={({ field }) => (
                <Checkbox id="saidaQuarta" {...field} checked={field.value ?? false} />
              )} />
              <label htmlFor="saidaQuarta" className="block text-sm font-medium text-gray-700">Quarta</label>
            </div>

            <div className="field flex space-x-2">

              <Controller name="saidaQuinta" control={control} render={({ field }) => (
                <Checkbox id="saidaQuinta" {...field} checked={field.value ?? false} />
              )} />
              <label htmlFor="saidaQuinta" className="block text-sm font-medium text-gray-700">Quinta</label>
            </div>

            <div className="field flex space-x-2">

              <Controller name="saidaSexta" control={control} render={({ field }) => (
                <Checkbox id="saidaSexta" {...field} checked={field.value ?? false} />
              )} />
              <label htmlFor="saidaSexta" className="block text-sm font-medium text-gray-700">Sexta</label>
            </div>

            <div className="field flex space-x-2">

              <Controller name="saidaSabado" control={control} render={({ field }) => (
                <Checkbox id="saidaSabado" {...field} checked={field.value ?? false} />
              )} />
              <label htmlFor="saidaSabado" className="block text-sm font-medium text-gray-700">Sábado</label>
            </div>
          </div>

          <Button type="submit" label="Salvar" className="p-button-primary w-full mt-6" />

        </form >
      </Dialog >
    </>
  );
}

export default FreightAdd;