import { JsonView } from "@otakustay/react-json-view"
import "@otakustay/react-json-view/style"
import { ClipboardNotes } from "@styled-icons/foundation"

export const PrettierCode = ({ code }: any) => {
  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text)
  }

  return (
    <div className="bg-gray-800 rounded-md shadow-md p-4  page-width-sidebar-menu relative">
      <JsonView source={code} />
      <button onClick={() => copyToClipboard(code)} className="absolute top-4 right-6 hover:animate-pulse">
        <ClipboardNotes className="w-6 h-6 text-white"></ClipboardNotes>
      </button>
    </div>
  )
}
