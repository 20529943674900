import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  id_state_origin: Yup.number().required('Estado de origem é obrigatório').positive('Estado de origem é obrigatório'),
  id_city_origin: Yup.number().required('Cidade de origem é obrigatório').positive('Cidade de origem é obrigatório'),
  id_state_destination: Yup.number().required('Estado de destino é obrigatório').positive('Estado de destino é obrigatório'),
  id_city_destination: Yup.number().required('Cidade de destino é obrigatório').positive('Cidade de destino é obrigatório'),
  cep_origin: Yup.string()
  .test('Apenas números', 'CEP deve ter apenas números', (value) => /^\d+$/.test(value ?? ''))
  .required('CEP de origem é obrigatório')
  .length(8, 'CEP de origem deve ter 8 caracteres'),
  cep_destination: Yup.string()
  .test('Apenas números', 'CEP deve ter apenas números', (value) => /^\d+$/.test(value ?? ''))
  .required('CEP de destino é obrigatório')
  .length(8, 'CEP de destino deve ter 8 caracteres'),
  time_in_days: Yup.number().required('Tempo é obrigatório').positive('Tempo deve ser positivo'),
  transport_value_including_insurance: Yup.number().required('Valor do transporte é obrigatório').positive('Valor deve ser positivo'),
  invoice_value: Yup.number().required('Valor da nota fiscal é obrigatório').positive('Valor deve ser positivo'),
  weight_kg: Yup.number().required('Peso é obrigatório').positive('Peso deve ser positivo'),
  volume_m3: Yup.number().required('Volume é obrigatório').positive('Volume deve ser positivo'),
  saidaDomingo: Yup.boolean().required(),
  saidaSegunda: Yup.boolean().required(),
  saidaTerca: Yup.boolean().required(),
  saidaQuarta: Yup.boolean().required(),
  saidaQuinta: Yup.boolean().required(),
  saidaSexta: Yup.boolean().required(),
  saidaSabado: Yup.boolean().required(),  
});

export default validationSchema;