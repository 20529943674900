import { AxiosResponse } from "axios";
import { Dialog } from "primereact/dialog";

function ModalDemandImportError({ isOpen = false, error, closeModal }: { isOpen?: boolean; error?: AxiosResponse, closeModal: Function }) {

  function closeModalError() {
    closeModal()
  }

  function getError() {
    if (error && error.status === 500) {
      return 'Não foi possível processar o arquivo, verifique se o mesmo está no formato correto.'
    }
    if (error?.status === 400) {
      return 'O arquivo enviado está vazio, verifique se o mesmo está no formato correto.'
    }
    return ''
  }

  return (
    <div>
      <Dialog onHide={closeModalError} visible={isOpen} header="Erro ao importar demandas via arquivo">
        {error && (
          <div className="mt-4">
            <p className="font-bold mb-3">Detalhes do erro:</p>
            <p className="text-sm">{getError()}</p>
            {error.data.errors.map((item: any, index: number) => (
              <p key={index} className="text-sm">{item.error}</p>
            ))}
          </div>
        )}
        <button onClick={closeModalError} className="bg-green-500 hover:bg-green-700 px-4 py-2 rounded-md text-white">
          Fechar
        </button>
      </Dialog>
    </div >
  )
}

export default ModalDemandImportError
