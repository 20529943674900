import { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import http from "../../../plugins/http";
import TablePagination from "../../../components/TablePagination";
import Loading from "../../../components/Loading";
import { Message } from "primereact/message";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Badge } from "primereact/badge";
import { Close } from "@styled-icons/evaicons-solid";
import { useToast } from "../../../components/Toast";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { MaskCPForCNPJ } from "../../../plugins/mask";
import { BreadCrumb } from "primereact/breadcrumb";
import ModalUserDetail from "./Detail/ModalUserDetail";
import { fetchRoles } from "../../../api/roles";
import { fetchStates } from "../../../api/states";

const UsersTable: React.FC = () => {
  const today = new Date();
  const prevMonth = new Date();
  prevMonth.setMonth(today.getMonth() - 1);
  const toast = useToast();
  const [users, setUsers] = useState([]);
  const [meta, setMeta] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [advFilterName, setAdvFilterName] = useState('');
  const [roles, setRoles] = useState<any[]>([]);
  const [states, setStates] = useState<any[]>([]);
  const [filters, setFilters] = useState({
    status: 99,
    role: 0,
    dateInitial: prevMonth.toISOString().split("T")[0],
    dateFinal: new Date().toISOString().split("T")[0],
    page: 1,
    perPage: 100,
    wordsToIgnore: [
      "@certi",
      "@ica",
      'Adamastor-',
      'teste'
    ]
  });

  const userProfiles = [
    { public_name: "Comprador e vendedor", value: 0 },
    { public_name: "Vendedor", value: 1 },
    { public_name: "Vendedor (Sem perfil integrador)", value: 11 },
    { public_name: "Comprador", value: 2 },
    { public_name: "Visitantes (CPF)", value: 8 },
    { public_name: "Outros (Financiador, Startup, Outros, etc)", value: 9 },
    { public_name: "Todos", value: 10 },
    { public_name: "Administrativo", value: 3 },
    { public_name: "Quem já comprou", value: 4 },
    { public_name: "Quem já vendeu", value: 5 },
    { public_name: "Quem tem produto anunciado", value: 6 },
    { public_name: "Quem tem demanda anunciada", value: 7 },
  ]

  const userSituation = [
    { public_name: "Todos", value: 99 },
    { public_name: "Ativados", value: 1 },
    { public_name: "Desativados", value: 0 },
  ]

  const profilesFlags = [
    {
      name: 'producer',
      label: 'Vendedor'
    },
    {
      name: 'buyer',
      label: 'Comprador'
    },
    {
      name: 'government',
      label: 'Governo'
    },
    {
      name: 'funder',
      label: 'Financiador'
    },
    {
      name: 'ict',
      label: 'ICT'
    },
    {
      name: 'investor',
      label: 'Investidor'
    },
    {
      name: 'startUp',
      label: 'StartUp'
    },
    {
      name: 'logisticOperator',
      label: 'Operador Logístico'
    },
    {
      name: 'other',
      label: 'Outro'
    }
  ];

  async function fetchUsers(filter: any) {
    try {
      setLoading(true);
      const response = await http.post("v1/reports/users", filter);
      if (response.status === 200) {
        setUsers(response.data.data);
        setMeta(response.data.meta);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchUsers(filters);
    fetchRoles().then(res => {
      setRoles(res.data.map((x: any) => ({ label: x.public_name, value: x.id })));
    });
    fetchStates().then(res => {
      setStates(res.data);
    });
  }, []);


  const handleFilterChange = (e: any) => {

    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
    fetchUsers({ ...filters, [name]: value });
  };

  const addNameToIgnore = () => {
    if (advFilterName) {
      if (filters.wordsToIgnore.includes(advFilterName)) {
        toast.current?.show({
          severity: "warn",
          summary: "Atenção",
          detail: "Esse nome já está na lista de padrões a serem ignorados",
        });
        return;
      }
      setFilters({ ...filters, wordsToIgnore: [...filters.wordsToIgnore, advFilterName] });
      setAdvFilterName("");
      fetchUsers({ ...filters, wordsToIgnore: [...filters.wordsToIgnore, advFilterName] });
    }
  }

  const removeNameToIgnore = (name: any) => {
    setFilters({ ...filters, wordsToIgnore: filters.wordsToIgnore.filter((n: any) => n !== name) });
    fetchUsers({ ...filters, wordsToIgnore: filters.wordsToIgnore.filter((n: any) => n !== name) });
  }

  const changePage = (page: number) => {
    setFilters({ ...filters, page });
    fetchUsers({ ...filters, page });
  }

  const generateReport = async () => {
    http.post("v1/reports/export-users", filters, {
      responseType: 'blob',
    }).then((response) => {
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'relatorio-usuarios.xlsx'; // Nome do arquivo
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
      toast.current?.show({
        severity: "success",
        summary: "Sucesso",
        detail: "Relatório gerado com sucesso",
      });
    }).catch((error) => {
      console.log(error);
      toast.current?.show({
        severity: "error",
        summary: "Erro",
        detail: "Erro ao gerar relatório",
      });
    });
  }

  const statusTemplate = (rowData: any) => {
    return (
      <div className="text-center">
        {rowData.status === 1 ? (
          <p className="text-[#28A745]">Ativo</p>
        ) : (
          <p className="text-red-500">Desativado</p>
        )}
      </div>
    );
  };

  const profileTemplate = (rowData: any) => {
    for (const flag in rowData.flags) {
      if (rowData.flags[flag]) {
        const profile = profilesFlags.find((p) => p.name === flag);
        return (
          <div className="text-center">
            <Badge className="px-2" value={profile?.label} severity="success" />
          </div>
        );
      }
    }
    return (
      <div className="text-center">
        <Badge className="px-2" value="-" severity="info" />
      </div>
    );
  };

  const dateTemplate = (rowData: any) => {
    return (
      <div className="text-center">
        {new Date(rowData.created_at).toLocaleDateString()}
      </div>
    );
  };

  const rowActions = (rowData: any) => {
    return (
      <div className="flex justify-center items-center">
        <ModalUserDetail user={rowData} onEdit={() => {}} roles={roles} states={states} />
      </div>
    );
  }

  const items = [{ label: 'Relatórios', url: '/reports' }, { label: 'Relatório de Usuários' }];
  const home = { icon: 'pi pi-home', url: '/home' }

  return (
    <div className="w-full p-10">
      <BreadCrumb model={items} home={home} className="mb-4" />
      <div className="flex flex-wrap">
        <div className="w-4/12 xl:w-3/12 flex flex-col">
          <label className="text-md font-bold mb-1">Perfil</label>
          <Dropdown name="role" value={filters.role} options={userProfiles} onChange={handleFilterChange} optionLabel="public_name" className="w-full" placeholder="Perfil" />
        </div>
        <div className="w-4/12 xl:w-3/12 flex flex-col">
          <label className="text-md font-bold mb-1">Situação</label>
          <Dropdown name="status" value={filters.status} options={userSituation} onChange={handleFilterChange} optionLabel="public_name" placeholder="Situação" className="w-full" />
        </div>
        <div className="w-5/12 xl:w-4/12 flex">
          <div className="w-1/2 flex flex-col pr-2">
            <label className="text-md font-bold mb-1">Data Inicial</label>
            <InputText
              value={filters.dateInitial}
              name="dateInitial"
              onChange={handleFilterChange}
              type="date"
            />
          </div>
          <div className="w-1/2 flex flex-col pl-2">
            <label className="text-md font-bold mb-1">Data Final</label>
            <InputText
              value={filters.dateFinal}
              name="dateFinal"
              onChange={handleFilterChange}
              type="date"
            />
          </div>
        </div>
        <div className="w-3/12 xl:w-2/12 flex flex-shrink justify-end align-middle pl-4 pt-8 space-x-2">
          <Button
            disabled={loading}
            onClick={() => fetchUsers(filters)}
            severity="success"
            className="max-h-10"
          >
            Filtrar
          </Button>
          <Button
            disabled={loading}
            onClick={() => generateReport()}
            severity="success"
            className="max-h-10"
          >
            Exportar
          </Button>
        </div>
      </div>

      <Message severity="warn" className="w-full mt-2" text="Para resultados mais confiáveis, alguns filtros foram aplicados automaticamente. Você pode desativá-los nos filtros avançados. " />

      <Accordion className="mt-2 mb-2">
        <AccordionTab header="Filtros avançados">
          <p className="text-sm font-bold mb-2">Padrão a ser ignorado</p>
          <p className="text-sm font-normal mb-2">Se um usuário conter no nome ou email uma dos padrões cadastrados aqui, eles serão ignorados do relatório.</p>
          <div className="w-full flex items-center space-x-2">
            <InputText
              value={advFilterName} onChange={(e: any) => setAdvFilterName(e.target.value)}
              onKeyDown={(e: any) => {
                if (e.key === "Enter") {
                  addNameToIgnore();
                }
              }
              }
            />
            <Button
              onClick={addNameToIgnore}
              color="success"
              size="small"
              className="bg-green-500 hover:bg-green-600 text-white font-bold px-2"
            >
              Adicionar
            </Button>
          </div>
          <div className="flex flex-row space-x-2 mt-2">
            {filters.wordsToIgnore.map((name) => (
              <div key={name} className="flex items-center ">
                <Badge className="px-2" value={name}></Badge>
                <Close className="cursor-pointer" onClick={() => removeNameToIgnore(name)} size="20" />
              </div>
            ))}
          </div>
        </AccordionTab>
      </Accordion>



      {loading && (
        <div className="w-full h-36 grid place-content-center p-20">
          <Loading color="#212529" size="w-10" />
        </div>
      )}
      {!loading && users.length === 0 && (
        <div className="w-full h-36 grid place-content-center p-20">
          <p>Nenhuma usuário encontrado com os filtros aplicados</p>
        </div>
      )}
      {!loading && users.length > 0 && (
        <div className="h-full w-full mt-10 overflow-auto">
          <Message severity="info" className="w-full" text={`Total de registros: ${users.length}`} />
          <DataTable showGridlines value={users} className="w-full mt-4 font-roboto text-black">
            <Column body={rowActions} header="Ações" />
            <Column field="name" header="Nome" />
            <Column body={(item) => (<span>{item?.integration?.name}</span>)} header="Razão Social" />
            <Column field="cpf_or_cnpj" header="CPF/CNPJ (Cadastro)" />
            <Column body={(item) => (<span>{MaskCPForCNPJ({ value: item?.integration?.cpf_or_cnpj })}</span>)} header="CNPJ (Venda)" />
            <Column field="email" header="Email" />
            <Column field="phone" header="Celular" />
            <Column body={(row) => <span>{row?.city?.name}/{row?.state?.short_name}</span>} header="Cidade" />
            <Column body={profileTemplate} header="Perfil de Cadastro" />
            <Column body={statusTemplate} header="Situação" />
            <Column body={dateTemplate} header="Data de Cadastro" />
          </DataTable>
          {meta?.current_page && <TablePagination
            meta={meta}
            onChange={page => fetchUsers(page)} />}
        </div>
      )}
    </div>
  );
};

export default UsersTable;
