import { useEffect, useState } from "react";
import PageHeader from "../../components/PageHeader";
import { Edit } from "@styled-icons/boxicons-regular";

import http from "../../plugins/http";
import Loading from "../../components/Loading";
import { Link } from "react-router-dom";
import { ConfirmDeleteDemand } from "../../components";

import { diffDate } from "../../utils/data";
import TablePagination from "../../components/TablePagination";
import { Button } from "primereact/button";

function DemandsPage() {
  const [loading, setLoading] = useState<boolean>(false);
  const [demands, setDemands] = useState<any[]>([]);
  const [meta, setMeta] = useState<any>({});
  const [openDeleteDemandModal, setOpenDeleteDemandModal] = useState(false);


  const getAllDemands = async ({
    page = 1,
    perPage = 5,
    search,
  }: { page?: number; perPage?: number; search?: string } = {}) => {
    setLoading(true);

    try {
      const user = localStorage.getItem("user");
      const response = await http.get(`/v1/buyer-demands/me/${JSON.parse(user ?? '{id: 1}').id}?paginate=true&perPage=${perPage}&page=${page}`);

      if (response.status === 200) {
        setDemands(response.data.data);
        setMeta(response.data.meta)
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleDeleteDemand = (id: number) => async () => {
    console.log("confirm click => ", id);

    try {
      const response = await http.delete(`/v1/buyer-demands/${id}`);

      if (response.status === 200) {
        setOpenDeleteDemandModal(false);
        getAllDemands();
      }
    } catch (error) {
      console.log(error);
      setOpenDeleteDemandModal(false);
    }
  };

  useEffect(() => {
    getAllDemands();
  }, []);

  return (
    <div className="w-full p-4 pt-10">
      <Link to="/demands/register">
        <Button
          color="success"
          className="uppercase text-[15px] font-nunito h-[38px] font-bold  hover:bg-[#33748B] bg-[#33748B] border-0 hover:border-0 mb-[0px] mt-10"
        >
          Cadastrar nova demanda
        </Button>
      </Link>

      <PageHeader>Minhas demandas cadastradas:</PageHeader>

      {loading && (
        <div className="grid w-full p-20 h-36 place-content-center">
          <Loading color="#212529" size="w-10" />
        </div>
      )}
      {!loading && demands.length === 0 && (
        <div className="grid w-full p-20 h-36 place-content-center">
          <p>Nenhuma demanda encontrada</p>
        </div>
      )}
      {!loading && demands.length > 0 && (
        <div className="w-full h-full overflow-x-auto">
          <table className="mt-0 font-roboto">
            <thead className="bg-white">
              <tr className="font-bold text-[18px] text-[#212529]">
                <th className="w-5">Nº de cadastro</th>
                <th className="col-span-2 w-25" scope="col">
                  <div className="col border-b-[1px] border-b-[#DEE2E6] items-center">Período da demanda</div>
                  <div className="row">
                    <div className="col">Início</div>
                    <div className="border-l-[1px] border-b-[#DEE2E6] col">Fim</div>
                  </div>
                </th>
                <th className="w-5">Tipo de castanha</th>
                <th className="w-15">Subtipo de castanha</th>
                <th className="w-5">Previsão</th>
                <th>Status</th>
                <th className="w-30"></th>
              </tr>
            </thead>
            <tbody>
              {demands.map(demand => (
                <tr className={`${demand.status ? "bg-white" : "bg-gray-100"}`} key={demand.id}>
                  <td>{demand.id}</td>
                  <td>
                    <div className="row">
                      <div className="col">{demand.date_init}</div>
                      <div className="border-l-[1px] border-b-[#DEE2E6] col ">{demand.date_end}</div>
                    </div>
                  </td>
                  <td>{demand?.product_type.name}</td>
                  <td>{demand?.product_subtype.name}</td>
                  <td>
                    <span className="flex capitalize">
                      {diffDate(demand?.date_end, demand?.date_init).count}
                      &nbsp;
                      {diffDate(demand.date_end, demand.date_init).diff}
                    </span>
                  </td>
                  <td>
                    {demand.status === 1 ? (
                      <p className="text-center text-[#28A745]">Ativo</p>
                    ) : (
                      <p className="text-center text-red-500">Desativado</p>
                    )}
                  </td>
                  <td className="flex items-center justify-center p-2">
                    <div className="flex items-center justify-center gap-3">
                      <div onClick={() => setOpenDeleteDemandModal(true)}>
                        <ConfirmDeleteDemand
                          isOpen={openDeleteDemandModal}
                          title="Desativar demanda"
                          body="Tem certeza que deseja desativar essa demanda?"
                          buttonLabel="Desativar"
                          handleFunction={handleDeleteDemand(demand.id)}
                        />
                      </div>

                      <button>
                        <Link to={`/demands/edit/${demand.id}`}>
                          <Edit size={22} color="#1F5974" title="Editar" />
                        </Link>
                      </button>

                      <Link to={`/demands/${demand.id}`} className="text-[#62B549]">
                        Visualizar
                      </Link>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="flex justify-end">
            <TablePagination meta={meta} onChange={page => getAllDemands({ page })} />
          </div>
        </div>
      )}
    </div>
  );
}

export default DemandsPage;
