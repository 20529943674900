export default interface iPagination {
  page?: number;
  perPage?: number;
  search?: string;
  paginate?: boolean;
  questionMark?: boolean;
}

export const defaultPagination: iPagination = {
  page: 1,
  perPage: 10,
  search: '',
  paginate: true,
  questionMark: true,
}


export const toQueryParams = (pagination: iPagination = defaultPagination) => {
  if (!pagination.paginate) return ''
  const query = `paginate=${pagination.paginate}&page=${pagination.page}&perPage=${pagination.perPage}`
  if (pagination.search) query.concat(`&search=${pagination.search}`)
  if (!pagination.questionMark) return query
  return `?${query}`
}
