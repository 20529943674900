import { useEffect, useState } from "react";
import FormikInput from "../../../components/FormikInput";
import { Form, Formik } from "formik";
import FormikSelect from "../../../components/FormikSelect";
import Loading from "../../../components/Loading";
import http from "../../../plugins/http";
import { useFormik } from "formik";
import { dateBR } from "../../../utils/data";
import TablePagination from "../../../components/TablePagination";
import { Message } from "primereact/message";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

function ModalSellingDetails({
  filterSelectedSellers,
  hasSelected,
  datas,
  args,
  onOpen,
  cleanSelections,
  chains,
}: {
  filterSelectedSellers: any;
  hasSelected: any;
  datas: any;
  args?: any;
  onOpen: any;
  cleanSelections: any;
  chains: any;
}) {
  const formik = useFormik({
    initialValues: {
      data_inicial: "",
      data_final: "",
      id_chain: "",
    },
    onSubmit: values => {},
  });

  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [selectedData, setSelectedData] = useState<any[]>([]);
  const [meta, setMeta] = useState<any>({});
  const [orders, setOrders] = useState<any[]>([]);
  const [totalSales, setTotalSales] = useState(0);
  const [totalComission, setTotalCommision] = useState(0);
  const [selectedChain, setSelectedChain] = useState<any>("");
  const [fetchedOrder, setFetchedOrder] = useState(false);
  const [fetchedChains, setFetchedChains] = useState<any[]>([]);
  const [initialValues, setInitialValues] = useState<any>({
    data_inicial: "",
    data_final: "",
    id_chain: "",
  });
  const toggle = () => {
    setModal(prevModal => !prevModal);
    if (!modal) {
      // Resets the form to its initial state.
      // This is used when the form is submitted successfully.
      formik.resetForm();

      formik.resetForm();
      setFetchedOrder(false);
    }
  };

  const showReport = async (values: any) => {
    try {
      setLoading(true);
      const response = await http.get(
        `v1/managers/manager-report?start=${values.data_inicial}&end=${values.data_final}&users=${selectedData
          .map((data: any) => data.id)
          .join(",")}&chain=${values.id_chain}`,
        {
          responseType: "blob",
        },
      );
      if (response.status === 403) {
        setError("Você não tem permissão para visualizar este relatório");
      }
      if (response.status === 200) {
        const href = URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "report.pdf");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchOrders = async (
    {
      page = 1,
      perPage = 10,
      search,
      startDate,
      endDate,
      userIds,
      chain,
    }: {
      page?: number;
      perPage?: number;
      search?: string;
      startDate?: string;
      endDate?: string;
      userIds: any[];
      chain?: any;
    } = { startDate: "", endDate: "", userIds: [] },
  ) => {
    try {
      setLoading(true);
      const searchData = search ? `&search=${search}` : "";
      const response = await http.get(
        `v1/managers/seller-order-list?startDate=${startDate}&endDate=${endDate}&users=${userIds
          .map((data: any) => data.id)
          .join(",")}&page=${page}&perPage=${perPage}${searchData}&chain=${chain}`,
      );
      if (response.status !== 200) {
        return;
      }
      setOrders(response.data.orders.data);
      setTotalSales(response.data.totalSales);
      setTotalCommision(response.data.totalComission);
      setMeta(response.data.orders.meta);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (modal && !fetchedOrder) {
      onOpen();
      fetchOrders({
        startDate: "",
        endDate: "",
        userIds: datas,
      });
      setFetchedChains(chains);
      setFetchedOrder(true);
    } else {
      cleanSelections({});
    }
  }, [modal]);

  useEffect(() => {
    setSelectedData(datas);
  }, [datas]);

  useEffect(() => {
    const exp = modal && ((formik.values.data_inicial && formik.values.data_final) || selectedChain);
    if (exp) {
      fetchOrders({
        startDate: formik.values.data_inicial,
        endDate: formik.values.data_final,
        userIds: datas,
        chain: selectedChain,
      });
    }
  }, [datas, modal, formik.values.data_inicial, formik.values.data_final, selectedChain]);

  return (
    <>
      <Button
        onClick={toggle}
        className="w-full md:w-auto flex-shrink-0 align-middle uppercase text-[15px] font-nunito h-[38px] font-bold hover:bg-[#479632] bg-green-500 border-0 hover:border-0"
        color="success"
        disabled={!hasSelected()}
      >
        Visualizar Vendas
      </Button>
      <Dialog header="Relatório de Vendas:" visible={modal} onHide={toggle} centered size="xl" {...args}>

        <Formik
          initialValues={initialValues}
          onSubmit={values => {
            fetchOrders({
              startDate: values.data_inicial,
              endDate: values.data_final,
              userIds: datas,
              chain: values.id_chain,
            });
          }}
          onReset={(values, { resetForm }) => {
            setSelectedChain("");
            fetchOrders({
              startDate: "",
              endDate: "",
              userIds: datas,
            });
          }}
          key={"form-search-percentages-probabilities"}
        >
          {({ values, resetForm }) => (
            <Form className="flex flex-wrap space-y-1">
              <div className="flex flex-col md:flex-row items-center w-full justify-between p-4 space-y-2 md:space-y-0 md:space-x-1 mr-4">
                <div className="flex-initial md:flex-grow mb-1 md:mb-0">
                  <FormikInput
                    onChanged={formik.handleChange}
                    value={values.data_inicial}
                    name="data_inicial"
                    label="Data Inicial"
                    type="date"
                  />
                </div>
                <div className="flex-initial md:flex-grow mb-1 md:mb-0">
                  <FormikInput
                    onChanged={formik.handleChange}
                    value={values.data_final}
                    name="data_final"
                    label="Data Final"
                    type="date"
                  />
                </div>
                <div className="flex-initial md:flex-grow mb-1 md:mb-0">
                  <FormikSelect
                    label="Cadeia"
                    name="id_chain"
                    options={[
                      { value: "", label: "Selecione", isDisabled: true },
                      ...(fetchedChains?.map((chain: any) => ({
                        label: chain.name,
                        value: chain.id,
                        isDisabled: false,
                      })) || []),
                    ]}
                    onChange={e => setSelectedChain(parseInt(e))}
                  />
                </div>
              </div>
              <div className="flex flex-col md:flex-row items-center justify-end gap-4 w-full p-6 mr-4">
                <Button
                  disabled={!values.data_inicial && !values.data_final && !(values.id_chain !== "")}
                  type="reset"
                  onClick={() => {
                    resetForm();
                  }}
                  className="w-full md:w-auto flex-shrink-0 align-middle uppercase bg-[#FFFFFF] hover:bg-[#28A745] text-[#146600] font-bold hover:border-[#28A745] text-[15px] font-nunito h-[38px]  border-0 hover:border-0"
                >
                  LIMPAR
                </Button>
              </div>
            </Form>
          )}
        </Formik>
        {!loading && orders?.length === 0 && (
          <div className="w-full h-36 grid place-content-center p-20">
            <p>Nenhuma venda encontrada</p>
          </div>
        )}
        {!loading && orders?.length > 0 && (
          <div className="relative shadow-md bg-white rounded-lg m-3">
            <div className="p-6">
              <div className="mb-4">
                <h2 className="text-lg font-semibold text-gray-800">Resumo das Vendas</h2>
              </div>
              <div className="border-t border-gray-200 py-4">
                <div className="flex justify-between">
                  <div>
                    <h3 className="text-sm font-semibold text-gray-600">Total de Vendas</h3>
                    <p className="text-lg font-bold text-green-600">R$ {totalSales}</p>
                  </div>
                  <div>
                    <h3 className="text-sm font-semibold text-gray-600">Total de Comissão</h3>
                    <p className="text-lg font-bold text-blue-600">R$ {totalComission}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {!loading && orders?.length > 0 && (
          <div className="relative overflow-x-auto overflow-y-auto max-h-[500px]">
            {!loading && orders?.length > 0 && (
              <div className="min-w-full">
                <table className="font-roboto divide-y divide-gray-200">
                  <thead className="bg-white sticky top-0">
                    <tr className="font-bold text-[18px] text-[#212529]">
                      <th className="px-8 py-3 text-left w-1/4">Vendedor</th>
                      <th className="px-6 py-3 text-center w-1/6">Valor</th>
                      <th className="px-6 py-3 text-center w-1/6">Comissão</th>
                      <th className="px-6 py-3 text-center w-1/6">Volume</th>
                      <th className="px-6 py-3 text-center w-1/6">Comprador</th>

                      <th className="px-6 py-3 text-center w-1/6">Data</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {orders.map(order => (
                      <tr key={order.id}>
                        <td className="px-8 py-4 whitespace-nowrap truncate">{order?.product?.user?.name}</td>
                        <td className="px-6 py-4 text-center whitespace-nowrap">R$ {order?.total}</td>
                        <td className="px-6 py-4 text-center whitespace-nowrap">
                          R$ {((order?.product?.user?.surcharge_rate * order?.total) / 100.0).toFixed(2)}
                        </td>
                        <td className="px-6 py-4 text-center whitespace-nowrap truncate">
                          {" "}
                          {`${Number(
                            order?.items?.reduce((acc: number, x: any) => acc + Number(x.quantity), 0),
                          ).toFixed(2)} kg`}
                        </td>

                        <td className="px-6 py-4 text-center whitespace-nowrap">{order?.user?.name}</td>
                        <td className="px-6 py-4 text-center whitespace-nowrap">
                          {dateBR({
                            date: order.created_at,
                          })}
                        </td>
                        {/* <td className="px-6 py-4 text-center">
                              {order.payment.status === 1 ? (
                                <span className="px-3 py-1 rounded-full text-center bg-green-100 text-green-700">
                                  Ativo
                                </span>
                              ) : (
                                <span className="px-3 py-1 rounded-full text-center bg-red-100 text-red-500">
                                  Desativado
                                </span>
                              )}
                            </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="flex justify-end">
                  <TablePagination
                    meta={meta}
                    onChange={page => {
                      fetchOrders({
                        startDate: formik.values.data_inicial ?? "",
                        endDate: formik.values.data_final ?? "",
                        page: page.page,
                        userIds: datas,
                        chain: selectedChain ?? "",
                      });
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        )}
        <div className="w-full flex justify-end p-4 gap-x-4">
          <Button
            onClick={toggle}
            disabled={loading}
            className="uppercase text-[15px] font-nunito h-[38px] font-bold text-[#146600] hover:text-white  bg-white hover:!bg-[#85D46C] border-1 hover:border-0"
          >
            Cancelar
          </Button>
          <Button
            disabled={loading || !formik.values.data_inicial || !formik.values.data_final || !selectedChain}
            onClick={() => showReport(formik.values)}
            color="success"
            className="uppercase text-[15px] font-nunito h-[38px] font-bold  hover:bg-[#479632] bg-green-500 border-0 hover:border-0  flex flex-wrap gap-x-4"
          >
            {loading && <Loading />}
            Visualizar
          </Button>
        </div>
        {error !== "" && (
          <Message className="w-full mt-4" color="danger">
            {error}
          </Message>
        )}
      </Dialog>
    </>
  );
}

export default ModalSellingDetails;
