import React from "react";
import { useField } from "formik";
import { withMask } from 'use-mask-input';
import ErrorMessage from "./ErrorMessage";
import styled from "styled-components";
import tw from "twin.macro";

const StyledInput = styled("input")`
  ${tw`border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-[#479632] focus:border-[#479632] p-2 h-[50px] bg-white w-full`}

  &:focus {
    border-color: transparent;
    box-shadow: 0 0 0 0.1rem rgb(166, 203, 243);
  }
`;

interface IFormikInputMask {
  label: string;
  name: string;
  placeholder: string;
  style?: object;
  className?: string;
  disabled?: boolean;
  type?: string;
  value?: string;
  maskChar?: string;
  mask: string | string[];
  onChange?: (value: any) => void;
}

const FormikInputMask: React.FC<IFormikInputMask> = ({
  name,
  label,
  disabled = false,
  placeholder,
  style,
  value,
  maskChar = "_",
  className = "w-full",
  type = "text",
  mask,
  onChange,
}: IFormikInputMask) => {
  const [field, meta] = useField(name);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    field.onChange(e);
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <div className={`${className} flex flex-col items-start`} style={style}>
      <label
        htmlFor={name}
        className={`block text-sm font-medium text-gray-700 ${meta.touched && meta.error ? "text-[#DC3545]" : ""}`}
      >
        {label}
      </label>
      <StyledInput
        {...field}
        ref={withMask(mask)}
        className={`font-sans max-sm:w-64 ${meta.touched && meta.error ? "border-[#DC3545]" : ""}`}
        placeholder={placeholder}
        disabled={disabled}
        type={type}
        value={value || field.value}
        onChange={handleInputChange}
      />
      {meta.error && meta.touched && <ErrorMessage message={meta.error} />}
    </div>
  );
};

export default FormikInputMask;
