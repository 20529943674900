import { cnpj, cpf } from "cpf-cnpj-validator";
import * as Yup from "yup";

const Schema = Yup.object().shape({
  id_role: Yup.number().required("Perfil de usuário é obrigatório"),
  id_state: Yup.number().required("Estado é obrigatório"),
  id_city: Yup.number().required("Cidade é obrigatório"),
  email: Yup.string()
    .email("Digite um email correto")
    .required("Email obrigatório")
    .max(60, "Deve ter no maximo 60 caracteres"),
  password: Yup.string()
    .required("Senha obrigatória")
    .min(6, "Deve ter no mínimo 6 caracteres")
    .max(14, "Deve ter no maximo 14 caracteres"),
  cpf_or_cnpj: Yup.string()
    .required("CPF/CNPJ obrigatório")
    .test("cpf_or_cnpj", "CPF/CNPJ inválido", (value: string) => cpf.isValid(value) || cnpj.isValid(value)),
  name: Yup.string()
    .required("Nome obrigatório")
    .min(3, "Deve ter no mínimo 3 caracteres")
    .max(60, "Deve ter no maximo 60 caracteres"),
  phone: Yup.string().required("Celular obrigatório"),
  confirmPassword: Yup.string()
    .required("Confirmação de senha obrigatória")
    .equals([Yup.ref("password")], "As senhas devem ser iguais"),
});

export default Schema;
