import React from "react";

type CardFilterProps = {
  children: React.ReactNode;
  label: string;
};

const CardIndividualFilter: React.FC<CardFilterProps> = ({ children, label }) => {
  return (
    <div className="border shadow-sm rounded">
      <div style={{
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
        }} className="bg-[#D5D5D5]  opacity-100 p-4">
        <label className="text-black text-lg">{label}</label>
      </div>
      <div
        className="p-4"
        style={{
          borderLeft: "1px solid #A3A3A3",
          borderBottom: "1px solid #A3A3A3",
          borderRight: "1px solid #A3A3A3",
          borderBottomLeftRadius: "4px",
          borderBottomRightRadius: "4px",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default CardIndividualFilter;
