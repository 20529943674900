import Routes from "./routes";
import { PrimeReactProvider } from 'primereact/api';
import AlertProvider from "./contexts/AlertContext.tsx";
import Tailwind from 'primereact/passthrough/tailwind';
import { ToastProvider } from "./components/Toast.tsx";
import "./global.css";
import "primereact/resources/themes/soho-light/theme.css";


function App() {
  return (
    <PrimeReactProvider value={{ pt: Tailwind }}>
      <AlertProvider>
        <ToastProvider>
          <Routes />
        </ToastProvider>
      </AlertProvider>
    </PrimeReactProvider>
  );
}

export default App;
