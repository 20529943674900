import { useEffect, useState } from "react";
import { Button } from "primereact/button";
import PageHeader from "../../../components/PageHeader";
import http from "../../../plugins/http";
import Loading from "../../../components/Loading";

import { Formik, Form } from "formik";

import * as Yup from "yup";
import FormikInput from "../../../components/FormikInput";
import FormikTextArea from "../../../components/FormikInputTextArea";

import FormikSelect from "../../../components/FormikSelect";

import { fetchTypeOfChestnut } from "../../../api/typeOfChestnut";
import { fetchSubtypes } from "../../../api/subtypes";
import { Link } from "react-router-dom";

import ListPeriods from "../../../components/Periods/ListPeriods";
import { SuccessModal } from "../../../components/SuccessModal";

import { format, startOfToday } from "date-fns";

const demandSchema = Yup.object().shape({
  name: Yup.string()
    .required("Campo obrigatório")
    .min(3, "Deve ter no mínimo 3 caracteres")
    .max(60, "Deve ter no maximo 60 caracteres"),
  description: Yup.string()
    .required("Campo obrigatório")
    .min(3, "Deve ter no mínimo 3 caracteres")
    .max(500, "Deve ter no maximo 500 caracteres"),
  date_init: Yup.date()
    .required("Campo obrigatório")
    .min(startOfToday(), "A data deve ser igual ou posterior à data atual"),
  date_end: Yup.date()
    .required("Campo obrigatório")
    .min(startOfToday(), "A data deve ser igual ou posterior à data atual"),
  id_product_type: Yup.number().required("Campo obrigatório"),
  id_product_subtype: Yup.number().required("Campo obrigatório"),
  periods: Yup.array()
    .of(
      Yup.object().shape({
        date: Yup.string().required("Campo obrigatório"),
        qtd: Yup.number().required("Campo obrigatório"),
        unit: Yup.string().required("Campo obrigatório"),
        price: Yup.number().required("Campo obrigatório"),
      }),
    )
    .optional(),
});

export interface Period {
  month?: string;
  qtd?: number;
  unit?: string;
  price?: number;
}

function DemandsRegisterPage() {
  const [types, setTypes] = useState<any[]>([]);
  const [subtypes, setSubtypes] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [dataInicio, setDataInicio] = useState<Date>();
  const [dataFim, setDataFim] = useState<Date>();
  const [intervalo, setIntervalo] = useState<number>(0);
  const [form, setForm] = useState<any>();
  const initialValues = {
    name: "",
    description: "",
    date_init: "",
    date_end: "",
    id_product_type: "",
    id_product_subtype: "",
    periods: [] as any,
  };

  /* Quando o usuário escolhe um tipo de castanha, ele carrega os subitpos */
  async function typeSelected(id: number) {
    setLoading(true);

    initialValues.id_product_subtype = "";

    fetchSubtypes({ id_product_type: id }).then(res => {
      setSubtypes(res.data);
      setLoading(false);
    });
  }

  async function register(values: any, actions: any) {
    console.log("values form => ", values);
    console.log("actions form => ", actions);

    actions.setSubmitting(true);
    setLoading(true);

    const data = {
      name: values.name,
      description: values.description,
      date_init: values.date_init,
      date_end: values.date_end,
      id_product_type: values.id_product_type,
      id_product_subtype: values.id_product_subtype,
      periods: values.periods,
    };

    try {
      const response = await http.post("/v1/buyer-demands", data);
      console.log("response => ", response);
      if (response.status === 200) {
        actions.resetForm();
        actions.setSubmitting(false);
        setLoading(false);
        setTypes([]);
        setSubtypes([]);

        setSuccess(true);
      }
    } catch (error) {
      setLoading(false);
      console.log("error => ", error);
    }
  }
  const handleChangeInicioDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDataInicio(new Date(e.target.value));
  };

  const handleChangeFimDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDataFim(new Date(e.target.value));
  };

  const validarIntervalo = (intervalo: number) => {
    return !(intervalo === 0 || isNaN(intervalo));
  };
  const formatarData = (data: Date | undefined) => {
    return data ? format(data, "yyyy-MM") : ""; // Se a data for definida, formata, caso contrário retorna uma string vazia.
  };

  useEffect(() => {
    const calcularIntervalo = () => {
      if (dataInicio && dataFim) {
        const diffYear = dataFim.getFullYear() - dataInicio.getFullYear();
        const diffMonth = dataFim.getMonth() - dataInicio.getMonth();
        const totalMonths = diffYear * 12 + diffMonth;
        setIntervalo(totalMonths);
      }
    };
    calcularIntervalo();
  }, [dataInicio, dataFim]);

  useEffect(() => {
    setLoading(true);
    fetchTypeOfChestnut().then(res => {
      console.log(res);
      setTypes(res.data);
      setLoading(false);
    });
  }, []);


  return (
    <div className="w-full p-4">
      {success && (
        <SuccessModal
          title="Cadastro de demanda realizado"
          body="Seu anúncio foi criado com sucesso, aguarde a aprovação por nossos analistas em instantes."
          buttonLabel="Fechar"
          isOpen={true}
          urlToRedirect="demands"
        />
      )}

      <PageHeader>
        <span>Cadastro de demanda:</span>
      </PageHeader>
      {loading && (
        <div className="grid w-full p-20 h-36 place-content-center">
          <Loading color="#212529" size="w-10" />
        </div>
      )}

      <Formik
        initialValues={initialValues}
        validationSchema={demandSchema}
        onSubmit={register}
        key={"form-register-user"}
      >
        {({ values, errors, touched, isValid }) => (
          <Form className="grid md:grid-cols-2 gap-4">
            <div className="col">
              <div className="mb-2 row">
                <FormikInput name="name" label="Título de demanda: " />
              </div>

              <div className="mb-2 row">
                <FormikTextArea label="Breve descrição: " name="description" rows={5} errors={errors.description} />
              </div>

              <div className="mb-2 row">
                <div className="p-3 rounded-md border border-[#CED4DA]">
                  <span className="mt-3 font-bold">Período do anúncio:</span>

                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <div className="col">
                      <FormikInput
                        className="w-full"
                        onChanged={handleChangeInicioDate}
                        type="date"
                        name="date_init"
                        label="Início: "
                      />
                    </div>

                    <div className="col">
                      <FormikInput
                        className="w-full"
                        onChanged={handleChangeFimDate}
                        type="date"
                        name="date_end"
                        label="Termino: "
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="mb-2 col">
                <FormikSelect
                  label="Tipo de castanha: "
                  name="id_product_type"
                  className="w-full mt-3"
                  options={types?.map(type => ({ label: type.name, value: type.id }))}
                  onChange={e => typeSelected(parseInt(e))}
                  errors={errors.id_product_type}
                />
              </div>

              <div className="mb-2 col">
                <FormikSelect
                  label="Subtipo de castanha: "
                  name="id_product_subtype"
                  className="w-full mt-3"
                  options={
                    subtypes.length > 0 ? subtypes?.map(subtype => ({ label: subtype.name, value: subtype.id })) : []
                  }
                  errors={errors.id_product_subtype}
                />
              </div>
            </div>

            <div className="col">
              <span className="inline-block mb-4">Previsão:</span>
              <div className="p-2 sm:p-3 md:p-4 rounded-md border border-[#CED4DA] mx-auto sm:mx-0 w-full sm:w-auto">
                <ListPeriods
                  periods={values.periods}
                  errors={errors.periods}
                  intervalo={intervalo}
                  firstMonth={formatarData(dataInicio)}
                  lastMonth={formatarData(dataFim)}
                />
                {errors.periods && validarIntervalo(intervalo) && (
                  <span className="text-[#DC3545] text-sm">Preencha todos os campos de previsão</span>
                )}
              </div>
            </div>
            <div className="flex justify-end w-full col-span-2 p-4 gap-x-5">
              <div>
                <Link to={"/demands"}>
                  <Button
                    disabled={loading}
                    className="uppercase text-[15px] font-nunito h-[38px] font-bold text-[#146600] hover:text-white  bg-white hover:!bg-[#85D46C] border-1 hover:border-0"
                  >
                    Cancelar
                  </Button>
                </Link>
              </div>
              <div>
                <Button
                  type="submit"
                  color="success"
                  className="uppercase text-[15px] font-nunito h-[38px] font-bold  hover:bg-[#479632] bg-green-500 border-0 hover:border-0  flex flex-wrap gap-x-4 disabled:opacity-50"
                  disabled={!isValid || loading}
                >
                  {loading ? (
                    <div>
                      <Loading />
                      <span>Enviando...</span>
                    </div>
                  ) : (
                    <span>Cadastrar</span>
                  )}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default DemandsRegisterPage;
