import React from "react";
import { Field, useField } from "formik";
import { Dropdown } from "primereact/dropdown";
import ErrorMessage from "./ErrorMessage";

interface IFormikSelect {
  label: string;
  name: string;
  style?: object;
  className?: string;
  placeholder?: string;
  options: Array<{ value: string; label: string }>;
  onChange?: (value: string) => void;
  errors?: any;
  type?: string;
  value?: string;
  labelClass?: string;
  selected?: boolean;
  defaultValue?: string;
  isDisabled?: boolean;
  isClearable?: boolean;
  menuIsOpen?: boolean;
  optionValue?: string;
}

const FormikSelect: React.FC<IFormikSelect> = ({
  name,
  label,
  style,
  className = "w-full",
  placeholder,
  options,
  onChange,
  errors,
  type,
  value,
  labelClass = "",
  selected,
  optionValue,
  defaultValue,
  isDisabled,
  isClearable,
  menuIsOpen,
}: IFormikSelect) => {
  const [field, meta, helpers] = useField(name);

  const handleChange = (e: { value: string }) => {
    helpers.setValue(e.value);
    if (onChange) {
      onChange(e.value);
    }
  };
  return (
    <div className={`${className} flex flex-col items-start`} style={style}>
      <label htmlFor={name} className={`font-sans Roboto font-normal max-sm:w-64 mb-0 ${labelClass}`}>
        {label}
      </label>
      <div className={`w-full ${errors && meta.touched ? "border-[1px] rounded-md border-[#DC3545]" : ""}`}>
        <Dropdown
          id={name}
          value={field.value}
          options={options}
          onChange={handleChange}
          defaultValue={defaultValue}
          placeholder={placeholder}
          className={`!w-full ${meta.touched && meta.error ? "p-invalid" : ""}`}
          disabled={isDisabled}
          filter={isClearable}
          optionValue={optionValue}
        />
      </div>
      {meta.error && meta.touched && <ErrorMessage message={meta.error} />}
      {errors && meta.touched && <ErrorMessage message={errors} />}
    </div>
  );
};

export default FormikSelect;
