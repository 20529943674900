import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export default function VolumesReportPage() {
  const report = {
    id: 1,
    year: 2024,
    date: '26/01/2024',
    hour: new Date().toLocaleTimeString('pt-BR'),
    intervalMonth: "Janeiro a Dezembro",
    intervalYear: "2022 a 2024",
    location: 'Amazonas - Manaus',
    weight: 20,
    producer: 15,
    buyer: 1,
    producerAndBuyer: 0,
    registeredProductVolume: 678,
    advertisedProductVolume: 316,
    registeredProductValue: 1055,
    advertisedProductValue: 55,
    totalPurchases: 1,
    salesAmount: 966.12,
    batchesProduced: 0,
    lotsPurchased: 0,
    registrationActive: 39,
    registrationInactive: 0,
    proposalAccepted: 0,
    proposalRejected: 0,
    proposalCreated: 0,
  };

  return (
    <div className="w-full p-10">
      <div className="font-bold text-2xl text-[#333333] pt-[50px] font-roboto">
        Relatório Geral do Biconex - {report.year}
      </div>
      <div className="font-roboto pb-[35px]">{report.date}</div>

      {/* Tabela 1: Mês, Ano, Localização */}
      <DataTable value={[report]} className="mt-10 font-roboto mb-10">
        <Column field="intervalMonth" header="Mês"></Column>
        <Column field="intervalYear" header="Ano"></Column>
        <Column field="location" header="Localização:"></Column>
      </DataTable>

      {/* Tabela 2: Empresas */}
      <DataTable value={[
        { tipo: 'Produtoras', quantidade: report.producer },
        { tipo: 'Compradoras', quantidade: 24 },
        { tipo: 'Compradoras (Que tem pelo menos um compra efetivada)', quantidade: report.buyer },
        { tipo: 'Produtoras e Compradoras', quantidade: report.producerAndBuyer },
      ]} className="mt-10 font-roboto mb-10">
        <Column field="tipo" header="Empresas"></Column>
        <Column field="quantidade" header="" bodyClassName="text-right"></Column>
      </DataTable>

      {/* Tabela 3: Volume de produtos */}
      <DataTable value={[
        { tipo: 'Produtos cadastrados (Histórico)', quantidade: Number('141.25').toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' Kg' },
        { tipo: 'Produtos cadastrados (Estoque atual)', quantidade: Number('141.25').toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' Kg' },
        { tipo: 'Produtos anunciados (Histórico)', quantidade: Number('21.25').toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' Kg' },
        { tipo: 'Produtos anunciados  (Estoque atual)', quantidade: Number('1.25').toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' Kg' },
      ]} className="mt-10 font-roboto mb-10">
        <Column field="tipo" header="Volume de produtos"></Column>
        <Column field="quantidade" header="" bodyClassName="text-right"></Column>
      </DataTable>

      {/* Tabela 4: Valores de Produto */}
      <DataTable value={[
        { tipo: 'Produtos cadastrados (Histórico)', valor: Number('2076.12').toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2, maximumFractionDigits: 2 }) },
        { tipo: 'Produtos cadastrados', valor: report.registeredProductValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2, maximumFractionDigits: 2 }) },
        { tipo: 'Produtos anunciados', valor: report.advertisedProductValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2, maximumFractionDigits: 2 }) },
        { tipo: 'Produtos vendidos', valor: report.salesAmount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2, maximumFractionDigits: 2 }) },
      ]} className="mt-10 font-roboto mb-10">
        <Column field="tipo" header="Valores de Produto"></Column>
        <Column field="valor" header="" bodyClassName="text-right"></Column>
      </DataTable>

      {/* Tabela 5: Lotes */}
      <DataTable value={[
        { tipo: 'Produzidos', quantidade: report.batchesProduced },
        { tipo: 'Comprados', quantidade: report.lotsPurchased },
      ]} className="mt-10 font-roboto mb-10">
        <Column field="tipo" header="Lotes"></Column>
        <Column field="quantidade" header="" bodyClassName="text-right"></Column>
      </DataTable>

      {/* Tabela 6: Situação Cadastral */}
      <DataTable value={[
        { tipo: 'Ativos', quantidade: report.registrationActive },
        { tipo: 'Inativos', quantidade: report.registrationInactive },
      ]} className="mt-10 font-roboto mb-10">
        <Column field="tipo" header="Situação Cadastral"></Column>
        <Column field="quantidade" header="" bodyClassName="text-right"></Column>
      </DataTable>

      {/* Tabela 7: Proposta de Compra */}
      <DataTable value={[
        { tipo: 'Propostas criadas', quantidade: report.proposalCreated },
        { tipo: 'Propostas aceitas', quantidade: report.proposalAccepted },
        { tipo: 'Propostas recusadas', quantidade: report.proposalRejected },
      ]} className="mt-10 font-roboto mb-10">
        <Column field="tipo" header="Proposta de Compra"></Column>
        <Column field="quantidade" header="" bodyClassName="text-right"></Column>
      </DataTable>
    </div>
  );
}
