import { cnpj, cpf } from "cpf-cnpj-validator"
import * as Yup from "yup"

const Schema = Yup.object().shape({
  email: Yup.string()
    .email("Digite um email correto")
    .required("Email obrigatório")
    .max(60, "Deve ter no maximo 60 caracteres"),
  cpf_or_cnpj: Yup.string()
    .required("CPF/CNPJ obrigatório")
    .test("cpf_or_cnpj", "CPF/CNPJ inválido", (value: string) => cpf.isValid(value) || cnpj.isValid(value)),
  name: Yup.string()
    .required("Nome obrigatório")
    .min(3, "Deve ter no mínimo 3 caracteres")
    .max(60, "Deve ter no maximo 60 caracteres"),
  phone: Yup.string().required("Celular obrigatório"),
})

export default Schema
