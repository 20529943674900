
const load_types = [
  { "id": 1, "label": { "pt": "Alimentos embalados", "en": "Packaged Foods", "es": "Alimentos envasados" }, "checked": false },
  { "id": 2, "label": { "pt": "Alimentos secos", "en": "Dry Foods", "es": "Alimentos secos" }, "checked": false },
  { "id": 3, "label": { "pt": "Temperos e ervas in natura", "en": "Fresh Spices and Herbs", "es": "Especias y hierbas frescas" }, "checked": false },
  { "id": 4, "label": { "pt": "Grãos ensacados", "en": "Bagged Grains", "es": "Granos ensacados" }, "checked": false },
  { "id": 5, "label": { "pt": "Galão de líquido (até 50L)", "en": "Liquid Gallon (up to 50L)", "es": "Galón de líquido (hasta 50L)" }, "checked": false },
  { "id": 6, "label": { "pt": "Caixas de madeira (carga comum)", "en": "Wooden Boxes (common load)", "es": "Cajas de madera (carga común)" }, "checked": false }
];

const structures = [
  { "id": 1, "label": { "pt": "Paletes no salão (30x60m)", "en": "Pallets in the Hall (30x60m)", "es": "Paletas en el Salón (30x60m)" }, "checked": false },
  { "id": 2, "label": { "pt": "Paletes em Prateleiras (3x10m)", "en": "Pallets on Shelves (3x10m)", "es": "Paletas en Estantes (3x10m)" }, "checked": false },
  { "id": 3, "label": { "pt": "Paletes em Prateleiras (5x10m)", "en": "Pallets on Shelves (5x10m)", "es": "Paletas en Estantes (5x10m)" }, "checked": false },
  { "id": 4, "label": { "pt": "Galpão em alvenaria, estrutura em aço reforçado, coberto e forrado", "en": "Masonry Warehouse, Reinforced Steel Structure, Covered and Lined", "es": "Almacén de Mampostería, Estructura de Acero Reforzado, Cubierto y Forrado" }, "checked": false },
  { "id": 5, "label": { "pt": "Área refrigerada", "en": "Refrigerated Area", "es": "Área Refrigerada" }, "checked": false },
  { "id": 6, "label": { "pt": "Ventiladores de teto", "en": "Ceiling Fans", "es": "Ventiladores de Techo" }, "checked": false },
  { "id": 7, "label": { "pt": "Piso em cerâmica", "en": "Ceramic Floor", "es": "Piso de Cerámica" }, "checked": false },
  { "id": 8, "label": { "pt": "Guarita", "en": "Guardhouse", "es": "Caseta de Guardia" }, "checked": false }
];

const equipaments = [
  { "id": 1, "label": { "pt": "Empilhadeira", "en": "Forklift", "es": "Montacargas" }, "checked": false },
  { "id": 2, "label": { "pt": "Lonas, cordas e caixa de separação", "en": "Tarps, Ropes and Sorting Box", "es": "Lonas, Cuerdas y Caja de Separación" }, "checked": false },
  { "id": 3, "label": { "pt": "Equipamento de cadastro e Software de controle de entrega", "en": "Registration Equipment and Delivery Control Software", "es": "Equipo de Registro y Software de Control de Entrega" }, "checked": false }
];

const operations = [
  { "id": 1, "label": { "pt": "Câmeras de monitoramento em todos os ângulos internos e externos", "en": "Surveillance Cameras in All Internal and External Angles", "es": "Cámaras de Vigilancia en Todos los Ángulos Internos y Externos" }, "checked": false },
  { "id": 2, "label": { "pt": "Sistema anti-incêndio", "en": "Fire Suppression System", "es": "Sistema Antincendios" }, "checked": false }
];

const certifications = [
  { "id": 1, "label": { "pt": "ISO 9001 / 2015", "en": "ISO 9001 / 2015", "es": "ISO 9001 / 2015" }, "checked": false },
  { "id": 2, "label": { "pt": "ISO 22000", "en": "ISO 22000", "es": "ISO 22000" }, "checked": false },
  { "id": 3, "label": { "pt": "AIB", "en": "AIB", "es": "AIB" }, "checked": false },
  { "id": 4, "label": { "pt": "HACCP", "en": "HACCP", "es": "HACCP" }, "checked": false }
]



export {
  certifications, equipaments, load_types, operations, structures
};
