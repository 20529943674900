import { BreadCrumb } from "primereact/breadcrumb";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useState } from "react";
import { fetchBanks } from "../../api/banks";
import { fetchStates } from "../../api/states";
import Loading from "../../components/Loading";
import PageHeader from "../../components/PageHeader";
import SearchBar from "../../components/SearchBar";
import TablePagination from "../../components/TablePagination";
import { useToast } from "../../components/Toast";
import http from "../../plugins/http";
import ModalEditCarrier from "./Edit/Modal";
import ModalEnableAndDisableUser from "./EnableAndDisable/Modal";
import ModalRegisterCarrier from "./Register/Modal";

const TableRowActions = ({
	carrier,
	update,
	enableAndDisable,
	states,
	banks,
}: {
	carrier: any;
	update: () => void;
	enableAndDisable: (carrierId: number) => void;
	states: any[];
	banks: any[];
}) => {
	return (
		<>
			<div className="flex justify-center gap-x-2">
				<ModalEnableAndDisableUser
					carrier={carrier}
					className="w-1/3"
					changeStatus={enableAndDisable}
				/>
				<ModalEditCarrier
					carrier={carrier}
					states={states}
					banks={banks}
					onEdit={() => update()}
				/>
			</div>
		</>
	);
};

function CarriersPage() {
	const toast = useToast();
	const [carriers, setCarriers] = useState<any[]>([]);
	const [meta, setMeta] = useState<any>({});
	const [loading, setLoading] = useState<boolean>(false);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [searchKey, setSearchKey] = useState<string>("");
	const [states, setStates] = useState<any[]>([]);
	const [banks, setBanks] = useState<any[]>([]);
	const options = {
		orderBy: "name",
		orderDir: "ASC",
		page: 1,
		search: "",
		perPage: 10,
	};

	function formatCnpj(cnpj: string) {
		return cnpj.replace(
			/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
			"$1.$2.$3/$4-$5",
		);
	}

	function formatPhone(phone: string) {
		return phone.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
	}

	async function fetchCarriers({
		page = currentPage,
		perPage = 10,
		search = searchKey,
		orderBy,
		orderDir,
	}: {
		page?: number;
		perPage?: number;
		search?: string;
		orderBy?: string;
		orderDir?: string;
	} = {}) {
		try {
			setLoading(true);
			const searchData = search ? `&search=${search}` : "";
			const orderByData = `&orderBy=${options.orderBy}`;
			const orderDirData = `&orderDir=${options.orderDir}`;
			const response = await http.get(
				`v1/carriers?perPage=${perPage}&page=${page}${searchData}${orderByData}${orderDirData}&user_full=1`,
			);
			if (response.status !== 200) {
				return;
			}
			setCarriers(response.data.data);
			setMeta(response.data.meta);
			setCurrentPage(page);
			setSearchKey(search);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	}

	const postReturnReport = async (url: string, data: any) => {
		const USER = JSON.parse(localStorage.getItem("user") ?? "");
		const headers = new Headers({
			"Content-Type": "application/json",
			"Access-Control-Allow-Origin": process.env.REACT_APP_API_URL ?? "",
			Authorization: `Bearer ${USER.token}`,
		});

		const response = await fetch(`${process.env.REACT_APP_API_URL}${url}`, {
			method: "POST",
			headers: headers,
			body: JSON.stringify(data),
		});

		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}

		return response.blob();
	};

	async function reports(carriers: any) {
		const url = `/v1/carriers/downlodPdf`;
		try {
			const data = await postReturnReport(url, { data: carriers });
			const file = new Blob([data], { type: "application/pdf" });
			const fileURL = URL.createObjectURL(file);
			window.open(fileURL);

			const a = document.createElement("a");
			a.href = fileURL;
			a.target = "_blank";
			a.download = "reports.pdf";
			document.body.appendChild(a);
			a.click();
		} catch (error) {
			console.log("getPDF error: ", error);
		}
	}

	const reportsById = async (id: number) => {
		const url = `/v1/carriers/downlodPdfById`;
		try {
			const data = await postReturnReport(url, { id });
			const file = new Blob([data], { type: "application/pdf" });
			const fileURL = URL.createObjectURL(file);
			window.open(fileURL);

			const a = document.createElement("a");
			a.href = fileURL;
			a.target = "_blank";
			a.download = "reports.pdf";
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
			URL.revokeObjectURL(fileURL);
		} catch (error) {
			console.log("getPDF error: ", error);
		}
	};

	async function generateReport() {
		try {
			await reports(carriers);
		} catch (error: any) {
			console.log(error);
		}
	}
	const generateReportByID = async (id: number) => {
		try {
			reportsById(id);
		} catch (error: any) {
			console.log(error);
		}
	};

	async function toggleCarrierStatus(carrier: any) {
		try {
			setLoading(true);
			if (carrier.status === 0) {
				const response = await http.put(`v1/carriers/${carrier.id}`, {
					status: 1,
				});
				if (response.status !== 200) {
					console.log("Error toggling user status");
					return;
				}
			} else {
				const response = await http.delete(`v1/carriers/${carrier.id}`);
				if (response.status !== 200) {
					console.log("Error toggling user status");
					return;
				}
			}
			toast.current?.show({
				severity: "success",
				summary: "Sucesso",
				detail: `Transportadora ${carrier.status === 1 ? "desativada" : "ativada"} com sucesso`,
			});
		} catch (error) {
			console.log(error);
		} finally {
			fetchCarriers();
			setLoading(false);
		}
	}

	function searchCarriers(val: string) {
		fetchCarriers({ search: val });
	}

	useEffect(() => {
		fetchCarriers();
		fetchStates().then((res) => {
			setStates(res.data);
		});
		fetchBanks().then((res) => {
			setBanks(
				res
					?.filter((bank: any) => bank?.code !== null)
					?.map((bank: any) => ({
						label: bank?.fullName,
						value: bank?.name,
					})) || [],
			);
		});
	}, []);

	const actionTemplate = (rowData: any) => (
		<TableRowActions
			carrier={rowData}
			update={() => {
				toast.current?.show({
					severity: "success",
					summary: "Sucesso",
					detail: "Transportadora editada",
				});
				fetchCarriers();
			}}
			states={states}
			enableAndDisable={toggleCarrierStatus}
			banks={banks}
		/>
	);

	const detailPdf = (row: any) => (
		<>
			<button
				className="text-[#479632] font-bold font-size-16 text-center cursor-pointer"
				onClick={() => generateReportByID(row.id)}
			>
				Vizualizar
			</button>
		</>
	);

	const items = [
		{ label: "Gerenciamento de Transportadoras", url: "/carriers" },
	];
	const home = { icon: "pi pi-home", url: "/home" };

	return (
		<div className="p-10">
			<BreadCrumb model={items} home={home} className="mb-4" />
			<div className="flex justify-start items-center ">
				<div className="flex items-center">
					<ModalRegisterCarrier
						updated={() => {
							toast.current?.show({
								severity: "success",
								summary: "Sucesso",
								detail: "Transportadora cadastrada",
							});
							fetchCarriers();
						}}
						banks={banks}
					/>
					<SearchBar width="400px" onSearch={(val) => searchCarriers(val)} />
				</div>
				<div className="flex flex-col ml-auto">
					<button
						className="align-tab-right border border-black border-1 px-4 py-2 rounded text-[#479632] hover:bg-[#479632] hover:text-white hover:border-transparent"
						onClick={generateReport}
					>
						Imprimir relatório
					</button>
				</div>
			</div>
			{loading && (
				<div className="h-36 grid place-content-center p-20">
					<Loading color="#212529" size="w-10" />
				</div>
			)}
			{!loading && carriers?.length === 0 && (
				<div className="h-36 grid place-content-center p-20">
					<p>Nenhuma transportadora encontrada</p>
				</div>
			)}
			{!loading && carriers?.length > 0 && (
				<>
					<DataTable
						stripedRows
						value={carriers}
						className="mt-10 font-roboto text-sm"
					>
						<Column body={actionTemplate} className="text-center" />
						<Column field="name_company" header="Nome/Razão Social" />
						<Column
							body={(row) => <span>{formatCnpj(row?.cnpj_company)}</span>}
							header="CNPJ"
						/>
						<Column field="inscricao_estadual" header="Inscrição Estadual" />
						<Column
							body={(row) => <span>{formatPhone(row?.phone)}</span>}
							header="Contato"
						/>
						<Column
							body={(row) => <span>{row?.state?.name}</span>}
							header="Estado"
						/>
						<Column
							body={(row) => <span>{row?.city?.name}</span>}
							header="Cidade"
						/>
						<Column body={detailPdf} header="Detalhes" />
					</DataTable>
					<TablePagination
						meta={meta}
						onChange={(page) => fetchCarriers(page)}
					/>
				</>
			)}
		</div>
	);
}

export default CarriersPage;
