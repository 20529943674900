import React, { useEffect, useState } from "react"
import styled from "styled-components"
import http from "../../../plugins/http"
import Loading from "../../../components/Loading"
import { InputText } from "primereact/inputtext"
import { Message } from "primereact/message"
import { Button } from "primereact/button"

const ArrowRight = styled.div`
  height: 0px;
  width: 0px;
  border-top: 25px solid transparent;
  border-left: 20px solid #146600;
  border-bottom: 25px solid transparent;
`

export interface ItemModelProps {
  field: string
  type: string
  unsigned: boolean
  not_null: boolean
  default: any
  as?: string
}

const ItemModelPage: React.FC<any> = ({ model }: { model: any }) => {
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState("")

  async function save() {
    setLoading(true)
    await http.post("/v1/module-parameters/mapping", { table_name: model.name, mapping: model.data })
    await loadAlias({ loading: false })
    setLoading(false)
    setMessage("Mapeamento salvo com sucesso!")
    setTimeout(() => {
      setMessage("")
    }, 2000)
  }

  async function loadAlias({ loading = true }: { loading?: boolean } = {}) {
    if (loading) setLoading(true)
    const res = await http.get(`/v1/module-parameters/mapping/${model.name}`)
    for (let i = 0; i < res.data.length; i++) {
      const item = res.data[i]
      const idx = model.data.findIndex((x: any) => x.field === item.field)
      if (idx !== -1) {
        model.data[idx].as = item.as
      }
    }
    if (loading) setLoading(false)
  }

  useEffect(() => {
    loadAlias()
  }, [])

  const setFieldValue = (value: string, index: number) => {
    const data = [...model.data]
    data[index].as = value
    model.data = data
  }

  return (
    <div className="max-h-screen border-in items-center flex flex-col relative">
      <div className="flex">
        <div
          className="h-[50px] 2xl:w-[440px] xl:w-[350px] lg:w-[270px] sm:w-[190px] 
          w-[130px] bg-[#146600] text-white text-lg inline-block xl:mt-24 mt-8 
          pt-2 pl-4 overflow-hidden"
        >
          Origem
        </div>
        <ArrowRight
          className="bg-[#85D46C] text-white text-lg inline-block xl:mt-24 
          mt-8 overflow-hidden"
        ></ArrowRight>
        <div
          className="h-[50px] 2xl:w-[440px] xl:w-[350px] lg:w-[270px] sm:w-[190px] 
          w-[130px] bg-[#85D46C] text-[#3D3D3D] text-lg inline-block xl:mt-24 
          mt-8 pt-2 pl-4 overflow-hidden"
        >
          Destino
        </div>
      </div>
      <div className="select-box-list" style={{ boxShadow: "0px 3px 6px #0000005C" }}>
        <div
          className="2xl:w-[440px] xl:w-[350px] lg:w-[270px] sm:w-[190px] 
        w-[calc((100%-20px)/2)] bg-[#02314F] overflow-y-scroll pl-4 pt-5"
        >
          {model?.data?.map((item: any) => (
            <div className="w-full relative" key={`prop-${model.name}-${item.field}`}>
              <InputText
                disabled
                className="border  rounded-0 w-5/6 max-w-xs inline-block"
                placeholder={item.field}
              ></InputText>
              <div className="mt-1 ml-2 mb-2 text-white">
                {item.type} {item.unsigned ? "unsigned" : ""} {item.not_null ? "not null" : ""}{" "}
                {"default: " + item.default}
              </div>

              {/* <div
                className="h-[2px] 2xl:w-[142px] 2xl:left-[368px] xl:w-[70px] xl:left-[311px] 
            lg:w-[50px] lg:left-[244px] sm:w-[40px] sm:left-[168px] absolute z-10 bg-[#33748B] 
            mt-5  opacity-100 -top-10"
              /> */}
            </div>
          ))}
        </div>

        <div
          className="2xl:w-[460px] xl:w-[370px] lg:w-[290px] sm:w-[210px] 
        w-[calc((100%+20px)/2)] bg-[#F9F9F9] flex flex-col items-center px-2 pt-5 overflow-y-scroll"
        >
          {model?.data?.map((item: any, idx: number) => (
            <div className="w-full relative" key={`prop2-${model.name}-${item.field}`}>
              <InputText
                className="border-[#33748B] rounded-0 w-full  inline-block"
                onChange={e => setFieldValue(e.target.value, idx)}
                placeholder={item.as}
              ></InputText>
              <div className="mt-1 ml-2 mb-2 text-gray-400">{item.field}</div>
            </div>
          ))}
        </div>
      </div>
      <div className={`w-full flex flex-wrap ${message === "" ? "justify-end" : "justify-between"} `}>
        {message !== "" && (
          <Message color="success" className=" text-lg inline-block mt-3 pt-2 pl-4 overflow-hidden">
            <p>Salvo com sucesso</p>
          </Message>
        )}
        <Button
          onClick={save}
          className="bg-[#205F16] max-sm:text-xs hover:bg-[#479632]
         right-0 flex flex-wrap gap-x-2 mt-4 h-10"
          disabled={loading}
        >
          {loading && <Loading />}
          SALVAR
        </Button>
      </div>
    </div>
  )
}

export default ItemModelPage
