import { useEffect, useState } from "react";
import http from "../../../plugins/http";
import "./styles.css";
import { Check, X } from "@styled-icons/boxicons-regular";
import { Link } from "react-router-dom";
import * as Yup from "yup";

const TableRowActions = ({ toggle, update }: { toggle: (user: any) => void; user: any; update: () => void }) => {
  return (
    <th scope="row" className="w-20 ml-1 text-center">
      <Check size={26} color="#28A745" className="cursor-pointer" />
      <X size={25} color="#F91B1B" className="cursor-pointer" />
    </th>
  );
};

const Schema = Yup.object().shape({
  email: Yup.string().email("Digite um email correto").required("Email obrigatório"),
  password: Yup.string().required("Senha obrigatória").min(6, "Deve ter no mínimo 6 caracteres"),
  name: Yup.string().required("Nome obrigatório").min(3, "Deve ter no mínimo 3 caracteres"),
  phone: Yup.string().required("Celular obrigatório"),
  confirmPassword: Yup.string()
    .required("Confirmação de senha obrigatória")
    .equals([Yup.ref("password")], "As senhas devem ser iguais"),
});

function ViewDemandsPage() {
  const [users, setUsers] = useState<any[]>([]);
  const [meta, setMeta] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);

  const initialValues = {
    name: "",
    cpf_or_cnpj: "",
    email: "",
    password: "",
    confirmPassword: "",
    phone: "",
    id_state: "",
    id_city: "",
    id_role: "",
  };

  async function fetchUsers({
    page = 1,
    perPage = 20,
    search,
  }: { page?: number; perPage?: number; search?: string } = {}) {
    try {
      setLoading(true);
      const searchData = search ? `&search=${search}` : "";
      const response = await http.get(`v1/users?all=1&perPage=${perPage}&page=${page}${searchData}`);
      if (response.status !== 200) {
        return;
      }
      setUsers(response.data.data);
      setMeta(response.data.meta);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  function searchUsers(val: string) {
    console.log(val);
    fetchUsers({ search: val });
  }

  async function toggleUserStatus(user: any) {
    try {
      setLoading(true);
      const response = await http.put(`v1/managers/users/${user.id}`, {
        status: user.status === 1 ? 0 : 1,
      });
      if (response.status !== 200) {
        console.log("Error toggling user status");
        return;
      }
      fetchUsers();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <div className="w-full page-height px-10  overflow-scroll">
      {/*   <PageHeader className="mt-[100px]">Demanda nº 102451/2023</PageHeader> */}
      <h2 style={{ font: "normal normal bold 28px Roboto", marginLeft: "15px", marginTop: "50px" }}>
        Demanda nº 1/2023
      </h2>
      <table className="table-view-demand mt-[15px]">
        <thead>
          <tr>
            <td colSpan={2} className="border-none" style={{ background: "transparent" }}></td>
            <td colSpan={12} className="current-year text-center h-[40px]">
              2023
            </td>
          </tr>
          <tr>
            <th id="announcement" colSpan={2} style={{ width: "300px" }}>
              <div style={{ display: "grid" }}>
                <span className="title"> Período do anúncio:</span>
                <span className="subtitle">22/12/2022 à 15/03/2023</span>
              </div>
            </th>
            {/* Aqui será fito um for para os meses do ano */}
            <th scope="col" className="th">
              JAN
            </th>
            <th scope="col" className="th">
              FEV
            </th>
            <th scope="col" className="th">
              MAR
            </th>
            <th scope="col" className="th">
              ABR
            </th>
            <th scope="col" className="th">
              MAI
            </th>
            <th scope="col" className="th">
              JUN
            </th>
            <th scope="col" className="th">
              JUL
            </th>
            <th scope="col" className="th">
              AGO
            </th>
            <th scope="col" className="th">
              SET
            </th>
            <th scope="col" className="th">
              OUT
            </th>
            <th scope="col" className="th">
              NOV
            </th>
            <th scope="col" className="th">
              DEZ
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="product" rowSpan={2}>
              <div style={{ display: "grid" }}>
                <span>Castanha em casca desitradata</span>
                <span>média (medium): 57 a 68 castanhas por 453 gr.</span>
              </div>
            </td>
            <td className="td-view-demand">Anúncio</td>
            <td className="td-view-demand td-advert"></td>
            <td className="td-view-demand td-advert"></td>
            <td className="td-view-demand td-advert"></td>
            <td className="td-view-demand td-advert"></td>
            <td className="td-view-demand td-advert">100 kg</td>
            <td className="td-view-demand td-advert"></td>
            <td className="td-view-demand td-advert"></td>
            <td className="td-view-demand td-advert"></td>
            <td className="td-view-demand td-advert"></td>
            <td className="td-view-demand td-advert"></td>
            <td className="td-view-demand td-advert"></td>
            <td className="td-view-demand td-advert"></td>
            <td rowSpan={2} className="accept-refuse">
              Aceitar / Recusar
            </td>
          </tr>
          <tr>
            {/* Ofertas para os 12 meses */}
            <td className="td-view-demand" style={{ background: "#efefef" }}>
              Ofertas
            </td>
            <td className="td-view-demand" style={{ background: "#efefef" }}></td>
            <td className="td-view-demand" style={{ background: "#efefef" }}></td>
            <td className="td-view-demand" style={{ background: "#efefef" }}></td>
            <td className="td-view-demand" style={{ background: "#efefef" }}></td>
            <td className="td-view-demand" style={{ background: "#efefef" }}></td>
            <td className="td-view-demand" style={{ background: "#efefef" }}></td>
            <td className="td-view-demand" style={{ background: "#efefef" }}></td>
            <td className="td-view-demand" style={{ background: "#efefef" }}></td>
            <td className="td-view-demand" style={{ background: "#efefef" }}></td>
            <td className="td-view-demand" style={{ background: "#efefef" }}></td>
            <td className="td-view-demand" style={{ background: "#efefef" }}></td>
            <td className="td-view-demand" style={{ background: "#efefef" }}></td>
          </tr>
          {/* For de vendedores com as ofertas */}
          <tr style={{ height: "85px" }}>
            <td className="buyer">Vendedor da castanha</td>
            <td className="td-view-demand" style={{ background: "#efefef" }}>
              01
            </td>
            {/* For dos periodos das ofertas dos vendedores */}
            <td style={{ background: "#d8d8d8", border: "1px solid #efefef" }}>100 kg</td>
            <td style={{ background: "#d8d8d8", border: "1px solid #efefef" }}>
              <span>10 kg</span>
            </td>
            <td style={{ background: "#d8d8d8", border: "1px solid #efefef" }}>
              <span>10 kg</span>
            </td>
            <td style={{ background: "#d8d8d8", border: "1px solid #efefef" }}>
              <span>10 kg</span>
            </td>
            <td style={{ background: "#d8d8d8", border: "1px solid #efefef" }}>
              <span>10 kg</span>
            </td>
            <td style={{ background: "#d8d8d8", border: "1px solid #efefef" }}>
              <span>10 kg</span>
            </td>
            <td style={{ background: "#d8d8d8", border: "1px solid #efefef" }}>
              <span>10 kg</span>
            </td>
            <td style={{ background: "#d8d8d8", border: "1px solid #efefef" }}>
              <span>10 kg</span>
            </td>
            <td style={{ background: "#d8d8d8", border: "1px solid #efefef" }}>
              <span>10 kg</span>
            </td>
            <td style={{ background: "#d8d8d8", border: "1px solid #efefef" }}>
              <span>10 kg</span>
            </td>
            <td style={{ background: "#d8d8d8", border: "1px solid #efefef" }}>
              <span>10 kg</span>
            </td>
            <td style={{ background: "#d8d8d8", border: "1px solid #efefef" }}>
              <span>10 kg</span>
            </td>
            <td style={{ background: "#ffffff", border: "1px solid #efefef" }}>
              <TableRowActions user={users} toggle={user => toggleUserStatus(user)} update={fetchUsers} />
            </td>
          </tr>
        </tbody>
      </table>
      <div style={{ display: "flex", marginRight: "49px", justifyContent: "flex-end" }}>
        <Link to="/demands">
          <button className="btn-back">VOLTAR</button>
        </Link>

        <button className="btn-conclude">CONCLUIR</button>
      </div>
    </div>
  );
}

export default ViewDemandsPage;
