import { Form, Formik } from "formik";
import { useState, memo, useEffect } from "react";
import FormikSelect from "../../../components/FormikSelect";
import PageHeader from "../../../components/PageHeader";
import Loading from "../../../components/Loading";
import ModalFinancialDetails from "../ModalSellingDetails/Modal";
import SearchBar from "../../../components/SearchBar";
import http from "../../../plugins/http";
import FormikInput from "../../../components/FormikInput";
import ModalSellingDetails from "../ModalSellingDetails/Modal";
import ModalReportGeneral from "../ModalReportGeneral/Modal";
import ModalReportIndividual from "../ModalReportIndividual/Modal";
import TablePagination from "../../../components/TablePagination";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
const TableRowActions = ({ toggle, data, checked }: { toggle: (data: any) => void; data: any; checked: boolean }) => {
  return <InputText type="checkbox" checked={checked} onChange={() => toggle(data)} />;
};

const OrderManager = memo(function ProfileManager(args: any) {
  const [key_word, setKeyWord] = useState("");
  const [meta, setMeta] = useState<any>({});
  const [pagination, setPagination] = useState(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedDatas, setSelectedDatas] = useState<any[]>([]);
  const [selections, setSelections] = useState<Record<string, boolean>>({});
  const [initialValues, setInitialValues] = useState<any>({
    key_word: "",
    perfil: "",
    operation: "",
    order: "",
    pagination: "",
  });
  const [datas, setDatas] = useState<any[]>([]);
  const [chains, setChains] = useState<any[]>([]);
  const [defaultDatas, setDefaultDatas] = useState<any[]>([]);
  const [checkBoxStates, setCheckBoxStates] = useState<any[]>([]);

  const fetchSellers = async ({
    page = 1,
    perPage = 10,
    search,
  }: { page?: number; perPage?: number; search?: string } = {}) => {
    try {
      setLoading(true);
      const searchData = search ? `&search=${search}` : "";
      const response = await http.get(
        `v1/percentages-and-probabilities/seller?all=1&perPage=${perPage}&page=${page}${searchData}`,
      );
      if (response.status !== 200) {
        return;
      }
      setDatas(response.data.data);
      setMeta(response.data.meta);
      setDefaultDatas([...response.data.data]);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchChains = async () => {
    try {
      setLoading(true);
      const response = await http.get("v1/product-groups?all=1");
      if (response.status !== 200) {
        return;
      }
      setChains([...response.data, { id: 0, name: "Todas" }]);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  function toggleItemSelection(id: any) {
    setSelections(prevState => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  }
  const uncheckAll = () => {
    const checkboxes = document.querySelectorAll("input[type=checkbox]");
    checkboxes.forEach((checkbox: any) => {
      checkbox.checked = false;
    });
  };

  function toggleAll() {
    const allSelected = Object.keys(selections).length === datas.length;

    if (allSelected) {
      setSelections({});
    } else {
      const newSelections: { [key: string]: boolean } = {};
      datas.forEach(data => {
        newSelections[data.id] = true;
      });
      setSelections(newSelections);
    }
  }

  const hasSelected = (): boolean => {
    return Object.values(selections).some(val => val);
  };

  const filterData = (e: any) => {
    const filteredData = defaultDatas.filter((data: any) => {
      return (
        data.name?.toLowerCase().includes(e.toLowerCase()) ||
        data.email?.toLowerCase().includes(e.toLowerCase()) ||
        data.phone?.toLowerCase().includes(e.toLowerCase())
      );
    });
    setDatas(filteredData);
  };

  const filterSelectedUsers = () => {
    console.log("vai fazer a consulta no banco de dados do pessoal que foi mandado para a modal");
  };

  useEffect(() => {
    fetchSellers();
    fetchChains();
  }, []);

  useEffect(() => {
    const selected = datas.filter(data => selections[data.id]);
    setSelectedDatas(selected);
  }, [selections, datas]);

  return (
    <div className="h-screen bg-[#F2F2F2] px-5 py-5">
      <PageHeader>Relatório de Custos e Cobranças</PageHeader>

      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => { }}
        onReset={(values, { resetForm }) => {
          setInitialValues({
            key_word: "",
            perfil: "",
            operation: "",
            order: "",
          });
          fetchSellers();
        }}
        key={"form-search-percentages-probabilities"}
      >
        {({ values, resetForm }) => (
          <Form className="flex flex-col space-y-3">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 gap-4 items-center w-full relative rounded-lg p-6 border border-[#707070]">
              <FormikInput
                className="col-span-2 w-full max-w-full p-1"
                labelClass="block max-w-[80%] truncate"
                label="Palavra chave:"
                name="key_word"
                type="text"
                onChanged={(e: React.ChangeEvent<HTMLInputElement>) => {
                  filterData(e.target.value);
                  setKeyWord(e.target.value);
                }}
              />
            </div>
            <div className="flex flex-col md:flex-row items-center justify-end gap-4 w-full">
              <Button
                disabled={!(key_word.length !== 0)}
                type="reset"
                className="w-full md:w-auto flex-shrink-0 align-middle uppercase bg-[#FFFFFF] hover:bg-[#28A745] text-[#146600] font-bold hover:border-[#28A745] text-[15px] font-nunito h-[38px]  border-0 hover:border-0"
                onClick={() => {
                  resetForm();
                  setKeyWord("");
                }}
              >
                LIMPAR
              </Button>
              <ModalReportGeneral
                onOpen={uncheckAll}
                filterSelectedSellers={filterSelectedUsers}
                hasSelected={hasSelected}
                datas={datas}
                cleanSelections={setSelections}
                chains={chains}
              />
              <ModalSellingDetails
                onOpen={uncheckAll}
                filterSelectedSellers={filterSelectedUsers}
                hasSelected={hasSelected}
                datas={[...selectedDatas]}
                cleanSelections={setSelections}
                chains={chains}
              />
            </div>
          </Form>
        )}
      </Formik>

      {loading && (
        <div className="w-full h-36 grid place-content-center p-20">
          <Loading color="#212529" size="w-10" />
        </div>
      )}

      {!loading && datas?.length === 0 && (
        <div className="w-full h-36 grid place-content-center p-20">
          <p>Nenhum usuário encontrado</p>
        </div>
      )}
      {!loading && datas?.length > 0 && (
        <div>
          <div className="h-[400px] overflow-auto" style={{ marginTop: "20px" }}>
            <table className="font-roboto border-[#DEE2E6]">
              <thead className="bg-white">
                <tr className="font-bold-roboto text-[18px] text-[#212529]">
                  <th>
                    <div className="flex justify-center items-center pb-2">
                      <InputText
                        type="checkbox"
                        onChange={toggleAll}
                        checked={Object.keys(selections).length === datas.length}
                      />
                    </div>
                  </th>
                  <th>
                    <div className="flex justify-left items-center">Vendedor</div>
                  </th>
                  <th className="hidden lg:table-cell">
                    <div className="flex justify-center items-center">email</div>
                  </th>
                  <th className="hidden xl:table-cell">
                    <div className="flex justify-center items-center">Celular</div>
                  </th>
                  <th className="hidden xl:table-cell">
                    <div className="flex justify-center items-center">Estado</div>
                  </th>
                  <th>
                    <div className="flex justify-center items-center">Cidade</div>
                  </th>
                  <th>
                    <div className="flex justify-center items-center">Status</div>
                  </th>
                  <th>
                    <div className="flex justify-center items-center">Relatório individual</div>
                  </th>
                </tr>
              </thead>
              <tbody className="border-b">
                {datas.map(data => (
                  <tr key={data.id} className="bg-white">
                    <td>
                      <div className="flex justify-center items-center pt-1">
                        <InputText
                          type="checkbox"
                          onChange={() => toggleItemSelection(data.id)}
                          checked={!!selections[data.id]}
                        />
                      </div>
                    </td>
                    <td>{data.name}</td>
                    <td className="hidden md:table-cell">{data.email}</td>
                    <td className="hidden lg:table-cell">{data.phone}</td>
                    <td className="hidden xl:table-cell">{data?.state?.name}</td>
                    <td className="hidden xl:table-cell">{data?.city?.name}</td>
                    <td className="text-center">
                      {data.status === 1 ? (
                        <p className="text-center text-[#28A745]">Ativo</p>
                      ) : (
                        <p className="text-center text-red-500">Desativado</p>
                      )}
                    </td>
                    <td className="text-center">
                      <ModalReportIndividual
                        onOpen={uncheckAll}
                        filterSelectedSellers={filterSelectedUsers}
                        hasSelected={hasSelected}
                        datas={data}
                        cleanSelections={setSelections}
                        chains={chains}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

          </div>
          <div className="flex justify-end">
            <TablePagination meta={meta} onChange={fetchSellers} />
          </div>
        </div>
      )}
    </div>
  );
});

export default OrderManager;
