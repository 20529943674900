import { DataTable } from "primereact/datatable";
import http from "../../plugins/http";
import { useEffect, useState } from "react";
import { Column } from "primereact/column";
import TablePagination from "../../components/TablePagination";
import { BreadCrumb } from "primereact/breadcrumb";

const LogsPage: React.FC = () => {
  const [logs, setLogs] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchKey, setSearchKey] = useState<string>("");

  const items = [{ label: 'Logs', url: '/logs' }];
  const home = { icon: 'pi pi-home', url: '/logs' }

  const fetchLogs = async ({
    page = currentPage,
    perPage = 12,
    search = searchKey,
  }: { page?: number; perPage?: number; search?: string } = {}) => {
    try {
      setLoading(true);
      const searchData = search ? `&search=${search}` : "";
      const data = await http.get(`v1/logs?all=1&perPage=${perPage}&page=${page}${searchData}`);
      if (data.status === 200) {
        setLogs(data.data);
      } else {
        console.error('Error to fetch logs', data);
      }
      setCurrentPage(page);
      setSearchKey(search);
    } catch (error) {
      console.error('Error to fetch logs', error);
    } finally {
      setLoading(false);
    }
  }

  const logsMapping = (log: any) => {
    if (/^\/v1\/buyer-demands\/\d+$/.test(log.route) && log.method === 'PUT') {
      return 'Atualizou demanda de compra'
    } else if (/^\/v1\/buyer-demands\/\d+$/.test(log.route) && log.method === 'DELETE') {
      return 'Deletou demanda de compra'
    } else if ('/auth/signin' === log.route && log.method === 'POST') {
      return 'Fez login'
    } else if (/^\/v1\/carriers\/\d+$/.test(log.route) && log.method === 'PUT') {
      return 'Atualizou uma transportadora'
    } else if (/^\/v1\/carriers\/\d+$/.test(log.route) && log.method === 'DELETE') {
      return 'Desativou uma transportadora'
    } else if (/^\/v1\/carriers$/.test(log.route) && log.method === 'POST') {
      return 'Cadastrou uma transportadora'
    }
    const routes: any = {
      '/v1/carriers/downlodPdfById': [
        {
          description: 'Baixou relatório em PDF de transportadoras',
          method: 'POST',
        }
      ],
      '/v1/reports/products': [
        {
          description: 'Listou relatório de produtos',
          method: 'GET',
        },
        {
          description: 'Gerou relatório de produtos',
          method: 'POST',
        }
      ],
      '/': [
        {
          description: 'Acessou a home da API',
          method: 'GET',
        }
      ],
      '/signin': [
        {
          description: 'Fez login',
          method: 'POST',
        }
      ],
      '/signout': [
        {
          description: 'Fez logout',
          method: 'POST',
        }
      ],
      '/signup': [
        {
          description: 'Criou uma conta',
          method: 'POST',
        }
      ],
      '/v1/users': [
        {
          description: 'Listou usuários',
          method: 'GET',
        },
        {
          description: 'Criou um usuário',
          method: 'POST',
        }
      ],
      '/v1/users/:id': [
        {
          description: 'Atualizou um usuário',
          method: 'PUT',
        },
        {
          description: 'Deletou um usuário',
          method: 'DELETE',
        }
      ],
      '/v1/logs': [
        {
          description: 'Acessou a listagem de logs',
          method: 'GET',
        }
      ],
      '/v1/managers/users/:id': [
        {
          description: 'Atualizou um usuário',
          method: 'PUT',
        }
      ],
    }
    if (routes[log.route]) {
      if (routes[log.route].find((route: any) => route.method === log.method)) {
        return routes[log.route].find((route: any) => route.method === log.method).description
      }
    }
    let description = ''
    if (log.method === 'GET') {
      description += 'Acessou '
    } else if (log.method === 'POST') {
      description += 'Criou '
    } else if (log.method === 'PUT') {
      description += 'Atualizou '
    } else if (log.method === 'DELETE') {
      description += 'Deletou '
    }
    description += log.route
    return description
  }

  useEffect(() => {
    fetchLogs();
  }, []);


  return (<>
    <BreadCrumb model={items} home={home} className="mb-4" />
    <DataTable className="w-full" loading={loading} value={logs.data}>
      <Column field="id" header="ID" />
      <Column field="route" header="Rota" />
      <Column field="method" header="Método" />
      <Column body={(e) => <span>{e.user?.name} ({e.user?.email})</span>} header="Usuário" />
      <Column field="ip" header="IP" />
      <Column body={(e) => logsMapping(e)} header="Descrição" />
    </DataTable>
    <TablePagination meta={logs.meta} onChange={page => fetchLogs(page)} />
  </>);
};

export default LogsPage;
