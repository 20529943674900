const filesBlob = new Map<string, string>()
filesBlob.set("xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
filesBlob.set("pdf", "application/pdf")
filesBlob.set("csv", "text/csv")
filesBlob.set("txt", "text/plain")
filesBlob.set("json", "application/json")
filesBlob.set("xml", "application/xml")
filesBlob.set("zip", "application/zip")


const exportBlobToFile = (data: BlobPart[], fileName: string ) => {
  const blob = new Blob(data, {
    type: filesBlob.get(fileName.split('.').pop() || '') || 'text/plain',
  })
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement("a")
  a.href = url
  a.download = fileName
  document.body.appendChild(a)
  a.click()
  a.remove()
  window.URL.revokeObjectURL(url)
}

export { filesBlob, exportBlobToFile }