import React, { useEffect, useState } from "react"
import FormikInput from "../../components/FormikInput"
import FormikPassword from "../../components/FormikPassword"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import http from "../../plugins/http"
import { useToast } from "../../components/Toast"
import { useSearchParams } from "react-router-dom"
import { Button } from "primereact/button"

interface MyFormValues {
  email: string
  password: string
}

const Schema = Yup.object().shape({
  email: Yup.string().email("Digite um email correto").required("Email obrigatório"),
  password: Yup.string().required("Senha obrigatória"),
})

const LoginPage: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const toast = useToast()
  const initialValues: MyFormValues = { email: "", password: "" }
  const [isLoading, setIsLoading] = useState(false)


  async function login({ email, password }: MyFormValues) {
    try {
      setIsLoading(true)
      const response = await http.post("auth/signin", {
        email: email,
        password: password,
      })
      if (response.status === 200) {
        localStorage.setItem("token", response.data.token)
        localStorage.setItem("user", JSON.stringify(response.data))
        window.location.href = "/home"
      } else {
        showError("Email ou senha incorretos")
      }
    } catch (error) {
      console.error(error)
      showError("Ocorreu um erro ao fazer login")
    } finally {
      setIsLoading(false)
    }
  }

  function showError(message: string) {
    toast.current?.show({ severity: 'error', summary: 'Ocorreu um erro', detail: message })
  }

  useEffect(() => {
    const sessionExpired = searchParams.get('sessionExpired');
    if (sessionExpired) {
      toast.current?.show({ severity: 'error', summary: 'Desconectado', detail: 'Sua seção expirou, entre novamente' })
    }
  }, [])

  return (
    <div className="w-full flex flex-col h-fit items-center pt-40 bg-[#F2F2F2]">
      <Formik
        initialValues={initialValues}
        validationSchema={Schema}
        onSubmit={(values, actions) => {
          login(values)
        }}
      >
        <Form>
          <div className="flex mb-10">
            <div className="text-[#146600] max-2xl:text-7xl max-md:text-5xl font-bold text-[5.5rem] font-sans Open">
              BIO
            </div>
            <div className="max-md:text-5xl max-2xl:text-7xl text-[5.5rem] font-sans Open">CONEX
            </div>

          </div>
          <FormikInput name="email" label="Email" placeholder="Digite seu email" />
          <FormikPassword name="password" label="Senha" placeholder="Digite sua senha" style={{ marginTop: "20px" }} />
          <div className="flex justify-end whitespace-nowrap mt-3">
            {/* <span className="mr-7">Esqueceu a senha?</span> */}
            <Button
              type="submit"
              className="bg-[#205F16] max-sm:text-xs hover:bg-[#317025] flex flex-wrap gap-x-4"
              loading={isLoading}
              severity="success"
            >
              ENTRAR
            </Button>
          </div>
        </Form>
      </Formik>
    </div>
  )
}

export default LoginPage
