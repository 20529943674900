import http from "../plugins/http";

/* export const fetchTypeOfChestnut = async ({ query = "?all=1&types=1" }: { query?: string } = {}) => {
  const data = await http.get(`product-types${query}`);
  return data;
};
  */

export const fetchTypeOfChestnut = async ({ query = "?all=1" }: { query?: string } = {}) => {
  const data = await http.get(`v1/product-types${query}`);
  return data;
};
