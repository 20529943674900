import { useEffect, useState } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { Dialog } from 'primereact/dialog';
import PageHeader from "../../components/PageHeader";
import SearchBar from "../../components/SearchBar";
import { Block, CheckCircle } from "@styled-icons/boxicons-regular";
import http from "../../plugins/http";
import Loading from "../../components/Loading";
import ModalAlert from "../../components/ModalAlert";
import ModalEditWarehouse from "./Edit/Modal";
import ModalRegisterWarehouse from "./Register/Modal";

const TableRowActions = ({
  toggle,
  warehouse,
  update,
}: {
  toggle: (warehouse: any) => void;
  warehouse: any;
  update: () => void;
}) => {
  return (
    <th scope="row" className="flex flex-wrap w-20">
      {warehouse.status === 1 ? (
        <Block onClick={() => toggle(warehouse)} size={25} color="#F91B1B" className="cursor-pointer" />
      ) : (
        <CheckCircle onClick={() => toggle(warehouse)} size={26} color="#28A745" className="cursor-pointer" />
      )}
      <ModalEditWarehouse warehouse={warehouse} className="w-1/2" onEdit={() => update()} />
    </th>
  );
};

function WarehousesPage() {
  const [warehouses, setWarehouses] = useState<any[]>([]);
  const [meta, setMeta] = useState<any>({});
  const [modalAlert, setModalAlert] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [modalAlertData, setModalAlertData] = useState({ title: "", body: "" });

  async function fetchWarehouses({
    page = 1,
    perPage = 10,
    search,
  }: { page?: number; perPage?: number; search?: string } = {}) {
    try {
      setLoading(true);
      const searchData = search ? `&search=${search}` : "";
      const response = await http.get(`v1/warehouses?all=1&perPage=${perPage}&page=${page}${searchData}`);
      if (response.status !== 200) {
        return;
      }
      setWarehouses(response.data);
      setMeta(response.data.meta);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  function searchUsers(val: string) {
    console.log(val);
    fetchWarehouses({ search: val });
  }

  async function toggleWarehouseStatus(warehouse: any) {
    console.log("vai mudar o status da warehouse ", warehouse);
    try {
      setLoading(true);
      const response = await http.put(`v1/warehouses/${warehouse.id}`, {
        status: warehouse.status === 1 ? 0 : 1,
      });
      if (response.status !== 200) {
        console.log("Error toggling user status");
        return;
      }
      openModalAlert("Sucesso", `Armazém ${warehouse.status === 1 ? "desativado" : "ativado"} com sucesso`);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const openModalAlert = (title: string, body: string) => {
    setModalAlertData({ title, body });
    setModalAlert(true);
  };

  const closeModalAlert = () => {
    setModalAlert(false);
    setModalAlertData({ title: "", body: "" });
    fetchWarehouses();
  };

  useEffect(() => {
    fetchWarehouses();
  }, []);

  return (
    <div className="w-full p-10">
      <PageHeader>Controle de Armazéns</PageHeader>
      <div className="w-full flex justify-start">
        <ModalRegisterWarehouse created={() => openModalAlert("Sucesso", "Armazém cadastrado com sucesso")} />
        <ModalAlert
          color="success"
          title={modalAlertData.title}
          body={modalAlertData.body}
          state={modalAlert}
          onClose={closeModalAlert}
        />
        <SearchBar onSearch={val => searchUsers(val)} />
      </div>
      {loading && (
        <div className="w-full h-36 grid place-content-center p-20">
          <Loading color="#212529" size="w-10" />
        </div>
      )}
      {!loading && warehouses.length === 0 && (
        <div className="w-full h-36 grid place-content-center p-20">
          <p>Nenhum armazém encontrado</p>
        </div>
      )}
      {!loading && warehouses.length > 0 && (
        <div>
          <DataTable value={warehouses} className="mt-10 font-roboto">
            <Column field="id" header="#" body={(rowData) => <TableRowActions toggle={toggleWarehouseStatus} warehouse={rowData} update={() => openModalAlert("Sucesso", "Armazém editado com sucesso")} />}></Column>
            <Column field="fantasy_name" header="Nome"></Column>
            <Column field="cnpj" header="CNPJ"></Column>
            <Column field="contacts.responsible" header="Responsável"></Column>
            <Column field="contacts.role" header="Cargo"></Column>
            <Column field="contacts.contact_1" header="Celular"></Column>
            <Column field="state.name" header="Estado"></Column>
            <Column field="city.name" header="Cidade"></Column>
            <Column field="status" header="Situação" body={(rowData) => <Tag value={rowData.status === 1 ? 'Ativo' : 'Desativado'} severity={rowData.status === 1 ? 'success' : 'danger'} />}></Column>
          </DataTable>
        </div>
      )}
    </div>
  );
}

export default WarehousesPage;
