import React from "react";
import { Field, useField } from "formik";
import ErrorMessage from "./ErrorMessage";

interface IFormikTextArea {
  label: string;
  name: string;
  style?: object;
  className?: string;
  cols?: number;
  rows?: number;
  initialValue?: string;
  onChange?: (value: string) => void;
  errors?: any;
  error?: string;
}

const FormikTextArea: React.FC<IFormikTextArea> = ({
  name,
  label,
  style,
  className = "w-full",
  rows,
  errors,
  error
}: IFormikTextArea) => {
  const [field, meta] = useField(name);
  return (
    <div className={`${className} flex flex-col items-start`} style={style}>
      <label htmlFor={name} className={`font-sans Roboto font-normal max-sm:w-64 mb-0`}>
        {label}
      </label>
      <div className={`w-full ${errors && meta.touched ? "border-[1px] rounded-md border-[#DC3545]" : ""}`}>
        <Field
          {...field}
          as="textarea"
          className={`
            w-full
            border
            focus:outline-none
            focus:ring-2
            p-2
            rounded-md
            font-sans
            max-sm:w-64
            ${meta.touched && meta.error ? "border-red-500" : "border-gray-300"}
          `}
          rows={rows}
          name={name}
        />
      </div>
      {meta.error && meta.touched && <ErrorMessage message={meta.error} />}
      {error && <ErrorMessage message={error} />}
    </div>
  );
};

export default FormikTextArea;
