import Sidebar from "../components/Sidebar";
import { MainContainer, MainContent } from "./styles";

interface IMainContainer {
  children: React.ReactNode;
}

export const MainLayout = ({ children }: IMainContainer) => {
  return (
    <MainContainer>
      <MainContent>
        <div className="w-full flex h-screen">
          <Sidebar />
          <div className="h-screen overflow-y-auto flex-1 bg-gray-100 p-2">{children}</div>
        </div>
      </MainContent>
    </MainContainer>
  );
};
