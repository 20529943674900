import React from "react";
import { useLocation } from "react-router-dom";
import { Button } from "primereact/button";
import TrackingEvents from "../Components/TrackingUpdates";
function ProductTrackerViewPage() {
  const trackerDetail: any = useLocation()?.state?.detail;
  const buyer = trackerDetail.buyer;
  const product = trackerDetail.product;
  const carrier = trackerDetail.carrier;
  return (
    <div className="w-full p-10 bg-white ml-5">
      <h1 className="text-[#146600] font-bold text-2xl pt-[50px] pb-[25px] font-roboto">Rastreio de Produto</h1>
      <h1
        className="text-[#146600] font-bold text-2xl pt-[50px] pb-[15px] font-roboto"
        style={{ borderBottom: "1px solid #BAE7AC" }}
      >
        Produto
      </h1>
      <div className="flex flex-wrap mt-2.5">
        <div className="w-full lg:w-1/4 mb-3">
          <h1 className="text-[#146600] text-xl font-medium">{buyer.name}</h1>
          <span className="text-[#666666] text-sm font-normal">Comprador de castanha</span>
          <br />
          <span className="text-[#666666] text-sm font-normal">Postagem em: {buyer.post_date}</span>
          <br />
          <span className="text-[#666666] text-sm font-normal">Estado: {buyer.state}</span>
          <br />
          <span className="text-[#666666] text-sm font-normal">Cidade: {buyer.city}</span>
        </div>

        <div className="w-full lg:w-1/4 mb-3">
          <h1 className="text-[#146600] text-xl font-medium">{product?.name}</h1>
          <span className="text-[#666666] text-sm font-normal">
            Tipo da castanha: <span className="text-[#666666] text-sm font-normal">{product?.type}</span>
          </span>
          <br />
          <span className="text-[#666666] text-sm font-normal">Peso: {product?.weight}</span>
          <br />
          <span className="text-[#666666] text-sm font-normal">Estoque: {product?.stock}</span>
          <br />
          <span className="text-[#666666] text-sm font-normal">Origem: {product?.origin}</span>
          <br />
        </div>

        <div className="w-full lg:w-1/4 mb-3 flex items-center justify-center">
          <h1>R$ {product?.price}</h1>
        </div>

        <div className="w-full lg:w-1/4 mb-3 flex flex-col">
          <h1 className="text-[#146600] text-xl font-medium">Previsão de entrega</h1>
          <span className="text-[#666666] text-sm font-normal">
            Caso não receba sua compra restituiremos somente o valor do produto.
          </span>
        </div>
      </div>

      <h1
        className="text-[#146600] font-bold text-2xl pt-[50px] pb-[15px] font-roboto"
        style={{ borderBottom: "1px solid #BAE7AC" }}
      >
        Destino:
      </h1>
      <div className="flex flex-wrap mt-2.5">
        <div className="w-full lg:w-1/5 mb-3">
          <h1 className="text-[#666666] text-xl font-medium">{buyer.destiny}</h1>
        </div>
        <div className="w-full lg:w-1/5 mb-3">
          <h1 className="text-[#666666] text-xl font-medium">
            {carrier.name} - {carrier.delivery_time} DIAS
          </h1>
        </div>
        <div className="w-full lg:w-1/5 mb-3 flex items-center justify-center">R$ {carrier?.price}</div>
        <div className="w-full lg:w-1/5 mb-3 flex items-center justify-center">
          <Button
            className="uppercase text-[15px] font-nunito h-[38px] font-bold bg-green-500 border-0 hover:bg-[#479632] disabled:opacity-50 flex items-center justify-center"
            disabled={true}
          >
            Pago
          </Button>
        </div>
        <div className="w-full lg:w-1/5 mb-3 flex items-center justify-center">
          <Button
            color="success"
            className="uppercase text-[15px] font-nunito h-[38px] font-bold  hover:bg-[#479632] bg-green-500 border-0 hover:border-0  flex flex-wrap gap-x-4"
          >
            NOTA FISCAL
          </Button>
        </div>
      </div>
      <h1 className="text-[#146600] font-bold text-2xl pt-[50px] pb-[15px] font-roboto mb-5">Detalhes da entrega:</h1>
      <TrackingEvents />
    </div>
  );
}

export default ProductTrackerViewPage;
