import { Form, Formik, FormikProps, useFormikContext } from "formik";
import { useEffect, useRef, useState } from "react";
import FormikInput from "../../../components/FormikInput";
import FormikSelect from "../../../components/FormikSelect";
import { fetchCities } from "../../../api/cities";
import http from "../../../plugins/http";
import FormikInputMask from "../../../components/FormikInputMask";
import { Edit } from "@styled-icons/boxicons-regular";
import Schema from "./validations";
import representativeSchema from "./representativeValidations";
import bancos from "../../../assets/json/bancos.json";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Message } from "primereact/message";

function ModalEditUser({
  user,
  onEdit,
  className = "",
  roles,
  states,
}: {
  user: any;
  onEdit: () => void;
  className?: string;
  roles: any[];
  states: any[];
}) {
  const [modal, setModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [cities, setCities] = useState<any[]>([]);
  const [schema, setSchema] = useState<any>(Schema);
  const [setCommision] = useState<number>(user.commision_percentage);
  const [isRepresentativeSelected, setIsRepresentativeSelected] = useState(false);
  const [role, setRole] = useState(user.role.name);
  const [initialValues, setInitialValues] = useState<any>({
    name: user.name,
    cpf_or_cnpj: user.cpf_or_cnpj,
    email: user.email,
    password: "",
    confirm_password: "",
    phone: user.phone,
    id_state: user.state?.id,
    id_city: user.city?.id,
    id_role: user.role.id,
    business_profile: user.business_profile,
    bank_name: user.bank_name,
    id_area: user.area?.id,
    bank_agency: user.bank_agency,
    commision_percentage: user.commision_percentage,
    bank_account: user.bank_account,
    pix_key: user.pix_key,
    flags: "",
  });
  const formikRef = useRef<FormikProps<typeof initialValues>>(null);

  const toggle = () => {
    const isRepresentative = user.role.name === "representative";
    setRole(isRepresentative ? "Representante" : "");
    setInitialValues({
      ...initialValues,
      flags: findProfile(user.flags),
    });
    setIsRepresentativeSelected(isRepresentative);
    setModal(prevModal => !prevModal);
    if (!modal && user.id_state) {
      fetchCities({ id_state: user.id_state }).then(res => {
        setCities(res.data);
      });
    }
  };

  const flags = [
    { label: "Produtor", value: "producer" },
    { label: "Governo", value: "government" },
    { label: "Indústria", value: "industry" },
    { label: "Representante", value: "representative" },
    { label: "Varejo", value: "retail" },
    { label: "Atacado", value: "wholesale" },
    { label: "Financiador", value: "funder" },
    { label: "Outro", value: "other" },
  ];

  const perfilComercial = [
    { label: "Bebidas", value: "Bebidas" },
    { label: "Alimentos", value: "Alimentos" },
    { label: "Fármaco", value: "Fármaco" },
    { label: "Varejo", value: "Varejo" },
    { label: "Saúde", value: "Saúde" },
  ];

  function FormButtons() {
    const { submitForm } = useFormikContext();
    return (
      <div className="w-full flex justify-end p-4 gap-x-4">
        <Button onClick={toggle} disabled={loading} severity="danger" outlined>
          Cancelar
        </Button>
        <Button type="submit" severity="success" onClick={submitForm} loading={loading}>
          Atualizar
        </Button>
      </div>
    );
  }

  async function stateSelected(id: number) {
    setLoading(true);
    initialValues.id_city = "";
    fetchCities({ id_state: id }).then(res => {
      setCities(res.data);
      setLoading(false);
    });
  }

  function showError(message: string) {
    setError(message);
    setTimeout(() => {
      setError("");
    }, 3000);
  }

  function findProfile(flags: any) {
    if (!flags) return "";
    for (let [key, value] of Object.entries(flags)) if (value) return key;
    return "";
  }

  const loadRepresentativeForm = (e: any) => {
    const role = roles.find(x => x.value === e);
    setRole(role ? role.label : "");
    if (role.label === "Representante") {
      setSchema(representativeSchema);
      setIsRepresentativeSelected(role?.label === "Representante");
      if (user.role.name !== "representative") {
        formikRef.current?.setFieldValue("business_profile", "");
        formikRef.current?.setFieldValue("id_area", "");
        formikRef.current?.setFieldValue("commision_percentage", "");
        formikRef.current?.setFieldValue("bank_name", "");
        formikRef.current?.setFieldValue("bank_agency", "");
        formikRef.current?.setFieldValue("bank_account", "");
        formikRef.current?.setFieldValue("pix_key", "");
      }
    } else {
      setSchema(Schema);
      setIsRepresentativeSelected(false);
    }
  };

  async function edit(values: any) {
    try {
      setLoading(true);
      if (values.password && values.password.length < 6) {
        delete values.password;
        delete values.confirm_password;
      }
      if (role !== "Representante") {
        values.commision_percentage = null;
        values.id_area = null;
        values.business_profile = null;
        values.bank_name = null;
        values.bank_agency = null;
        values.bank_account = null;
        values.pix_key = null;
      }

      const flag = values.flags;
      values.flags = {};
      Object.entries(user.flags).map(([userflag, value]) => {
        values.flags[userflag] = userflag === flag;
      });
      if (values.flags[flag] === undefined) {
        values.flags[flag] = true;
      }

      const response = await http.put(`/v1/managers/users/${user.id}`, {
        ...values,
      });
      if (response.status === 200) {
        toggle();
        if (onEdit != null) onEdit();
      } else if (response.data.hasOwnProperty("message")) {
        showError(response.data.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (user.role.name === "representative") {
      setRole("Representante");
      setIsRepresentativeSelected(true);
    }
  }, [user.role.name]);

  return (
    <div className="inline-flex">
      <Edit onClick={toggle} size={25} color="#1F5974" className="cursor-pointer" />

      <Dialog header="Editar usuário" visible={modal} onHide={toggle} className={className}>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={(values, actions) => {
            edit(values);
          }}
          key={"form-edit-user"}
        >
          <>
            <Form
              className="flex flex-wrap space-y-6 p-10 pt-0"
              style={isRepresentativeSelected ? { height: "40rem", overflowY: "scroll" } : {}}
            >

              <FormikInput className="w-full" name="name" label="Nome completo" placeholder="Digite o nome" />
              <div className="flex w-full space-x-10">
                <FormikSelect
                  label="Tipo de usuário"
                  name="id_role"
                  className="w-1/2"
                  defaultValue={user.id_role}
                  options={roles}
                  onChange={e => loadRepresentativeForm(e)}
                />
                <FormikInputMask
                  mask={["999.999.999-99", "99.999.999/9999-99"]}
                  maskChar=""
                  className="w-1/2"
                  name="cpf_or_cnpj"
                  label="CPF/CNPJ"
                  placeholder="Digite o CPF/CNPJ"
                />
              </div>
              <div className="flex w-full space-x-10">
                <FormikInput className="w-1/2" name="email" label="Email" placeholder="Digite o email" />
                <FormikInputMask
                  className="w-1/2"
                  name="phone"
                  mask="(99) 99999-9999"
                  label="Celular"
                  placeholder="Digite o número de celular"
                />{" "}
              </div>
              <div className="flex w-full space-x-10">
                {states && (
                  <FormikSelect
                    label="Estado"
                    name="id_state"
                    className="w-1/2"
                    defaultValue={user.id_state}
                    options={states?.map(state => ({ label: state?.name, value: state?.id }))}
                    onChange={e => stateSelected(parseInt(e))}
                  />
                )}
                {cities && (
                  <FormikSelect
                    label="Cidade"
                    name="id_city"
                    defaultValue={user.id_city}
                    className="w-1/2"
                    options={cities.length > 0 ? cities?.map(city => ({ label: city.name, value: city.id })) : []}
                  />
                )}
              </div>
              <div className="flex w-full space-x-10">
                <FormikSelect label="Perfil" name="flags" className="w-1/2" options={flags} />
                <div className="w-1/2"></div>
              </div>
              <Message className="w-full mb-4" severity="info" text="Deixe a senha em branco caso não queira alterar" />
              <div className="flex w-full space-x-10">
                <FormikInput
                  className="w-1/2"
                  name="password"
                  label="Senha de acesso"
                  placeholder="Digite a senha"
                  type="password"
                />
                <FormikInput
                  className="w-1/2"
                  name="confirm_password"
                  label="Repita a senha de acesso"
                  placeholder="Digite a senha"
                  type="password"
                />
              </div>
              {role === "Representante" && (
                <>
                  <div className="flex w-full items-center space-x-50">
                    <div className="flex-grow h-px bg-gray-300"></div>
                  </div>
                  <div className="flex w-full items-start space-x-40">
                    <div className="flex items-center mr-5">
                      <h2 style={{ color: "#146600" }}>Dados Comerciais</h2>
                    </div>
                    <div className="flex items-center">
                      <h2 className="font-sans Roboto font-normal ml-12" style={{ color: "#146600" }}>
                        Dados Bancários
                      </h2>
                    </div>
                  </div>
                  <div className="flex w-full space-x-10">
                    <FormikSelect
                      label="Perfil Comercial"
                      name="business_profile"
                      className="w-1/2"
                      options={perfilComercial}
                    />
                    <FormikSelect
                      className="w-1/2"
                      name="bank_name"
                      label="Banco"
                      placeholder="Digite o nome do banco"
                      options={bancos}
                    />
                  </div>
                  <div className="flex w-full space-x-10">
                    <FormikSelect
                      label="Área/Região"
                      name="id_area"
                      defaultValue="user.area.id"
                      className="w-1/2"
                      options={cities.length > 0 ? cities?.map(city => ({ label: city.name, value: city.id })) : []}
                    />
                    <FormikInput className="w-1/2" name="bank_agency" label="Agência" placeholder="Digite a agência" />
                  </div>
                  <div className="flex w-full space-x-10">
                    <div className="w-1/2"></div>
                    <FormikInput className="w-1/2" name="pix_key" label="Chave pix" placeholder="Digite a chave pix" />
                  </div>
                </>
              )}
            </Form>
            <FormButtons />
            {error !== "" && (
              <Message className="w-full mt-4" color="danger">
                {error}
              </Message>
            )}
          </>
        </Formik>
      </Dialog>
    </div>
  );
}

export default ModalEditUser;
