import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import http from "../../plugins/http";
import TablePagination from "../../components/TablePagination";
import { fetchStates } from "../../api/states";
import FreightAdd from "./FreightAdd";
import { IStateData } from "../../interfaces/endpoints/models";


const FreightList: React.FC = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [meta, setMeta] = useState<any>({});
  const [freights, setFreights] = useState<any[]>([]);
  const [states, setStates] = useState<IStateData[]>([]);

  async function fetchFreights({
    page = 1,
    perPage = 10,
    search,
  }: { page?: number; perPage?: number; search?: string } = {}) {
    try {
      setLoading(true);
      const searchData = search ? `&search=${search}` : "";
      const response = await http.get(`v1/freight?perPage=${perPage}&page=${page}${searchData}`);
      if (response.status !== 200) {
        return;
      }
      setFreights(response.data.data);
      setMeta(response.data.meta);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const onEdit = (freight: any) => {
    console.log(freight);
  }

  const onDelete = (id: number) => {
    console.log(id);
  }

  const actionBodyTemplate = (rowData: any) => {
    return (
      <div className="flex justify-end gap-2">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success"
          onClick={() => onEdit(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger"
          onClick={() => onDelete(rowData.id!)}
        />
      </div>
    );
  };

  useEffect(() => {
    fetchFreights();
    fetchStates().then(res => {
      setStates(res.data);
      setLoading(false);
    });
  }, []);


  return (
    <>
      <div className="p-4 bg-white">
        <FreightAdd states={states} onSubmit={() => fetchFreights()} />
        <DataTable value={freights} showGridlines paginator rows={10}>
          <Column body={(data) => <div>{data?.city_origin?.name}/{data?.state_origin?.short_name}</div>} header="Origem" sortable />
          <Column body={(data) => <div>{data?.city_destination?.name}/{data?.state_destination?.short_name}</div>} header="Destino" sortable />
          <Column field="time_in_days" header="Tempo (Dias)" sortable />
          <Column field="transport_value_including_insurance" header="Valor Transporte" sortable />
          <Column field="invoice_value" header="Valor Nota Fiscal" sortable />
          <Column field="weight_kg" header="Peso (kg)" sortable />
          <Column field="volume_m3" header="Volume (m³)" sortable />
          <Column field="has_loading_and_unloading" header="Carga/Descarga" body={(rowData) => (rowData.has_loading_and_unloading ? 'Sim' : 'Não')} />
          <Column field="departure_on_sunday" header="Saída Domingo" body={(rowData) => (rowData.saidaDomingo ? 'Sim' : 'Não')} />
          <Column field="departure_on_monday" header="Saída Segunda" body={(rowData) => (rowData.saidaSegunda ? 'Sim' : 'Não')} />
          <Column field="departure_on_tuesday" header="Saída Terça" body={(rowData) => (rowData.saidaTerca ? 'Sim' : 'Não')} />
          <Column field="departure_on_wednesday" header="Saída Quarta" body={(rowData) => (rowData.saidaQuarta ? 'Sim' : 'Não')} />
          <Column field="departure_on_thursday" header="Saída Quinta" body={(rowData) => (rowData.saidaQuinta ? 'Sim' : 'Não')} />
          <Column field="departure_on_friday" header="Saída Sexta" body={(rowData) => (rowData.saidaSexta ? 'Sim' : 'Não')} />
          <Column field="departure_on_saturday" header="Saída Sábado" body={(rowData) => (rowData.saidaSabado ? 'Sim' : 'Não')} />
          <Column body={actionBodyTemplate} />
        </DataTable>
        <TablePagination
          meta={meta}
          onChange={page => fetchFreights(page)} />
      </div>
    </>
  );
};

export default FreightList;
