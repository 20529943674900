import { useEffect, useState } from "react";
import PageHeader from "../../components/PageHeader";
import SearchBar from "../../components/SearchBar";
import ModalRegisterUser from "./Register/Modal";
import http from "../../plugins/http";
import Loading from "../../components/Loading";
import ModalEditUser from "./Edit/Modal";
import TablePagination from "../../components/TablePagination";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useToast } from "../../components/Toast";
import ModalEnableAndDisableUser from "./EnableAndDisable/Modal";
import ModalUserJustifications from "./Justification/Modal";
import { fetchRoles } from "../../api/roles";
import { fetchStates } from "../../api/states";
import { BreadCrumb } from "primereact/breadcrumb";

const TableRowActions = ({
  user,
  update,
  changeStatus,
  roles,
  states,
}: {
  user: any;
  update: () => void;
  roles: any[],
  states: any[],
  changeStatus: (user: any, form: any) => void;
}) => {
  return (
    <th scope="row" className="w-20 flex flex-row space-x-2">
      <ModalEnableAndDisableUser user={user} className="w-1/3" changeStatus={changeStatus} />
      <ModalEditUser roles={roles} states={states} user={user} className="w-1/3" onEdit={() => update()} />
      <ModalUserJustifications user={user} className="w-1/3" />
    </th>
  );
};

function UsersPage() {
  const toast = useToast();
  const [users, setUsers] = useState<any[]>([]);
  const [meta, setMeta] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchKey, setSearchKey] = useState<string>("");
  const [roles, setRoles] = useState<any[]>([]);
  const [states, setStates] = useState<any[]>([]);

  async function fetchUsers({
    page = currentPage,
    perPage = 10,
    search = searchKey,
  }: { page?: number; perPage?: number; search?: string } = {}) {
    try {
      setLoading(true);
      const searchData = search ? `&search=${search}` : "";
      const response = await http.get(`v1/users?all=1&perPage=${perPage}&page=${page}${searchData}`);
      if (response.status !== 200) {
        return;
      }
      setUsers(response.data.data);
      setMeta(response.data.meta);
      setCurrentPage(page);
      setSearchKey(search);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  function searchUsers(val: string) {
    console.log(val);
    fetchUsers({ search: val });
  }

  async function toggleUserStatus(user: any, form: any) {
    try {
      setLoading(true);
      const justification = form.justification;
      const user_id = user.id;
      const response = await http.post(`v1/justifications`, {
        justification,
        id_user_target: user_id,
        action: user.status === 1 ? "Desativação" : "Ativação",
      });
      if (response.status !== 200) {
        console.log("Error toggling user status");
        return;
      }
      toast.current?.show({
        severity: "success",
        summary: "Sucesso",
        detail: `Usuário ${user.status === 1 ? "desativado" : "ativado"} com sucesso`,
      });
    } catch (error) {
      console.log(error);
    } finally {
      fetchUsers();
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchUsers();
    fetchRoles().then(res => {
      setRoles(res.data.map((x: any) => ({ label: x.public_name, value: x.id })));
    });
    fetchStates().then(res => {
      setStates(res.data);
    });
  }, []);

  const statusTemplate = (rowData: any) => (
    <div className="text-center">
      {rowData.status === 1 ? <p className="text-[#28A745]">Ativo</p> : <p className="text-red-500">Desativado</p>}
    </div>
  );

  const dateTemplate = (rowData: any) => (
    <div>
      {new Date(rowData.created_at).toLocaleDateString("pt-BR", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      })}
    </div>
  );

  const actionTemplate = (rowData: any) => (
    <div>
      <TableRowActions
        user={rowData}
        roles={roles}
        states={states}
        update={() => {
          toast.current?.show({
            severity: "success",
            summary: "Sucesso",
            detail: "Usuário editado",
          });
          fetchUsers();
        }}
        changeStatus={toggleUserStatus}
      />
    </div>
  );

  const items = [{ label: 'Gerenciamento de usuários', url: '/users' }];
  const home = { icon: 'pi pi-home', url: '/home' }

  return (
    <div className="p-10">
      <BreadCrumb model={items} home={home} className="mb-4" />
      <div className="flex justify-start">
        <ModalRegisterUser
          updated={() =>
            toast.current?.show({
              severity: "success",
              summary: "Sucesso",
              detail: "Usuário cadastrado",
            })
          }
        />
        <SearchBar onSearch={val => searchUsers(val)} />
      </div>
      {loading && (
        <div className="h-36 grid place-content-center p-20">
          <Loading color="#212529" size="w-10" />
        </div>
      )}
      {!loading && users.length === 0 && (
        <div className="h-36 grid place-content-center p-20">
          <p>Nenhum usuário encontrado</p>
        </div>
      )}
      {!loading && users.length > 0 && (
        <div>
          <DataTable showGridlines value={users} className="mt-10 font-roboto text-sm">
            <Column body={actionTemplate} className="text-center" />
            <Column field="name" header="Nome" />
            <Column field="cpf_or_cnpj" header="CPF/CNPJ" />
            <Column field="email" header="Email" />
            <Column field="phone" header="Celular" />
            <Column
              body={row => (
                <span>
                  {row?.city?.name}/{row?.state?.short_name}
                </span>
              )}
              header="Cidade"
            />
            <Column field="role.public_name" header="Perfil" />
            <Column body={statusTemplate} header="Situação" />
            <Column body={dateTemplate} header="Data. Cadastro" />
          </DataTable>
          <TablePagination meta={meta} onChange={page => fetchUsers(page)} />
        </div>
      )}
    </div>
  );
}

export default UsersPage;
