import tw from "twin.macro"

const BtnTab = tw.button`
px-4 py-2 shadow-md hover:bg-green-400 bg-white hover:text-white bg-[#FFFFFF] border-[#D4D4D4] text-[#3D3D3D] rounded-none mt-5
`

const BtnTabActive = tw.button`
px-4 py-2 shadow-md bg-green-500 text-white bg-[#FFFFFF] border-[#D4D4D4] text-[#3D3D3D] rounded-none mt-5
`

export const BtnTabOption = ({ active, onClick, children }: any) => {
  return active ? (
    <BtnTabActive onClick={onClick}>{children}</BtnTabActive>
  ) : (
    <BtnTab onClick={onClick}>{children}</BtnTab>
  )
}
