import { Filter, FilterCircleXmark } from "@styled-icons/fa-solid";
import { useState, useEffect, memo } from "react";
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import PageHeader from "../PageHeader";

const DataPrivacyControl = memo(function DataPrivacyControl(args: any) {
  const [selectedData, setSelectedData] = useState<any[] | null>(null);
  const [rowClick] = useState<boolean>(true);
  const [datas, setDatas] = useState<any[]>(args.data);
  const defaultDatas = args.data;

  const setSelectedRows = (value: any) => {
    args.setSelectedRows(value);
    setSelectedData(value);
  }

  const setGroupValue = (value: string | undefined) => {
    args.setGroupValue(value);
  };
  const setGroupValue2 = (value: string | undefined) => {
    args.setGroupValue2(value);
  };

  function clearAllCheckboxes() {
    const checkboxes = document.querySelectorAll("input[type=checkbox]");
    checkboxes.forEach((checkbox: any) => {
      checkbox.checked = false;
    });
    setGroupValue(undefined);
    setGroupValue2(undefined);
  }

  function filterData(e: any) {
    const value = e.target.value;
    if (value === "") return setDatas(defaultDatas);
    const filtered = defaultDatas.filter((data: { name: string; group: any; subgroup: any }) => {
      const completeName = data.name + " - " + data.group.value + " - " + data.subgroup.value;
      return completeName.toLowerCase().includes(value.toLowerCase());
    });
    setDatas(filtered);
  }

  useEffect(() => {
    setDatas(args.data);
    args.setCheckboxes(document.querySelectorAll("input[type=checkbox]"));
  }, [args]);

  return (
    <div className="bg-[white] p-4">
      <PageHeader>Categorização de dados</PageHeader>
      <div className="h-8 flex mb-3">
        <InputText className="border-[#C3C3C3] rounded-0 w-4/6 max-w-xs inline mr-2" onChange={filterData} />
        <div className="bg-[#205F16] rounded-0 mr-2 w-8 flex justify-center items-center">
          <Filter className="w-6 h-6 text-white" />
        </div>
        <div className="bg-[#33748B] rounded-0 w-8 flex justify-center items-center">
          <FilterCircleXmark onClick={clearAllCheckboxes} className="w-5 h-5 text-white" />
        </div>
      </div>
      <div className="flex w-full space-x-24">
        <div className="space-y-3">
          <div className="font-bold font-roboto"> Nova privacidade Bioconex </div>
          <div>
            <RadioButton
              inputId="bioconexPublico"
              name="bioconex"
              value="publico"
              onChange={(e) => setGroupValue(e.value.toString())}
              checked={args.groupValue === "publico"}
            />
            <label htmlFor="bioconexPublico" className="ml-2">Público</label>
          </div>
          <div>
            <RadioButton
              inputId="bioconexPrivado"
              name="bioconex"
              value="privado"
              onChange={(e) => setGroupValue(e.value.toString())}
              checked={args.groupValue === "privado"}
            />
            <label htmlFor="bioconexPrivado" className="ml-2">Privado</label>
          </div>
        </div>
        <div className="space-y-3">
          <div className="font-bold font-roboto"> Nova privacidade Bi da Floresta </div>
          <div>
            <RadioButton
              inputId="biFlorestaPublico"
              name="biFloresta"
              value="publico"
              onChange={(e) => setGroupValue2(e.value.toString())}
              checked={args.groupValue2 === "publico"}
            />
            <label htmlFor="biFlorestaPublico" className="ml-2">Público</label>
          </div>
          <div>
            <RadioButton
              inputId="biFlorestaPrivado"
              name="biFloresta"
              value="privado"
              onChange={(e) => setGroupValue2(e.value.toString())}
              checked={args.groupValue2 === "privado"}
            />
            <label htmlFor="biFlorestaPrivado" className="ml-2">Privado</label>
          </div>
        </div>
      </div>
      <div className="h-4/6 mt-2 overflow-auto">
        <DataTable value={datas} selectionMode={rowClick ? undefined : 'multiple'} selection={selectedData!}
          onSelectionChange={(data: any) => setSelectedRows(data.value)} dataKey="id" tableStyle={{ minWidth: '50rem' }} className="font-roboto border-[#146600]">
          <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
          <Column field="name" header="Dados (nome, grupo e subgrupo)" body={(data) => data.name + " - " + data.group.value + " - " + data.subgroup.value} />
          <Column field="bioconex" header="Bioconex" body={(data) => <p className="text-center">{data.type.private_bioconex ? "Privado" : "Público"}</p>} />
          <Column field="biFloresta" header="BI da Floresta" body={(data) => <p className="text-center">{data.type.private_BI ? "Privado" : "Público"}</p>} />
          <Column field="updated_at" header="Ultima alteração" body={(data) => <p className="text-center">{Intl.DateTimeFormat("pt-BR").format(Date.parse(data.updated_at))}</p>} />
        </DataTable>
      </div>
    </div>
  );
});

export default DataPrivacyControl;