import LogoCerti from '../../assets/logo-certi.png';
import { FooterContainer, FooterContent } from "./styles";

export const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <div className="flex justify-center">
          <img alt="" src={LogoCerti} />
        </div>
      </FooterContent>
    </FooterContainer>
  )
}
