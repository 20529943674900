import { Suspense, useState } from "react";
import { Button } from "primereact/button";
import PageHeader from "../../components/PageHeader";

import FormikSelect from "../../components/FormikSelect";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import groups from "./utils/Groups";
import Loading from "../../components/Loading";
import { Group, Subgroup } from "./utils/Groups.d";
import { BreadCrumb } from "primereact/breadcrumb";

const RenderGroupComponent = ({ groupValue, subgroupValue }: { groupValue: Group; subgroupValue: Subgroup }) => {
  if (!groupValue) return <h1>Sem filtro selecionado</h1>;
  if (subgroupValue) return <subgroupValue.component />;
  if (!groupValue.subgroups && !groupValue.component) return <h1>Grupo sem componente</h1>;
  if (!groupValue.component) return <h1>Selecione um subgrupo</h1>;
  return <groupValue.component />;
};

const Schema = Yup.object().shape({
  id_group: Yup.string().required("Grupo é obrigatório"),
});
function ParameterizationPage(this: any) {
  const [group, setGroup] = useState<Group | null>(null);
  const [subgrop, setSubgroup] = useState<any>(null);
  const [initialValues, setInitialValues] = useState<any>({
    id_group: "",
    id_subgroup: "",
  });

  const setGroupValue = (value: string) => {
    const group = groups.find(g => g.value === value) as Group;
    setGroup(group);
    setSubgroup(null);
    setInitialValues({ id_group: group.value, id_subgroup: "" });
  };

  const setSubgroupValue = (value: string) => {
    const subgroup = group?.subgroups?.find(g => g.value === value);
    setSubgroup(subgroup);
  };

  const items = [{ label: "Edição de dados Gerais", url: "/parameterization" }];
  const home = { icon: "pi pi-home", url: "/home" };

  return (
    <div className="w-full p-10">
      <BreadCrumb model={items} home={home} className="mb-4" />
      <PageHeader>Edição de dados gerais</PageHeader>
      <Formik
        initialValues={initialValues}
        validationSchema={Schema}
        onSubmit={(values, actions) => {
          setGroupValue(values.id_group);
          setSubgroupValue(values.id_subgroup);
        }}
        onReset={(values, actions) => {
          setGroup(null);
          setSubgroup(null);
          setInitialValues({ id_group: "", id_subgroup: "" });
        }}
        key={"form-search-parameterization"}
      >
        {({ values }) => (
          <Form className="flex flex-wrap">
            <div className="grid grid-cols-2  w-full relative rounded-lg p-10 border border-[#707070]">
              <div className="row-span-1 grid grid-cols-2 space-x-0">
                <FormikSelect
                  label="Grupo:"
                  name="id_group"
                  className="w-72 p-2"
                  options={groups}
                  onChange={e => setGroupValue(e)}
                />
                {group?.subgroups && (
                  <FormikSelect
                    label="Subgrupo:"
                    name="id_subgroup"
                    className="w-48 p-2"
                    defaultValue={initialValues.id_subgroup}
                    options={group?.subgroups}
                    onChange={e => setSubgroupValue(e)}
                  />
                )}
              </div>
            </div>

            <div className="w-full flex flex-wrap mt-3 justify-end  gap-x-0 sm:gap-x-8 gap-y-3 sm:gap-y-0">
              <Button disabled={!values.id_group} type="reset" severity="danger" size="small">
                LIMPAR
              </Button>

              <Button disabled={!values.id_group} type="submit" size="small" severity="success">
                PESQUISAR
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      {group && (
        <Suspense fallback={<Loading />}>
          <RenderGroupComponent groupValue={group} subgroupValue={subgrop} />
        </Suspense>
      )}
    </div>
  );
}

export default ParameterizationPage;
