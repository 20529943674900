import { Dialog } from "primereact/dialog";

function ModalDemandImportSuccess({ isOpen = false, closeModal }: { isOpen?: boolean; closeModal: Function }) {

  function closeModalSuccess() {
    console.log("closeModalSuccess")
    closeModal()
  }

  return (
    <div>
      <Dialog header="Demandas importadas" visible={isOpen} onHide={closeModalSuccess}>
        Suas demandas foram importadas com sucesso!
        <button onClick={closeModalSuccess} className="bg-green-500 hover:bg-green-700 px-4 py-2 rounded-md text-white">
          Fechar
        </button>
      </Dialog>
    </div>
  )
}

export default ModalDemandImportSuccess
