import {
	createBrowserRouter,
	createRoutesFromElements,
	Outlet,
	Route,
	RouterProvider,
} from "react-router-dom"
import { MainLayout } from "./layouts/Main"
import { FullLayout } from "./layouts/Full"
import Login from "./pages/Login"
import Model from "./pages/Models"
import Endpoint from "./pages/Endpoints"
import Users from "./pages/Users"
import HomePage from "./pages/HomePage"
import RelatorioPage from "./pages/Reports"
import Parameterization from "./pages/Parameterization"

import Demands from "./pages/Demands"
import DemandImport from "./pages/DemandImport"
import ViewDemandsPage from "./pages/Demands/ViewDemand/ViewDemand"
import DemandsRegister from "./pages/Demands/NewDemand/Register"
import DemandsEdit from "./pages/Demands/EditDemand/Edit"
import DataCategorization from "./pages/DataCategorization"
import PercentagesAndProbabilities from "./pages/PercentagesAndProbabilities"
import Warehouses from "./pages/Warehouses"
import CarrierProfileNotificationPage from "./pages/CarrierProfileNotification"
import CarrierProfileNotificationViewPage from "./pages/CarrierProfileNotification/details/details"
import ProductTrackerListPage from "./pages/CarrierTrackerDetails"
import ProductTrackerViewPage from "./pages/CarrierTrackerDetails/view/view"
import VolumesReportPage from "./pages/RegistrationVolumes/Report/Report"
import ReportGeneralIndex from "./pages/Reports/General/index"
import ReportIndividualIndex from "./pages/RegistrationVolumes/Individual"
import UsersReport from "./pages/Reports/Users"
import ProductsReport from "./pages/Reports/Products"
import ReportCostGeneralIndex from "./pages/CostsAndCharges/"
import DemandsTable from "./pages/Reports/Demands"
import FreightList from "./pages/FreightManager/FreightList"
import LogsPage from "./pages/LogsPage"
import ProductsPage from "./pages/Products"
import Carriers from "./pages/Carriers"
import SalesPage from "./pages/Reports/Sales"

const MainTemplate = () => (
	<MainLayout>
		<Outlet />
	</MainLayout>
)

const FullTemplate = () => (
	<FullLayout>
		<Outlet />
	</FullLayout>
)

const router = createBrowserRouter(
	createRoutesFromElements(
		<Route path='/'>
			<Route element={<FullTemplate />}>
				<Route path='/login' element={<Login />} />
				<Route path='/' element={<Login />}></Route>
			</Route>
			<Route element={<MainTemplate />}>
				<Route path='/demands' element={<Demands />} />
				<Route path='/reports' element={<RelatorioPage />} />
				<Route path='/demands-import' element={<DemandImport />} />
				<Route
					path="/demands/import GenerateApiToken from '../../../services/integrador/commands/GenerateApiToken';
view"
					element={<ViewDemandsPage />}
				/>
				<Route path='/demands/register' element={<DemandsRegister />} />
				<Route path='/demands/edit/:id' element={<DemandsEdit />} />
				<Route path='/users' element={<Users />} />
				<Route path='/carriers' element={<Carriers />} />
				<Route path='/models' element={<Model />} />
				<Route path='/endpoints' element={<Endpoint />} />
				<Route path='/home' element={<HomePage />} />
				<Route path='/parameterization' element={<Parameterization />} />
				<Route path='/data-categorization' element={<DataCategorization />} />
				<Route
					path='/percentages-and-probabilities'
					element={<PercentagesAndProbabilities />}
				/>
				<Route path='/warehouses' element={<Warehouses />} />
				<Route
					path='/carrier-profile-notification'
					element={<CarrierProfileNotificationPage />}
				/>
				<Route
					path='/carrier-profile-notification/view/:id'
					element={<CarrierProfileNotificationViewPage />}
				/>
				<Route
					path='/product-tracker-list'
					element={<ProductTrackerListPage />}
				/>
				<Route
					path='/product-tracker-list/view/:id'
					element={<ProductTrackerViewPage />}
				/>
				<Route path='/reports/general' element={<ReportGeneralIndex />} />
				<Route
					path='/registration-volumes/report/:id'
					element={<VolumesReportPage />}
				/>
				<Route
					path='/registration-volumes/individual/index'
					element={<ReportIndividualIndex />}
				/>
				<Route path='/reports/sales' element={<SalesPage />} />
				<Route path='/reports/users' element={<UsersReport />} />
				<Route path='/reports/products' element={<ProductsReport />} />
				<Route path='/reports/demands' element={<DemandsTable />} />
				<Route path='/cost-report' element={<ReportCostGeneralIndex />} />
				<Route path='/freights' element={<FreightList />} />
				<Route path='/logs' element={<LogsPage />} />
				<Route path='/products' element={<ProductsPage />} />
			</Route>
		</Route>,
	),
)

function Routes() {
	return <RouterProvider router={router} />
}

export default Routes
