export const weightUnitOptions = [
  { id: 1, value: "kg", label: "Quilo(s)" },
  { id: 2, value: "g", label: "Grama(s)" },
];

export const months = [
  { id: 1, value: "janeiro", label: "Janeiro" },
  { id: 2, value: "fevereiro", label: "Fevereiro" },
  { id: 3, value: "março", label: "Março" },
  { id: 4, value: "abril", label: "Abril" },
  { id: 5, value: "maio", label: "Maio" },
  { id: 6, value: "junho", label: "Junho" },
  { id: 7, value: "julho", label: "Julho" },
  { id: 8, value: "agosto", label: "Agosto" },
  { id: 9, value: "setembro", label: "Setembro" },
  { id: 10, value: "outubro", label: "Outubro" },
  { id: 11, value: "novembro", label: "Novembro" },
  { id: 12, value: "dezembro", label: "Dezembro" },
];
