import { Form, Formik, FormikProps } from "formik";
import { useEffect, useRef, useState } from "react";
import FormikInput from "../../../components/FormikInput";
import FormikInputMask from "../../../components/FormikInputMask";
import Loading from "../../../components/Loading";
import FormikSelect from "../../../components/FormikSelect";
import { fetchStates } from "../../../api/states";
import { fetchCities } from "../../../api/cities";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { certifications, equipaments, load_types, operations, structures } from "../WarehouseOptions.ts";
import FormikTextArea from "../../../components/FormikInputTextArea.tsx";
import http from "../../../plugins/http.ts";
import Schema from "../Validations/validations.ts";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import CheckboxList from "../Components/CheckboxList.tsx";
import { Message } from "primereact/message";

function ModalRegisterWarehouse({ created, args }: { created?: any; args?: any }) {
  const [modal, setModal] = useState(false);
  const [error, setError] = useState("");
  const [states, setStates] = useState<any[]>([]);
  const [cities, setCities] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState(["", "", "", ""]);
  const [certificationsOp, setCertifications] = useState<any[]>(certifications);
  const [equipamentsOp, setEquipaments] = useState<any[]>(equipaments);
  const [load_typesOp, setLoadTypes] = useState<any[]>(load_types);
  const [operationsOp, setOperations] = useState<any[]>(operations);
  const [structuresOp, setStructures] = useState<any[]>(structures);
  const formikRef = useRef<FormikProps<typeof initialValues>>(null);

  const initialValues = {
    //dados cadastrais
    fantasy_name: "",
    social_reason: "",
    cnpj: "",
    id_state: "",
    id_city: "",
    cep: "",
    address: "",
    neighborhood: "",
    number: "",
    complement: "",
    //dados de contato
    responsible: "",
    role: "",
    contact_1: "",
    contact_2: "",
    email: "",
    //observação
    obs: "",
    //datas
    images: "",
    measure: "",
    //json de data e contacts
    contacts: "",
    datas: "",
  };

  const loadAddressInfo = async (e: any) => {
    let cep = e.target.value;
    cep = cep.replace(/\D/g, "");
    if (cep === "") {
      formikRef.current?.setFieldValue("address", "");
      formikRef.current?.setFieldValue("neighborhood", "");
      formikRef.current?.setFieldValue("id_state", "");
      formikRef.current?.setFieldValue("id_city", "");
      return;
    }
    if (cep !== "") {
      const validacep = /^[0-9]{8}$/;
      if (validacep.test(cep)) {
        try {
          setLoading(true);
          const ans = await http.get(`v1/public/cep/${cep}`);
          if (ans.data && !ans.data.message) {
            formikRef.current?.setFieldValue("address", ans.data.address);
            formikRef.current?.setFieldValue("neighborhood", ans.data.neighborhood);
            formikRef.current?.setFieldValue("id_state", ans.data.id_state);
            stateSelected(ans.data.id_state);
            formikRef.current?.setFieldValue("id_city", ans.data.id_city);
          }
          setLoading(false);
        } catch (error) {
          console.error("Error fetching address:", error);
        }
      }
    }
  };
  const onImageSelected = (event: any, index: any) => {
    const file = event.target.files[0];
    if (file.size > 2 * 1024 * 1024) {
      alert("O arquivo é muito grande! Por favor, selecione um arquivo menor que 2MB.");
      event.target.value = "";
      return;
    }
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const base64 = reader.result;
        const updatedImages: any = [...images];
        updatedImages[index] = base64;
        setImages(updatedImages);
      };
      reader.readAsDataURL(file);
    }
  };

  const removeImage = (index: any) => {
    const updatedImages = [...images];
    updatedImages[index] = "";
    setImages(updatedImages);
  };

  const toggleItem = (id: number, items: any[], setItems: React.Dispatch<React.SetStateAction<any[]>>) => {
    const newItems = items.map(item => (item.id === id ? { ...item, checked: !item.checked } : item));
    setItems(newItems);
  };

  const statesOp = () => {
    return states?.map(state => ({ label: state?.name, value: state?.id }));
  };

  function showError(message: string) {
    setError(message);
    setTimeout(() => {
      setError("");
    }, 3000);
  }

  async function stateSelected(id: number) {
    setLoading(true);
    fetchCities({ id_state: id }).then(res => {
      setCities(res.data);
      setLoading(false);
    });
  }

  const isAllUnchecked = (array: any) => array.every((item: any) => item.checked === false);

  async function register() {
    try {
      if (images.some((image: any) => image === "")) {
        showError("Todas as imagens devem ser preenchidas");
        return;
      }
      if (isAllUnchecked(load_typesOp)) {
        showError("Selecione pelo menos um tipo de carga");
        return;
      }
      if (isAllUnchecked(structuresOp)) {
        showError("Selecione pelo menos uma característica da estrutura");
        return;
      }
      if (isAllUnchecked(equipamentsOp)) {
        showError("Selecione pelo menos um tipo de equipamento");
        return;
      }
      if (isAllUnchecked(operationsOp)) {
        showError("Selecione pelo menos um tipo de operação");
        return;
      }
      if (isAllUnchecked(certificationsOp)) {
        showError("Selecione pelo menos uma certificação");
        return;
      }

      if (images.some((image: any) => image === "")) {
        showError("Todas as imagens devem ser preenchidas");
        return;
      }
      if (!formikRef.current?.isValid) {
        showError("Preencha todos os campos obrigatórios");
        return;
      }

      const values = formikRef.current?.values;

      const contacts = {
        responsible: values.responsible,
        role: values.role,
        contact_1: values.contact_1,
        contact_2: values.contact_2,
        email: values.email,
      };

      const datas = {
        measures: values.measure,
        load_types: load_typesOp,
        structures: structuresOp,
        equipaments: equipamentsOp,
        operations: operationsOp,
        certifications: certificationsOp,
      };

      let valores: any = { ...values, images, contacts: contacts, datas: datas };
      delete valores?.measure;
      delete valores?.responsible;
      delete valores?.role;
      delete valores?.contact_1;
      delete valores?.contact_2;
      delete valores?.email;
      const response = await http.post("/v1/warehouses", valores);
      if (response.status === 200) {
        toggle();
        if (created) created();
      } else if (response.data.hasOwnProperty("message")) {
        showError(response.data.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const toggle = () => {
    setModal(!modal);
    setImages(["", "", "", ""]);
    formikRef.current?.resetForm();
    setCertifications(certifications);
    setEquipaments(equipaments);
    setLoadTypes(load_types);
    setOperations(operations);
    setStructures(structures);
  };

  const onInputNumber = (e: any) => {
    let key = e.nativeEvent.data;
    if (key === "." || key === "e" || key === "," || key === "-" || key === "+") {
      let value = document.getElementsByName("number")[0].getAttribute("value");
      (document.getElementsByName("number")[0] as HTMLInputElement).value = value ?? '';
    }
  }

  useEffect(() => {
    setLoading(true);
    fetchStates().then(res => {
      setStates(res.data);
      setLoading(false);
    });
  }, []);

  return (
    <div className="inline-flex">
      <Button
        onClick={toggle}
        severity="success"
        label="Novo cadastro"
        size="small"
      >
      </Button>
      <Dialog maximizable header="Cadastrar Novo Armazém" style={{ width: '70vw' }} visible={modal} onHide={toggle} centered {...args}>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={Schema}
          onSubmit={(values, actions) => {
            register();
          }}
          key={"form-register-warehouse"}
        >
          <>
            <Form className="flex flex-wrap p-10 overflow-y-auto">
              <div className="w-1/2 pr-2 space-y-8">
                <div className="dados_cadastrais space-y-4">
                  <label htmlFor="" className="text-lg text-[#146600]">
                    Dados Cadastrais
                  </label>
                  <FormikInput
                    className="w-full"
                    name="social_reason"
                    label="Razão Social"
                    placeholder="Digite a razão social"
                  />
                  <FormikInput
                    className="w-full"
                    name="fantasy_name"
                    label="Nome Fantasia"
                    placeholder="Digite o nome fantasia"
                  />
                  <FormikInputMask
                    mask="99.999.999/9999-99"
                    className="w-full"
                    name="cnpj"
                    label="CNPJ"
                    placeholder="Digite o CNPJ (apenas números)"
                  />
                  <div className="flex space-x-4">
                    <FormikSelect
                      label="Estado"
                      name="id_state"
                      className="w-1/2"
                      options={statesOp()}
                      onChange={e => stateSelected(parseInt(e))}
                    />
                    <FormikSelect
                      label="Cidade"
                      name="id_city"
                      className="w-1/2"
                      options={cities.length > 0 ? cities?.map(city => ({ label: city.name, value: city.id })) : []}
                    />
                    <FormikInputMask
                      mask="99999-999"
                      label="CEP"
                      name="cep"
                      className="w-1/2"
                      placeholder="Digite o CEP"
                      onChange={loadAddressInfo}
                    />
                  </div>
                  <div className="flex space-x-4">
                    <FormikInput className="w-full" name="address" label="Endereço" placeholder="Digite o endereço" />
                    <FormikInput
                      className="w-full"
                      name="neighborhood"
                      label="Bairro"
                      placeholder="Digite o bairro"
                    />
                  </div>
                  <div className="flex space-x-4">
                    <FormikInput
                      className="w-1/3"
                      onInput={e => onInputNumber(e)}
                      type="number"
                      name="number"
                      label="Número"
                      placeholder="Digite o número"
                    />
                    <FormikInput
                      className="w-2/3"
                      name="complement"
                      label="Complemento"
                      placeholder="Digite o complemento"
                    />
                  </div>
                </div>
                <div className="contact space-y-4">
                  <label htmlFor="" className="w-full text-lg text-[#146600]">
                    Dados de Contato
                  </label>
                  <div className="flex space-x-4">
                    <FormikInput
                      className="w-1/2"
                      name="responsible"
                      label="Responsável"
                      placeholder="Digite o nome do responsável"
                    />
                    <FormikInput className="w-1/2" name="role" label="Cargo" placeholder="Digite o cargo" />
                  </div>
                  <div className="flex space-x-9">
                    <FormikInputMask
                      mask="(99) 99999-9999"
                      className="w-1/2"
                      name="contact_1"
                      label="Contato 1"
                      placeholder="Digite o contato"
                    />
                    <FormikInputMask
                      mask="(99) 99999-9999"
                      className="w-1/2"
                      name="contact_2"
                      label="Contato 2"
                      placeholder="Digite o contato"
                    />
                  </div>
                  <div className="flex">
                    <FormikInput className="w-full" name="email" label="E-mail" placeholder="Digite o e-mail" />
                  </div>
                </div>
                <div className="flex w-full items-center space-y-8">
                  <div className="flex-grow h-px bg-gray-300"></div>
                </div>
                <div className="tipo_carga space-y-4">
                  <div className="space-y-4">
                    <label htmlFor="" className="w-full text-lg text-[#146600]">
                      Tipo de Carga
                    </label>
                    <div className="ml-2">
                      <CheckboxList
                        items={load_typesOp}
                        onToggle={id => toggleItem(id, load_typesOp, setLoadTypes)}
                      />
                    </div>
                    <FormikTextArea label="Observações ou Informações adicionais" name="obs" rows={7} />
                  </div>
                </div>
              </div>
              <div className="w-1/2 pl-10 space-y-4">
                <div className="photos space-y-2">
                  <label htmlFor="" className="text-lg text-[#146600]">
                    Fotos(mín. 04 fotos com 2mb cada)
                  </label>
                  <div className="grid grid-cols-2 lg:grid-cols-4 gap-2 relative border border-gray-400 m-4 rounded-md pt-2.5 pb-5 px-1.5 mx-0.5 ">
                    {images.map((image: any, index: any) => (
                      <div key={index} className="text-center">
                        <div
                          className={`relative border border-gray-400 rounded-md ${index === 0 ? "h-[102px]" : "h-[78px]"
                            }`}
                        >
                          <FontAwesomeIcon
                            icon={faImage}
                            className="text-green-600 text-2xl opacity-60 absolute top-4 left-1/2 transform -translate-x-1/2 pointer-events-none z-10"
                          />
                          <input
                            className="w-full h-full opacity-0 cursor-pointer"
                            type="file"
                            name={`image${index + 1}`}
                            accept="image/png, image/jpeg"
                            onChange={e => onImageSelected(e, index)}
                          />
                          <img
                            className={`w-full h-full object-cover absolute top-0 left-0 pointer-events-none z-20 ${image ? "block" : "hidden"
                              }`}
                            src={image || ""}
                            alt="preview"
                          />
                        </div>
                        <button
                          onClick={() => removeImage(index)}
                          type="button"
                          className={`text-red-500 mt-1 ${image ? "block" : "hidden"}`}
                        >
                          Remover
                        </button>
                      </div>
                    ))}
                  </div>
                  <FormikInput
                    className="w-full"
                    name="measure"
                    label="Medidas do armazém em m²"
                    placeholder="Insira as medidas"
                    type="text"
                  />
                </div>
                <div className="space-y-2">
                  <label htmlFor="structures" className="text-[#848484]">
                    Estrutura
                  </label>
                  <div>
                    <CheckboxList items={structuresOp} onToggle={id => toggleItem(id, structuresOp, setStructures)} />
                  </div>
                </div>
                <div className="space-y-2">
                  <label htmlFor="equipament" className="text-[#848484]">
                    Equipamento
                  </label>
                  <div>
                    <CheckboxList
                      items={equipamentsOp}
                      onToggle={id => toggleItem(id, equipamentsOp, setEquipaments)}
                    />
                  </div>
                </div>
                <div className="space-y-4">
                  <label htmlFor="equipament" className="text-[#848484]">
                    Operação
                  </label>
                  <div>
                    <CheckboxList items={operationsOp} onToggle={id => toggleItem(id, operationsOp, setOperations)} />
                  </div>
                </div>
                <div className="flex w-full items-center space-y-4">
                  <div className="flex-grow h-px bg-gray-300"></div>
                </div>
                <div className="certificacoes space-y-4">
                  <div className="space-y-4">
                    <label htmlFor="" className="w-full text-lg text-[#146600]">
                      Certificações
                    </label>
                    <div className="ml-2">
                      <CheckboxList
                        items={certificationsOp}
                        onToggle={id => toggleItem(id, certificationsOp, setCertifications)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Form>
            <div className="w-full flex justify-end p-4 gap-x-4">
              <Button
                onClick={toggle}
                disabled={loading}
                severity="danger"
                outlined
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                severity="success"
                onClick={register}
                disabled={loading}

              >
                {loading && <Loading />}
                Salvar
              </Button>
            </div>
            {error !== "" && (
              <Message className="w-full mt-4" color="danger">
                {error}
              </Message>
            )}
          </>
        </Formik>
      </Dialog>
    </div>
  );
}

export default ModalRegisterWarehouse;
