import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Messages } from 'primereact/messages';
import Loading from "../../../components/Loading";
import http from "../../../plugins/http";

function ModalReportIndividual({
  filterSelectedSellers,
  hasSelected,
  datas,
  args,
  onOpen,
  cleanSelections,
  chains,
}: {
  filterSelectedSellers: any;
  hasSelected: any;
  datas: any;
  args?: any;
  onOpen: any;
  cleanSelections: any;
  chains: any;
}) {
  const formik = useFormik({
    initialValues: {
      data_inicial: null,
      data_final: null,
      id_chain: null,
    },
    onSubmit: values => { },
  });

  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [selectedChain, setSelectedChain] = useState<any>(null);
  const [fetchedChains, setFetchedChains] = useState<any[]>([]);
  const [initialValues, setInitialValues] = useState<any>({
    data_inicial: null,
    data_final: null,
    id_chain: null,
  });
  const toggle = () => {
    setModal(prevModal => !prevModal);
    if (!modal) {
      formik.resetForm();
    }
  };

  const showReport = async (values: any) => {
    try {
      setLoading(true);
      const dataInicial = values.data_inicial ? values.data_inicial.toISOString().split('T')[0] : '';
      const dataFinal = values.data_final ? values.data_final.toISOString().split('T')[0] : '';

      const response = await http.get(
        `v1/managers/manager-report?start=${dataInicial}&end=${dataFinal}&users=${datas.id}`,
        {
          responseType: "blob",
        },
      );
      if (response.status === 403) {
        setError("Você não tem permissão para visualizar este relatório");
      }
      if (response.status === 200) {
        const href = URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "report.pdf");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (chains) {
      setFetchedChains(chains);
    }
  }, [chains]);

  return (
    <>
      <Button
        label="Gerar"
        icon="pi pi-file-pdf"
        className="p-button-success"
        onClick={toggle}
      />
      <Dialog
        header="Relatório de Vendas Individual:"
        visible={modal}
        onHide={toggle}
        style={{ width: '50vw' }}
        {...args}
      >
        <div className="flex flex-wrap space-y-1">
          <div className="flex flex-col md:flex-row items-center w-full justify-between p-4 space-y-2 md:space-y-0 md:space-x-1 mr-4">
            <div className="flex-initial md:flex-grow mb-2 md:mb-0">
              <label htmlFor="data_inicial" className="block text-sm font-medium text-gray-700">Data Inicial</label>
              <Calendar id="data_inicial" value={formik.values.data_inicial} onChange={(e) => formik.setFieldValue('data_inicial', e.value)} showIcon dateFormat="dd/mm/yy" />
            </div>
            <div className="flex-initial md:flex-grow mb-2 md:mb-0">
              <label htmlFor="data_final" className="block text-sm font-medium text-gray-700">Data Final</label>
              <Calendar id="data_final" value={formik.values.data_final} onChange={(e) => formik.setFieldValue('data_final', e.value)} showIcon dateFormat="dd/mm/yy" />
            </div>
            <div className="flex-initial md:flex-grow mb-2 md:mb-0">
              <label htmlFor="id_chain" className="block text-sm font-medium text-gray-700">Cadeia</label>
              <Dropdown
                id="id_chain"
                value={selectedChain}
                onChange={(e) => {
                  setSelectedChain(e.value);
                  formik.setFieldValue('id_chain', e.value ? e.value.id : null); // Ajustando para o formato do PrimeReact
                }}
                options={fetchedChains.map((chain: any) => ({
                  label: chain.name,
                  value: chain
                }))}
                placeholder="Selecione"
                optionLabel="name"
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row items-center justify-end gap-4 w-full p-6 mr-4">
            <Button label="Limpar" className="p-button-outlined" onClick={() => formik.resetForm} />
            <Button label="Cancelar" className="p-button-outlined" onClick={toggle} />
            <Button
              label={'Visualizar'}
              icon="pi pi-file-pdf"
              className="p-button-success"
              onClick={() => showReport(formik.values)}
              loading={loading}
              disabled={loading || !formik.values.data_inicial || !formik.values.data_final}
            />
          </div>
        </div>
        <Messages ref={(el) => el && el.show({ severity: 'error', summary: 'Erro', detail: error, sticky: true })} />
      </Dialog>
    </>
  );
}

export default ModalReportIndividual;
