import { useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import http from "../../../plugins/http";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
function ModalUserJustifications({ product, className = "" }: { product: any; className?: string }) {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [justifications, setJustifications] = useState<any[]>([]);

  const toggle = () => {
    if (!modal) {
      fetchJustifications();
    }
    setModal(prevModal => !prevModal);
  };

  async function fetchJustifications() {
    try {
      setLoading(true);
      const response = await http.get(`v1/products-justifications/products/${product.id}`);
      if (response.status !== 200) {
        return;
      }
      setJustifications(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const captalize = (text?: string) => {
    if (!text) {
      return "";
    }
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  return (
    <div className="inline-flex">
      <i className="pi pi-comments cursor-pointer ml-2" style={{ fontSize: "20px" }} onClick={toggle} />
      <Dialog header="Justificativas" visible={modal} onHide={toggle} className={className}>
        <div className="w-full flex flex-col gap-y-4 p-4">
          {justifications && (
            <DataTable value={justifications} className="w-full">
              <Column field="id" header="ID" />
              <Column field="justification" header="Justificativa" />
              <Column field="action" body={(rowData: any) => captalize(rowData.action)}
                header="Ação" />
              <Column
                body={(rowData: any) => {
                  return <span>{rowData?.user?.name} ({rowData?.user?.email})</span>
                }}
                header="Usuário"
              />
              <Column
                body={(rowData: any) => {
                  return (
                    new Date(rowData.created_at).toLocaleDateString() +
                    " " +
                    new Date(rowData.created_at).toLocaleTimeString()
                  );
                }}
                header="Data"
              />
            </DataTable>
          )}
        </div>
        <div className="w-full flex justify-end p-4 gap-x-4">
          <Button severity="success" onClick={toggle}>
            Fechar
          </Button>
        </div>
      </Dialog>
    </div>
  );
}

export default ModalUserJustifications;
