import React, { useState } from "react"

const ALERT_TIME = 5000
const initialState = {
  text: "",
  type: "",
}

export const AlertContext = React.createContext({
  ...initialState,
  setAlert: (text: string, type: string) => {},
})

export default function AlertProvider({ children }: { children: any }) {
  const [text, setText] = useState("")
  const [type, setType] = useState("")

  const setAlert = (text: string, type: string) => {
    setText(text)
    setType(type)

    setTimeout(() => {
      setText("")
      setType("")
    }, ALERT_TIME)
  }

  const contextValue = {
    text,
    type,
    setAlert,
  }

  return <AlertContext.Provider value={contextValue}>{children}</AlertContext.Provider>
}
