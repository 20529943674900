import { Content, HeaderContainer } from "./styles";
import { BtnLogout } from "./styles";

export const Header = ({ showExitBtn }: { showExitBtn?: boolean }) => {
  function logoutSystem() {
    localStorage.clear();
    window.location.href = "/login";
  }

  return (
    <HeaderContainer>
      <Content>
        <div className="flex justify-end">
          {showExitBtn && (
            <BtnLogout className="inline" onClick={() => logoutSystem()}>
              Sair
            </BtnLogout>
          )}
        </div>
      </Content>
    </HeaderContainer>
  );
};
