import { Dialog } from "primereact/dialog";

function ModalError({ isOpen = false, message, closeModal }: { isOpen?: boolean; closeModal: Function; message: string }) {
  function closeModalError() {
    closeModal();
  }

  return (
    <div>
      <Dialog visible={isOpen} onHide={closeModalError} header="Aviso!">
        {message}
        <button onClick={closeModalError} className="bg-green-500 hover:bg-green-700 px-4 py-2 rounded-md text-white">
          Fechar
        </button>
      </Dialog>
    </div>
  );
}

export default ModalError;
