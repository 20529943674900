import { Form, Formik, useFormikContext } from "formik";
import { useState } from "react";
import FormikInput from "../../../components/FormikInput";
import FormikSelect from "../../../components/FormikSelect";
import { fetchCities } from "../../../api/cities";
import http from "../../../plugins/http";
import Loading from "../../../components/Loading";
import FormikInputMask from "../../../components/FormikInputMask";
import Schema from "../Schemas/Schema";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Message } from "primereact/message";
import { Edit } from "@styled-icons/boxicons-regular";

function ModalEditCarrier({
  carrier,
  onEdit,
  args,
  states,
  banks,
}: {
  carrier?: any;
  onEdit: () => void;
  args?: any;
  states: any[];
  banks: any[];
}) {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [cities, setCities] = useState<any[]>([]);
  const [schema, setSchema] = useState<any>(Schema);
  const [type, setType] = useState(null);
  const types = [
    { label: "Dados Bancários", value: "bank" },
    { label: "Token(Mercado Pago ou Pagar.me)", value: "token" },
  ];

  function FormButtons() {
    const { submitForm } = useFormikContext();
    return (
      <div className="w-full flex justify-end p-4 gap-x-4">
        <Button onClick={toggle} disabled={loading} severity="danger">
          Cancelar
        </Button>
        <Button type="submit" onClick={submitForm} disabled={loading} severity="success">
          {loading && <Loading />}
          Editar
        </Button>
      </div>
    );
  }

  const toggle = () => {
    setModal(!modal);
    if (!modal && carrier.id_state) {
      fetchCities({ id_state: carrier.id_state }).then(res => {
        setCities(res.data);
      });
    }
  };

  const replaceNulls = (obj: any) => {
    const newObj = { ...obj };
    for (const key in newObj) {
      if (newObj[key] === null) {
        newObj[key] = "";
      }
    }
    return newObj;
  };

  const initialValues = {
    ...replaceNulls(carrier),
    bank_name: Number(carrier.bank_name),
  };

  async function stateSelected(id: number) {
    setLoading(true);
    initialValues.id_city = "";
    fetchCities({ id_state: id }).then(res => {
      setCities(res.data);
      setLoading(false);
    });
  }

  function showError(message: string) {
    setError(message);
    setTimeout(() => {
      setError("");
    }, 3000);
  }

  const handleTypeChange = (type: any) => {
    setType(type);
  };

  async function edit(values: any) {
    try {
      setLoading(true);
      const response = await http.put(`/v1/carriers/${carrier.id}`, {
        ...values,
        bank_name: values.bank_name.toString(),
      });
      if (response.status === 200) {
        toggle();
        if (onEdit) onEdit();
      } else if (response.data.hasOwnProperty("error")) {
        showError(response.data.error.portuguese);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="inline-flex">
      <Edit onClick={toggle} size={25} color="#1F5974" className="cursor-pointer" />

      <Dialog header="Edição de transportadora" maximizable onHide={toggle} visible={modal} {...args}>
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={(values, actions) => {
            edit(values);
          }}
          key={"form-edit-carriers"}
        >
          <>
            <Form
              className="flex flex-wrap space-y-6 p-10"

            >
              <div className="flex w-full space-x-10">
                <FormikInput
                  className="w-1/2"
                  name="name_company"
                  label="Nome/Razão Social"
                  placeholder="Digite o nome da impresa"
                />
                <FormikInputMask
                  mask="99.999.999/9999-99"
                  className="w-1/2"
                  name="cnpj_company"
                  label="CNPJ"
                  placeholder="Digite o CNPJ"
                />
              </div>
              <div className="flex w-full space-x-10">
                <FormikInput
                  className="w-1/2"
                  name="inscricao_estadual"
                  label="Inscrição Estadual"
                  placeholder="Digite a inscrição estadual"
                  keyfilter="int"
                />
                <FormikInput className="w-1/2" name="email" label="Email" placeholder="Digite o email" />
              </div>

              <div className="flex w-full space-x-10">
                <FormikInputMask
                  className="w-1/2"
                  name="phone"
                  mask="(99) 99999-9999"
                  label="Telefone"
                  placeholder="Digite o número de telefone"
                />
                <FormikInputMask
                  className="w-1/2"
                  name="mobile_phone"
                  mask="(99) 99999-9999"
                  label="Celular"
                  placeholder="Digite o número de celular"
                />
              </div>
              <div className="flex w-full space-x-10">
                {states && (
                  <FormikSelect
                    label="Estado"
                    name="id_state"
                    className="w-1/2"
                    defaultValue={carrier.id_state}
                    options={states?.map((state: any) => ({ label: state?.name, value: state?.id }))}
                    onChange={e => stateSelected(parseInt(e))}
                  />
                )}
                {cities && (
                  <FormikSelect
                    label="Cidade"
                    name="id_city"
                    defaultValue={carrier.id_city}
                    className="w-1/2"
                    options={cities.length > 0 ? cities?.map(city => ({ label: city.name, value: city.id })) : []}
                  />
                )}
              </div>
              <div className="flex w-full space-x-10">
                <FormikInput
                  className="w-full"
                  name="spreadsheet_url"
                  label="Planilha"
                  placeholder="Ex: https://docs.google.com/spreadsheets/d/..."
                />
              </div>
              <div className="flex-grow h-px w-px bg-[#85d46c]"></div>
              <div className="flex w-full space-x-10">
                <h3>Dados Financeiros</h3>
              </div>
              <div className="flex w-full space-x-10">
                <FormikInput
                  className="w-1/2"
                  name="nfe_percentage"
                  label="Porcentagem sobre a NFE"
                  placeholder="Insira a porcentagem sobre a NFE"
                />
                <FormikInput
                  className="w-1/2"
                  name="max_weight"
                  label="Peso máximo(kg)(Opcional)"
                  placeholder="Insira o peso máximo"
                />
              </div>
              <div className="flex w-full space-x-10">
                <FormikSelect
                  label="Tipo"
                  name="id_finance_type"
                  defaultValue={carrier.id_finance_type}
                  className="w-1/2"
                  placeholder="Selecione o tipo"
                  options={types}
                  onChange={handleTypeChange}
                />
                <div className="w-1/2"></div>
              </div>
              {type === "token" && (
                <>
                  <div className="flex w-full space-x-10">
                    <FormikInput className="w-full" name="token" label="Código" placeholder="Digite o código" />
                  </div>
                </>
              )}
              {type === "bank" && (
                <>
                  <div className="flex w-full space-x-10">
                    <FormikSelect
                      className="w-1/2"
                      name="bank_name"
                      defaultValue={carrier.bank_name}
                      label="Banco"
                      placeholder="Selecione o banco"
                      options={banks}
                    />
                    <div className="w-1/2"></div>
                  </div>
                  <div className="flex w-full space-x-10">
                    <FormikInput className="w-1/2" name="bank_agency" label="Agência" placeholder="Digite a agência" />
                    <FormikInput className="w-1/2" name="bank_account" label="Conta" placeholder="Digite a conta" />
                  </div>
                </>
              )}
            </Form>
            <FormButtons />
            {error !== "" && <Message text={error} className="w-full mt-4" severity="error" />}
          </>
        </Formik>
      </Dialog>
    </div>
  );
}

export default ModalEditCarrier;
