import { useEffect, useRef, useState } from "react";
import FormikSelect from "../../../components/FormikSelect";
import CardIndividualFilter from "../Components/CardIndividualFilter";
import { years, months } from "../GeneralReportOptions";
import { Form } from "react-router-dom";
import { Formik, FormikProps, useFormikContext } from "formik";
import { fetchStates } from "../../../api/states";
import { fetchCities } from "../../../api/cities";
import { Button } from "primereact/button";
import RegistrationVolumesTable from "../Components/RegistrationVolumesTable";
import SearchBar from "../../../components/SearchBar";
export default function ReportIndividualIndex() {
  const [states, setStates] = useState<any[]>([]);
  const [cities, setCities] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [initialMonth, setInitialMonth] = useState("");
  const [finalMonth, setFinalMonth] = useState("");
  const [initialYear, setInitialYear] = useState("");
  const [finalYear, setFinalYear] = useState("");
  const [id_state, setIdState] = useState("");
  const [id_city, setIdCity] = useState("");
  const [checkBoxElements, setCheckBoxElements] = useState<any[]>([]);
  const [initialValues, setInitialValues] = useState<any>({
    initialMonth: "",
    finalMonth: "",
    initialYear: "",
    finalYear: "",
    id_state: "",
    id_city: "",
  });
  const formikRef = useRef<FormikProps<typeof initialValues>>(null);

  const submit = (values: any) => {
    console.log(values);
  };
  async function stateSelected(id: number) {
    setLoading(true);
    initialValues.id_city = "";
    fetchCities({ id_state: id }).then(res => {
      setCities(res.data);
      setLoading(false);
    });
  }
  const handleCheckBoxes = () => {
    // retorna true se tem pelo menos um checkbox selecionado
    checkBoxElements.forEach((checkbox: any) => {
      if (checkbox.checked) {
        return true;
      }
    });
    return false;
  };

  const search = (val: any) => {
    console.log(val);
  };

  function FormButtons() {
    const { submitForm, resetForm } = useFormikContext();
    const handleReset = () => {
      resetForm();
    };

    return (
      <>
        <div className="w-full flex justify-end pr-10 gap-x-8">
          <Button
            className="uppercase text-[15px] font-nunito h-[38px] font-bold text-[#146600] hover:text-white  bg-white hover:!bg-[#85D46C] border-1 hover:border-0"
            onClick={() => {
              handleReset();
            }}
          >
            Limpar
          </Button>
          <Button
            type="submit"
            color="success"
            className="uppercase text-[15px] font-nunito h-[38px] font-bold  hover:bg-[#479632] bg-green-500 border-0 hover:border-0  flex flex-wrap gap-x-4"
            onClick={submitForm}
          >
            Gerar
          </Button>
        </div>
        <div className="w-full p-10">
          <div style={{ borderBottom: "2px solid #205F16", padding: "10px" }}></div>
        </div>
      </>
    );
  }

  useEffect(() => {
    setLoading(true);
    fetchStates().then(res => {
      setStates(res.data);
      setLoading(false);
    });
  }, []);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      onSubmit={(values, actions) => {
        submit(values);
      }}
      onReset={(values, { resetForm }) => {
        setInitialValues({
          initalMonth: "",
          finalMonth: "",
          initialYear: "",
          finalYear: "",
          id_state: "",
          id_city: "",
        });
      }}
      key={"form-registration-report-filters"}
    >
      {({ values, resetForm }) => (
        <>
          <Form className="w-full p-10">
            <h1 className="font-bold text-2xl text-[#333333] pt-[50px] pb-[35px] font-roboto">
              Painel de Relatório Geral:
            </h1>
            <SearchBar classname="" onSearch={val => search(val)} />
            <h1 className="font-bold text-2xl text-[#333333] pt-[25px] pb-[35px] font-roboto">Filtrar por:</h1>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 items-strech">
              <CardIndividualFilter label="Mês">
                <div className="flex gap-x-4">
                  <FormikSelect
                    label="De:"
                    name="initialMonth"
                    className="w-1/2"
                    options={months}
                    placeholder="Escolha"
                    type="select"
                    onChange={e => setInitialMonth(e)}
                  />
                  <FormikSelect
                    label="Até:"
                    name="finalMonth"
                    className="w-1/2"
                    options={months}
                    placeholder="Escolha"
                    type="select"
                    onChange={e => setFinalMonth(e)}
                  />
                </div>
              </CardIndividualFilter>
              <CardIndividualFilter label="Ano">
                <div className="flex gap-x-4">
                  <FormikSelect
                    label="De:"
                    name="initialYear"
                    className="w-1/2"
                    options={years}
                    placeholder="Escolha"
                    type="select"
                    onChange={e => setInitialYear(e)}
                  />
                  <FormikSelect
                    label="Até:"
                    name="finalYear"
                    className="w-1/2"
                    options={years}
                    placeholder="Escolha"
                    type="select"
                    onChange={e => setFinalYear(e)}
                  />
                </div>
              </CardIndividualFilter>
              <CardIndividualFilter label="Localização:">
                <div className="flex gap-x-4">
                  <FormikSelect
                    label="Estado"
                    name="id_state"
                    className="w-1/2"
                    options={states?.map(state => ({ label: state?.name, value: state?.id }))}
                    onChange={e => stateSelected(parseInt(e))}
                  />
                  <FormikSelect
                    label="Cidade"
                    name="id_city"
                    className="w-1/2"
                    options={cities.length > 0 ? cities?.map(city => ({ label: city.name, value: city.id })) : []}
                  />
                </div>
              </CardIndividualFilter>
            </div>
          </Form>
          <FormButtons />
          <div className="w-full flex justify-end pr-10 gap-x-4">
            <Button
              type="submit"
              color="success"
              className="uppercase text-[15px] font-nunito h-[38px] font-bold  hover:bg-[#479632] bg-[#146600] border-0 hover:border-0  flex flex-wrap gap-x-4"
            >
              CSV
            </Button>
            <Button
              type="submit"
              color="success"
              className="uppercase text-[15px] font-nunito h-[38px] font-bold  hover:bg-[#479632] bg-[#146600] border-0 hover:border-0  flex flex-wrap gap-x-4"
            >
              PDF
            </Button>
            <Button
              type="submit"
              color="success"
              className="uppercase text-[15px] font-nunito h-[38px] font-bold  hover:bg-[#479632] bg-[#146600] border-0 hover:border-0  flex flex-wrap gap-x-4"
            >
              IMPRIMIR
            </Button>
          </div>
          <div className="p-10">
            <RegistrationVolumesTable
              checkBoxes={checkBoxElements}
              setCheckBoxes={setCheckBoxElements}
              handleCheckBoxes={handleCheckBoxes}
            />
          </div>
        </>
      )}
    </Formik>
  );
}
