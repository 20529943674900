import React from "react"

const Loading: React.FC<any> = ({ color = "#FFF", size = 'w-6' }: { color?: string, size?: string }) => {
  return (
    <svg
      className={`animate-spin ${size}`}
      version="1.1"
      id="L3"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      xmlSpace="preserve"
    >
      <circle fill="none" stroke={color} strokeWidth="4" cx="50" cy="50" r="44" className="opacity-50" />
      <circle fill={color} stroke={color} strokeWidth="3" cx="8" cy="54" r="6"></circle>
    </svg>
  )
}

export default Loading
