import { useEffect, useRef, useState } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FileUpload } from 'primereact/fileupload';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import PageHeader from "../../components/PageHeader";
import http from "../../plugins/http";
import Loading from "../../components/Loading";
import { dateBR, dateDiff } from "../../utils/data";
import iPagination, { toQueryParams } from "../../interfaces/pagination";
import ModalDemandImportSuccess from "./ModalSuccess/ModalSuccess";
import ModalDemandImportError from "./ModalError/ModalError";

function DemandImportPage() {
  const [demands, setDemands] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [meta, setMeta] = useState<any>({});
  const [modalSuccess, setModalSuccessState] = useState<boolean>(false);
  const [modalError, setModalErrorState] = useState<boolean>(false);
  const [error, setError] = useState<any>('');
  const toast = useRef<Toast>(null);

  async function handleFileUpload(e: any) {
    const file = e.files[0];
    const formData = new FormData();
    formData.append("demand", file);
    const res = await http
      .post("/v1/buyer-demands/import", formData);
    const hasErrors = res.data.hasOwnProperty('errors') ? res.data.errors : [];
    if (res.status === 200 && hasErrors?.length === 0) {
      setModalSuccessState(true);
      fetchDemands({});
      toast.current?.show({ severity: 'success', summary: 'Sucesso', detail: 'Arquivo importado com sucesso!', life: 3000 });
    } else {
      setError(res);
      setModalErrorState(true);
      fetchDemands({});
      toast.current?.show({ severity: 'error', summary: 'Erro', detail: 'Falha ao importar o arquivo.', life: 3000 });
    }
  }

  const fetchDemands = ({ page = 1, perPage = 5 }: iPagination) => {
    setLoading(true);
    const user = localStorage.getItem("user");
    http
      .get(`/v1/buyer-demands/me/${JSON.parse(user ?? '{id: 1}').id}${toQueryParams({ perPage, page, paginate: true, questionMark: true })}`)
      .then(res => {
        setDemands(res.data.data);
        setMeta(res.data.meta);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchDemands({});
  }, []);

  function closeModalSuccess() {
    setModalSuccessState(false);
  }

  function closeModalError() {
    setModalErrorState(false);
  }

  return (
    <div className="w-full page-height p-10 overflow-scroll">
      <Toast ref={toast} /> {/* Toast component from PrimeReact */}
      <div className="w-full flex justify-start mt-[10px]">
        <ModalDemandImportSuccess isOpen={modalSuccess} closeModal={closeModalSuccess} />
        <ModalDemandImportError isOpen={modalError} error={error} closeModal={closeModalError} />
        <FileUpload
          name="demand"
          url="/v1/buyer-demands/import"
          accept=".csv"
          customUpload
          uploadHandler={handleFileUpload}
          chooseLabel="Importar Demanda"
          className="p-button p-component p-fileupload p-button-success"
        />
      </div>

      <PageHeader>Meus arquivos de demandas importados:</PageHeader>
      {loading && (
        <div className="w-full h-36 grid place-content-center p-20">
          <Loading color="#212529" size="w-10" />
        </div>
      )}
      {!loading && demands.length === 0 && (
        <div className="w-full h-36 grid place-content-center p-20">
          <p>Nenhuma demanda encontrada</p>
        </div>
      )}
      {!loading && demands.length > 0 && (
        <div>
          <DataTable value={demands} className="mt-0 font-roboto">
            <Column field="id" header="Nº de cadastro" style={{ width: '10%' }}></Column>
            <Column field="date_init" header="Início" body={(rowData) => dateBR({ date: rowData.date_init })} style={{ width: '20%' }}></Column>
            <Column field="date_end" header="Fim" body={(rowData) => dateBR({ date: rowData.date_end })} style={{ width: '20%' }}></Column>
            <Column field="product_type.name" header="Tipo de castanha" style={{ width: '15%' }}></Column>
            <Column field="product_subtype.name" header="Subtipo de castanha" style={{ width: '15%' }}></Column>
            <Column header="Previsão" body={(rowData) => dateDiff(rowData.date_init, rowData.date_end) + ' dias'} style={{ width: '10%' }}></Column>
            <Column field="status" header="Status" body={(rowData) => <Tag value={rowData.status === 1 ? 'Ativo' : 'Desativado'} severity={rowData.status === 1 ? 'success' : 'danger'} />} style={{ width: '10%' }}></Column>
          </DataTable>
        </div>
      )}
    </div>
  );
}

export default DemandImportPage;
