import React, { useEffect, useState, useRef } from "react";
import { Timeline } from "primereact/timeline";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "../styles/TrackingUpdates.css";
import ModalConfirmation from "../../Parameterization/Tables/Dashboards/ModalConfirmation/ModalConfirmation";
import ModalAlert from "../../../components/ModalAlert";
import { set } from "date-fns";
const TrackingEvents = () => {
  const [events, setEvents] = useState([
    { id: 0, status: "Aguardando próxima atualização...", date: "" },
    {
      id: 1,
      status: "O pedido está em transferência entre as filiais da transportadora",
      date: "22/11/2022 - 09:03",
    },
  ]);
  const [newEvent, setNewEvent] = useState("");
  const [newTimestamp, setNewTimestamp] = useState("");
  const [editId, setEditId] = useState<number | null>(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [headerMessage, setHeaderMessage] = useState<string>("");
  const [bodyMessage, setBodyMessage] = useState<string>("");
  const [deleteMessage, setDeleteMessage] = useState<boolean>(false);
  const [tempEditId, setTempEditId] = useState<number | null>(null);
  const [tempDeleteId, setTempDeleteId] = useState<number | null>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const formatTimestamp = () => {
    const date = new Date();
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${day}/${month}/${year} - ${hours}:${minutes}`;
  };

  const cancelEdit = () => {
    setEditId(null);
    setNewEvent("");
  };

  const addEvent = () => {
    if (!newEvent) {
      alert("Por favor, preencha todos os campos.");
      return;
    }
    //criar uma data com tempo
    const formattedTimestamp = formatTimestamp();
    const nextId = events.length > 0 ? Math.max(...events.map(u => u.id)) + 1 : 1;
    setEvents([...events, { id: nextId, status: newEvent, date: formattedTimestamp }]);
    setNewEvent("");
    setNewTimestamp("");
  };

  const deleteEvent = (id: any) => {
    if (id === null) return;
    setEvents(events.filter(update => update.id !== id));
  };

  const editEvent = (id: number | null) => {
    if (id === null) return;

    const update = events.find(update => update.id === id);
    if (update) {
      setEditId(id);
      setNewEvent(update.status);
      setNewTimestamp(update.date);
    } else {
      console.error(`No update found with id: ${id}`);
    }
  };

  const updateEdit = () => {
    if (!newEvent || editId == null) {
      alert("Por favor, preencha todos os campos.");
      return;
    }
    const formattedTimestamp = formatTimestamp();
    setEvents(
      events.map(update => {
        if (update.id === editId) {
          return { ...update, status: newEvent, date: formattedTimestamp };
        }
        return update;
      }),
    );
    setEditId(null);
    setNewEvent("");
    setNewTimestamp("");
  };

  const toggleConfirmationModal = (id: number | null) => {
    setTempDeleteId(id);
    setIsConfirmationModalOpen(true);
  };

  const toggleEditModal = (id: number) => {
    setTempEditId(id);
    setIsEditModalOpen(true);
  };

  const handleEditConfirmation = (id: number | null) => {
    editEvent(id);
    setHeaderMessage("Edição realizada com sucesso!");
    setBodyMessage(`Rastreio editado com sucesso.`);
    setIsEditModalOpen(false);
    setTimeout(() => {
      textAreaRef.current?.focus();
    }, 500);
  };

  const handleDeleteConfirmation = (id: number | null) => {
    deleteEvent(tempDeleteId);
    setHeaderMessage("Exclusão realizada com sucesso!");
    setBodyMessage(`Rastreio excluído com sucesso.`);
  };

  return (
    <div className="max-w-4xl mt-10">
      <Timeline
        value={events}
        align="left"
        content={(item, idx) => (
          <div className="flex justify-between items-center">
            <div className="content">
              <div className="text-[#3A3952]">{item.status}</div>
              <small className="text-color-secondary">{item.date}</small>
            </div>
            {idx !== 0 && (
              <div className="flex items-center gap-4">
                <button
                  onClick={() => toggleEditModal(item.id)}
                  className="text-[#81C46D] hover:text-green-600 text-sm font-semibold"
                >
                  Editar
                </button>
                <ModalConfirmation
                  isOpen={isEditModalOpen}
                  toggle={() => setIsEditModalOpen(false)}
                  onConfirm={() => handleEditConfirmation(tempEditId)}
                  message={`Você realmente quer editar este rastreio?`}
                />
                <button
                  onClick={() => toggleConfirmationModal(item.id)}
                  className="text-[#E04F5D] hover:text-red-700 text-sm font-semibold"
                >
                  Excluir
                </button>
                <ModalConfirmation
                  isOpen={isConfirmationModalOpen}
                  toggle={() => setIsConfirmationModalOpen(false)}
                  onConfirm={() => handleDeleteConfirmation(tempDeleteId)}
                  message={`Você realmente quer excluir este rastreio?`}
                />
              </div>
            )}
          </div>
        )}
        pt={{
          marker: { className: "bg-green-400" },
        }}
      />
      <div className="max-w-xl mt-10">
        <textarea
          ref={textAreaRef}
          className={`
            w-full
            border
            focus:outline-none
            focus:ring-2
            p-2
            rounded-md
            font-sans
            max-sm:w-64
          `}
          value={newEvent}
          name=""
          id=""
          cols={10}
          rows={5}
          placeholder="Digite a nova localização"
          onChange={e => setNewEvent(e.target.value)}
        ></textarea>
        {editId == null ? (
          <button className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-700" onClick={addEvent}>
            ADD RASTREIO
          </button>
        ) : (
          <div className="flex gap-2">
            <button className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700" onClick={updateEdit}>
              ATUALIZAR
            </button>
            <button className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-700" onClick={cancelEdit}>
              CANCELAR
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default TrackingEvents;
