import { PatternFormat } from 'react-number-format';

export function MaskCPF({ value }: { value: string }) {

  return (
    <PatternFormat value={value} displayType="text" format="###.###.###-##" allowEmptyFormatting mask="_" />
  )
}


export function MaskCNPJ({ value }: { value: string }) {

  return (
    <PatternFormat value={value} displayType="text" format="##.###.###/####-##" allowEmptyFormatting mask="_" />
  )
}

export function MaskCPForCNPJ({ value }: { value?: string }) {
  if (!value) {
    return ''
  }
  value = value.replace(/\D/g, '')
  if (value.length <= 11) {
    return MaskCPF({ value })
  } else {
    return MaskCNPJ({ value })
  }
}

export function MaskPhone({ value }: { value: string }) {
  if (value.length < 11) {
    return (
      <PatternFormat value={value} displayType="text" format="(##) ####-####" allowEmptyFormatting mask="_" />
    )
  }
  return (
    <PatternFormat value={value} displayType="text" format="(##) #####-####" allowEmptyFormatting mask="_" />
  )
}