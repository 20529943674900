import { useEffect, useState } from "react";
import http from "../../../plugins/http";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import Loading from "../../../components/Loading";
import { useToast } from "../../../components/Toast";
import TablePagination from "../../../components/TablePagination";
import { Message } from "primereact/message";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { MaskCPForCNPJ } from "../../../plugins/mask";
import { Dropdown } from "primereact/dropdown";
import { BreadCrumb } from "primereact/breadcrumb";

const DemandsTable: React.FC = () => {
  const today = new Date();
  const prevMonth = new Date();
  prevMonth.setMonth(today.getMonth() - 1);
  const toast = useToast();
  const [demands, setDemands] = useState([]);
  const [loading, setLoading] = useState(false);
  const [productGroups, setProductGroups] = useState<any[]>([]);
  const [productTypes, setProductTypes] = useState<any[]>([]);
  const [productSubtypes, setProductSubtypes] = useState<any[]>([]);
  const [filters, setFilters] = useState({
    dateInitial: prevMonth.toISOString().split("T")[0],
    dateFinal: new Date().toISOString().split("T")[0],
    page: 1,
    perPage: 100,
    product_group: null,
    product_type: null,
    product_subtype: null,
    status: null
  });


  const fetchDemands = async (filter: any) => {
    try {
      setLoading(true);
      console.log(filter);
      const response = await http.post("v1/reports/demands", filter);
      if (response.status === 200) {
        setDemands(response.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  async function fetchProductGroups() {
    try {
      setLoading(true);
      const response = await http.get("v1/product-groups?all=1");
      if (response.status === 200) {
        setProductGroups([{ id: null, name: "Todos" }, ...response.data]);
      }
      return []
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function fetchProductTypes() {
    try {
      setLoading(true);
      const responseTypes = await http.get("v1/product-types?all=1");
      if (responseTypes.status === 200) {
        return responseTypes.data;
      }
      return [];
    } catch (error) {
      console.log(error);
      return [];
    } finally {
      setLoading(false);
    }
  }

  async function fetchProductSubtypes() {
    try {
      setLoading(true);
      const responseSubtypes = await http.get("v1/product-subtypes?all=1");
      if (responseSubtypes.status === 200) {
        return responseSubtypes.data;
      }
      return [];
    } catch (error) {
      console.log(error);
      return [];
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchDemands(filters);
    fetchProductGroups();
  }, []);

  const handleFilterChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "product_group") {
      fetchProductTypes().then((response) => {
        setProductTypes([{ id: null, name: "Todos" }, ...response]);
        setProductSubtypes([]);
      });
    }
    if (name === "product_type") {
      fetchProductSubtypes().then((response) => {
        setProductSubtypes([{ id: null, name: "Todos" }, ...response]);
      });
    }
    fetchDemands({ ...filters, [name]: value });
    setFilters(prevFilters => ({ ...prevFilters, [name]: value }));
  }

  const generateReport = async () => {
    http.post("v1/reports/export-demands", filters, {
      responseType: 'blob',
    }).then((response) => {
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'relatorio-demandas.xlsx'; // Nome do arquivo
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
      toast.current?.show({
        severity: "success",
        summary: "Sucesso",
        detail: "Relatório gerado com sucesso",
      });
    }).catch((error) => {
      console.log(error);
      toast.current?.show({
        severity: "error",
        summary: "Erro",
        detail: "Erro ao gerar relatório",
      });
    });
  }

  const userSituation = [
    { public_name: "Todos", value: null },
    { public_name: "Ativados", value: 1 },
    { public_name: "Desativados", value: 0 },
  ]

  const statusTemplate = (rowData: any) => {
    return (
      <div className="text-center">
        {rowData?.status === 1 ? (
          <p className="text-[#28A745]">Ativo</p>
        ) : (
          <p className="text-red-500">Desativado</p>
        )}
      </div>
    );
  };

  const dateTemplate = (field: string) => {
    return (
      <div className="text-center">
        {new Date(field).toLocaleDateString()}
      </div>
    );
  };

  const items = [{ label: 'Relatórios', url: '/reports' }, { label: 'Relatório de Demandas' }];
  const home = { icon: 'pi pi-home', url: '/home' }

  return (
    <div className="w-full p-10">
      <BreadCrumb model={items} home={home} className="mb-4" />
      <div className="flex flex-wrap">
        <div className="w-4/12 xl:w-3/12 flex flex-col">
          <label className="text-md font-bold mb-1">Cadeia</label>

          <Dropdown name="product_group" value={filters.product_group} options={productGroups} onChange={handleFilterChange} optionLabel="name" className="!w-full" placeholder="Cadeia" />
        </div>
        <div className="w-4/12 xl:w-3/12 flex flex-col pl-2">
          <label className="text-md font-bold mb-1">Tipo</label>
          <Dropdown name="product_type" value={filters.product_type} options={productTypes} onChange={handleFilterChange} optionLabel="name" className="!w-full" placeholder="Tipo" />
        </div>
        <div className="w-4/12 xl:w-3/12 flex flex-col pl-2">
          <label className="text-md font-bold mb-1">Subtipo</label>
          <Dropdown name="product_subtype" value={filters.product_subtype} options={productSubtypes} onChange={handleFilterChange} optionLabel="name" className="!w-full" placeholder="Subtipo" />
        </div>
        <div className="w-4/12 xl:w-3/12 flex flex-col pl-2">
          <label className="text-md font-bold mb-1">Situação</label>
          <Dropdown name="status" value={filters.status} options={userSituation} onChange={handleFilterChange} optionLabel="public_name" placeholder="Situação" className="!w-full" />
        </div>
        <div className="w-6/12 flex">
          <div className="w-1/2 flex flex-col pr-2">
            <label className="text-md font-bold mb-1">Data Inicial</label>
            <InputText
              value={filters.dateInitial}
              name="dateInitial"
              onChange={handleFilterChange}
              type="date"
            />
          </div>
          <div className="w-1/2 flex flex-col pl-2">
            <label className="text-md font-bold mb-1">Data Final</label>
            <InputText
              value={filters.dateFinal}
              name="dateFinal"
              onChange={handleFilterChange}
              type="date"
            />
          </div>
          <div className="flex flex-col pl-2">
            <label className="text-md font-bold mb-1">&nbsp;</label>
            <Button
              label="Filtrar"
              disabled={loading}
              onClick={() => fetchDemands(filters)}
              severity="success"
            />
          </div>
          <div className="flex flex-col pl-2">
            <label className="text-md font-bold mb-1">&nbsp;</label>
            <Button
              label="Exportar"
              disabled={loading}
              onClick={generateReport}
              severity="success"
            />
          </div>
        </div>
      </div>

      {loading && (
        <div className="w-full h-36 grid place-content-center p-20">
          <Loading color="#212529" size="w-10" />
        </div>
      )}
      {!loading && demands.length === 0 && (
        <div className="w-full h-36 grid place-content-center p-20">
          <p>Nenhuma usuário encontrado com os filtros aplicados</p>
        </div>
      )}
      {!loading && demands.length > 0 && (
        <div className="h-full w-full mt-10 overflow-auto">
          <Message severity="info" className="w-full" text={`Total de registros: ${demands.length}`} />
          <DataTable showGridlines value={demands} className="w-full mt-4 font-roboto text-black">
            <Column body={(row) => <span>{row?.user_full?.name}</span>} header="Comprador" />
            <Column body={(item) => (<span>{item?.user_full?.cpf_or_cnpj}</span>)} header="CNPJ" />
            <Column body={(row) => <span>{row?.user_full?.email}</span>} header="Email" />
            <Column body={(row) => <span>{row?.user_full?.phone}</span>} header="Celular" />
            <Column field="description" header="Demanda" />
            <Column body={(row) => <span>{row?.product_group?.name}</span>} header="Cadeia Produtiva" />
            <Column body={(row) => <span>{row?.product_type?.name}</span>} header="Tipo" />
            <Column body={(row) => <span>{row?.product_subgroup?.name}</span>} header="Subtipo" />
            <Column body={statusTemplate} header="Situação" />
            <Column body={(row) => dateTemplate(row.date_init)} header="Data de Inicio" />
            <Column body={(row) => dateTemplate(row.date_end)} header="Data de Termino" />
            <Column body={(row) => dateTemplate(row.created_at)} header="Data de Cadastro" />
          </DataTable>
        </div>
      )}
    </div>
  );
};

export default DemandsTable;