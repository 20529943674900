const weightOptions = [
  { "id": 1, "label": "Quilograma (Kg)", "checked": false },
  { "id": 2, "label": "Tonelada (T)", "checked": false }
];

const companyOptions = [
  { "id": 1, "label": "Produtoras", "checked": false },
  { "id": 2, "label": "Compradoras", "checked": false },
  { "id": 3, "label": "Produtoras e Compradoras", "checked": false },
]

const productVolumeOptions = [
  { "id": 1, "label": "Volume de Produtos cadastrados", "checked": false },
  { "id": 2, "label": "Volume de Produtos anunciados", "checked": false },
];

const valuesType = [
  { "id": 1, "label": "Compras", "checked": false },
  { "id": 2, "label": "Vendas", "checked": false },
];

const proposalsType = [
  { "id": 1, "label": "Propostas criadas", "checked": false },
  { "id": 2, "label": "Propostas aceitas", "checked": false },
  { "id": 3, "label": "Propostas recusadas", "checked": false },
];

const productValuesOptions = [
  { "id": 1, "label": "Valores de Produtos cadastrados", "checked": false },
  { "id": 2, "label": "Valores de Produtos anunciados", "checked": false },
];

const statusOptions = [
  { "id": 1, "label": "Ativo", "checked": false },
  { "id": 2, "label": "Inativo", "checked": false },
];

const batchOptions = [
  { "id": 1, "label": "Produzidos", "checked": false },
  { "id": 2, "label": "Comprados", "checked": false },
];

const months = [
  { value: "", label: "Selecione", isDisabled: true },
  { value: "1", label: "Janeiro" },
  { value: "2", label: "Fevereiro" },
  { value: "3", label: "Março" },
  { value: "4", label: "Abril" },
  { value: "5", label: "Maio" },
  { value: "6", label: "Junho" },
  { value: "7", label: "Julho" },
  { value: "8", label: "Agosto" },
  { value: "9", label: "Setembro" },
  { value: "10", label: "Outubro" },
  { value: "11", label: "Novembro" },
  { value: "12", label: "Dezembro" },
];

const years = [
  { value: "", label: "Selecione", isDisabled: true },
  { value: "2021", label: "2025" },
  { value: "2021", label: "2024" },
  { value: "2021", label: "2023" },
  { value: "2021", label: "2022" },
  { value: "2021", label: "2021" },
  { value: "2020", label: "2020" },
  { value: "2019", label: "2019" },
];

export {
  batchOptions, companyOptions, productValuesOptions, productVolumeOptions, proposalsType, statusOptions, valuesType, weightOptions, years, months
};

