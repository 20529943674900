import { useEffect, useState } from "react";
import PageHeader from "../PageHeader";
import { Link } from "react-router-dom";
import { Search } from "@styled-icons/fa-solid";
import { Person } from "@styled-icons/fa-solid";

function CarrierList(args: any) {
  const { carriersDetails, ...otherArgs } = args;
  const [datas, setDatas] = useState<any[]>(carriersDetails);
  const [filter, setFilter] = useState<String>("all");

  function openDetail(detail: any, index: number) {
    // Aqui deve fazer uma requisição para alterar a propriedade readed para true no banco
  }

  function filterData(e: any) {
    const value = e.target.value;
    if (value === "") return setDatas(carriersDetails);
    const filtered = carriersDetails.filter((detail: { buyer: any }) => {
      return detail.buyer.name.toLowerCase().includes(value.toLowerCase());
    });
    setDatas(filtered);
  }

  return (
    <div className="w-full p-10 page-height">
      <div className="w-full p-10 bg-white">
        <PageHeader>
          <div className="text-[#205F16] uppercase pb-4">Notificações</div>
          <div className="flex space-x-10">
            <div className="h-[38px] flex text-center">
              <input
                className="text-[#757B81] h-[38px] border-2 border-[#479632] bg-[white] 
            px-2 focus:outline-none"
                onChange={filterData}
              ></input>
              <div className="bg-[#479632] rounded-0 w-8 flex justify-center items-center">
                <Search className="w-6 h-6 text-white"></Search>
              </div>
            </div>
            {filter === "all" ? (
              <>
                <div className="text-[#205F16] text-xl cursor-pointer">Tudo</div>
                <div className="text-gray-400 text-xl cursor-pointer" onClick={() => setFilter("notReaded")}>
                  Não Lidas
                </div>{" "}
              </>
            ) : (
              <>
                <div className="text-gray-400 text-xl cursor-pointer" onClick={() => setFilter("all")}>
                  Tudo
                </div>
                <div className="text-[#205F16] text-xl cursor-pointer">Não Lidas</div>{" "}
              </>
            )}
          </div>
        </PageHeader>
        <div className="flex flex-col pt-6">
          {datas.map((detail: any, index: number) => {
            if (detail.readed && filter === "all") {
              return (
                <div className="flex items-center justify-between space-x-6 px-5 py-2">
                  <div className="bg-gray-300 rounded-full w-16 h-16 p-4 flex justify-center items-center">
                    <Person className="text-[#0B405F]"></Person>
                  </div>
                  <div className="flex space-x-8 w-3/5">
                    <div>Solicitação de transporte</div>
                    <div className="w-4/6 text-[#205F16] font-bold">{detail.buyer.name}</div>
                  </div>
                  <div>{detail.buyer.post_date}</div>
                  <Link to={`/carrier-profile-notification/view/${detail.id}`} state={{ detail }}>
                    <div
                      className="text-[#479632] cursor-pointer"
                      onClick={() => {
                        openDetail(detail, index);
                      }}
                    >
                      Visualizar
                    </div>
                  </Link>
                </div>
              );
            }
            if (!detail.readed) {
              return (
                <div className="flex items-center justify-between space-x-6 px-5 py-2 bg-[#d8ffcd]">
                  <div className="bg-gray-300 rounded-full w-16 h-16 p-4 flex justify-center items-center">
                    <Person className="text-[#0B405F]"></Person>
                  </div>
                  <div className="flex space-x-8 w-3/5 font-bold">
                    <div>Solicitação de transporte</div>
                    <div className="w-4/6 text-[#205F16] font-bold">{detail.buyer.name}</div>
                  </div>
                  <div>{detail.buyer.post_date}</div>
                  <Link to={`/carrier-profile-notification/view/${detail.id}`} state={{ detail }}>
                    <div
                      className="text-[#479632] cursor-pointer"
                      onClick={() => {
                        openDetail(detail, index);
                      }}
                    >
                      Visualizar
                    </div>
                  </Link>
                </div>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
}

export default CarrierList;
