import { useState } from "react";
import CarrierList from "../../components/CarrierLists/CarrierListNotification";
import Loading from "../../components/Loading";

function CarrierProfileNotificationPage() {
  const [loading, setLoading] = useState<boolean>(false);

  const carriersDetails = [
    {
      id: 1,
      readed: true,
      carrier: {
        name: "NAVEGAM",
        delivery_time: 5,
        price: 300.0,
      },
      buyer: {
        name: "Anderson Filho",
        post_date: "20/01/2023",
        state: "Amazonas",
        city: "Manaus",
        destiny: "Novo Airão - AM",
      },
      product: {
        name: "Castanha do Pará",
        type: "sem casca",
        weight: "200kg",
        stock: "Disponível",
        origin: "Tefé/AM",
        price: 5000.0,
      },
    },
    {
      id: 2,
      readed: true,
      carrier: {
        name: "TRANSPORTA",
        delivery_time: 3,
        price: 150.0,
      },
      buyer: {
        name: "Maria Souza",
        post_date: "07/11/2023",
        state: "São Paulo",
        city: "Campinas",
        destiny: "São Paulo - SP",
      },
      product: {
        name: "Café Especial",
        type: "grãos torrados",
        weight: "100kg",
        stock: "Disponível",
        origin: "Espírito Santo do Pinhal/SP",
        price: 1200.0,
      },
    },
    {
      id: 3,
      readed: false,
      carrier: {
        name: "TRANSPORTA4",
        delivery_time: 5,
        price: 180.0,
      },
      buyer: {
        name: "Taylor Kotlin",
        post_date: "17/10/2023",
        state: "Maranhão",
        city: "Morumbi",
        destiny: "São Paulo - SP",
      },
      product: {
        name: "Açai Açu",
        type: "Sementes",
        weight: "100kg",
        stock: "Disponível",
        origin: "Espírito Santo do Pinhal/SP",
        price: 1800.0,
      },
    },
  ];

  return (
    <>
      {loading && (
        <div className="w-full h-36 grid place-content-center p-20">
          <Loading color="#212529" size="w-10" />
        </div>
      )}
      {!loading && carriersDetails.length === 0 && (
        <div className="w-full h-36 grid place-content-center p-20">
          <p>Nenhum detalhe de notificação cadastrado</p>
        </div>
      )}
      {!loading && carriersDetails.length > 0 && <CarrierList carriersDetails={carriersDetails}></CarrierList>}
    </>
  );
}

export default CarrierProfileNotificationPage;
