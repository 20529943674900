import { cpf } from "cpf-cnpj-validator";
import * as Yup from "yup";

const representativeSchema = Yup.object().shape({
  id_role: Yup.number().required("Perfil de usuário é obrigatório"),
  id_state: Yup.number().required("Estado é obrigatório"),
  id_city: Yup.number().required("Cidade é obrigatório"),
  email: Yup.string()
    .email("Digite um email correto")
    .required("Email obrigatório")
    .max(60, "Deve ter no maximo 60 caracteres"),
  password: Yup.string()
    .required("Senha obrigatória")
    .min(6, "Deve ter no mínimo 6 caracteres")
    .max(14, "Deve ter no maximo 14 caracteres"),
  cpf_or_cnpj: Yup.string()
    .required("CPF obrigatório")
    .test("cpf_or_cnpj", "CPF inválido", (value: string) => cpf.isValid(value)),
  name: Yup.string()
    .required("Nome obrigatório")
    .min(3, "Deve ter no mínimo 3 caracteres")
    .max(60, "Deve ter no maximo 60 caracteres"),
  phone: Yup.string().required("Celular obrigatório"),
  confirmPassword: Yup.string()
    .required("Confirmação de senha obrigatória")
    .equals([Yup.ref("password")], "As senhas devem ser iguais"),
  business_profile: Yup.string().required("Perfil de Comercial é obrigatório"),
  id_area: Yup.number().required("Área/Região é obrigatório"),
  commision_percentage: Yup.number().required("Percentual de Comissão é obrigatório").max(100, "Percentual de Comissão deve ser menor que 100").min(0, "Percentual de Comissão deve ser maior que 0"),
  bank_name: Yup.string().required("Nome do banco é obrigatório"),
  bank_agency: Yup.string().required("Agência do banco é obrigatório"),
  bank_account: Yup.string().required("Conta do banco é obrigatório"),
  pix_key: Yup.string()
    .test(
      'pixKey',
      'Chave Pix inválida',
      function (value) {
        if (value === undefined) return true;

        const { path, createError } = this;

        // Validação de email
        const emailRegEx = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        // Validação de CPF
        const cpfRegEx = /^\d{3}\.\d{3}\.\d{3}\-\d{2}$/;

        // Validação de telefone
        const phoneRegEx = /^\(\d{2}\)\s\d{5}-\d{4}$/;

        // Validação de chave aleatória
        const randomKeyRegEx = /^[0-9a-fA-F]{32}$/;

        if (emailRegEx.test(value) || cpfRegEx.test(value) || phoneRegEx.test(value) || randomKeyRegEx.test(value)) {
          return true;
        }

        return createError({
          path,
          message: 'Chave Pix inválida'
        });
      }
    )
    .required('Chave Pix é obrigatória')
});

export default representativeSchema;