import tw from 'twin.macro'

export const FooterContainer = tw.footer`
  bg-[#317025]
`

export const FooterContent = tw.div`
  container
  h-[56px]
  grid
  place-content-center
`
