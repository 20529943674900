import { BreadCrumb } from "primereact/breadcrumb"
import { Card } from "primereact/card"
import React from "react"
import { Link } from "react-router-dom"

function CardReport({ title, route }: { title: string; route: string }) {
	return (
		<Link to={route}>
			<Card className='text-center group hover:bg-green-500 duration-300'>
				<h1 className='group-hover:text-white group-hover:font-bold duration-300'>
					{title}
				</h1>
			</Card>
		</Link>
	)
}
const items = [{ label: "Relatórios", url: "/reports" }]
const home = { icon: "pi pi-home", url: "/home" }

const ReportsPage: React.FC = () => {
	return (
		<div className='p-10'>
			<BreadCrumb model={items} home={home} className='mb-4' />
			<div className='w-full h-screen grid grid-cols-5 grid-rows-3 gap-2'>
				<CardReport
					route='/reports/general'
					title='Relatório geral'
				></CardReport>
				<CardReport
					route='/reports/sales'
					title='Relatório de vendas'
				></CardReport>
				<CardReport
					route='/reports/users'
					title='Relatório de usuário'
				></CardReport>
				<CardReport
					route='/reports/products'
					title='Relatório de produtos'
				></CardReport>
				<CardReport
					route='/reports/demands'
					title='Relatório de demandas'
				></CardReport>
				{/* <CardReport
        route="/reports/comissions"
        title="Relatório de comissões"></CardReport> */}
			</div>
		</div>
	)
}

export default ReportsPage
