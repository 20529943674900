import { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

interface Props {
  isOpen: boolean;
  title: string;
  body: string;
  buttonLabel: string;
  urlToRedirect?: string;
}

export const SuccessModal = ({
  title,
  body,
  buttonLabel,
  isOpen = false,
  urlToRedirect,
  ...args
}: Props) => {
  const [modal, setModal] = useState(isOpen);

  const toggle = () => {
    setModal(!modal);

    if (urlToRedirect) {
      window.location.href = `/ ${urlToRedirect} `;
    }
  };

  const footer = (
    <div className="flex justify-end">
      <Button
        label={buttonLabel}
        className="bg-[#205F16] text-[#ffffff] hover:bg-[#143d0e] border-0"
        onClick={toggle}
      />
    </div>
  );

  return (
    <Dialog
      header={title}
      visible={modal}
      footer={footer}
      onHide={toggle}
      {...args}
      className="w-full md:w-2/3"
    >
      <div className="text-base">
        {body}
      </div>
    </Dialog>
  );
};