import { Form, Formik, FormikProps, useFormikContext } from "formik";
import { useRef, useState } from "react";
import FormikInput from "../../../components/FormikInput";
import http from "../../../plugins/http";
import { Block, CheckCircle } from "@styled-icons/boxicons-regular";
import Loading from "../../../components/Loading";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Message } from "primereact/message";
import Schema from "../validation/schema";
import FormikTextArea from "../../../components/FormikInputTextArea";
function ModalReproachProduct({
  productId,
  className = "",
  disapprove,
}: {
  productId: any;
  className?: string;
  disapprove: (user: any, form: any) => void;
}) {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const schema = Schema;
  const formikRef = useRef<FormikProps<typeof initialValues>>(null);
  const toggle = () => {
    setModal(prevModal => !prevModal);
  };

  let initialValues = {
    justification: "",
  };

  function FormButtons() {
    const { submitForm } = useFormikContext();
    return (
      <div className="w-full flex justify-center p-4 gap-x-4">
        <Button type="submit" severity="success" onClick={submitForm} loading={loading}>
          Enviar
        </Button>
      </div>
    );
  }

  return (
    <div className="inline-flex">
      <Button onClick={toggle} disabled={loading} severity="danger">
        Reprovar
      </Button>
      <Dialog header="Motivo da reprovação" visible={modal} onHide={toggle} className={className}>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={(values, actions) => {
            disapprove(productId, values);
            toggle();
          }}
          key={"form-change-user-status"}
        >
          <>
            <Form className="flex flex-wrap space-y-6 p-10">
              <span className="flex justify-center text-center">
                Descreva em detalhes para o produtor qual o motivo o produto cadastrado não foi aprovado.
              </span>
              <FormikTextArea className="w-full" label="" name="justification" rows={5} />
            </Form>
            <FormButtons />
            {error !== "" && (
              <Message className="w-full mt-4" color="danger">
                {error}
              </Message>
            )}
          </>
        </Formik>
      </Dialog>
    </div>
  );
}
export default ModalReproachProduct;
