import * as Yup from "yup"

const Schema = Yup.object().shape({
  justification: Yup.string()
    .required("Justificativa obrigatória")
    .min(10, "Deve ter no mínimo 10 caracteres")
    .max(120, "Deve ter no maximo 120 caracteres"),
})

export default Schema
