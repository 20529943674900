import { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Message } from "primereact/message";
import FormikInput from "../../../components/FormikInput";
import { Formik } from "formik";
import FormikSelect from "../../../components/FormikSelect";
import Loading from "../../../components/Loading";
import http from "../../../plugins/http";
import { useFormik } from "formik";
import { dateBR } from "../../../utils/data";
import TablePagination from "../../../components/TablePagination";

function ModalReportGeneral({
  filterSelectedSellers,
  hasSelected,
  datas,
  args,
  onOpen,
  cleanSelections,
  chains,
}: {
  filterSelectedSellers: any;
  hasSelected: any;
  datas: any;
  args?: any;
  onOpen: any;
  cleanSelections: any;
  chains: any;
}) {
  const formik = useFormik({
    initialValues: {
      data_inicial: "",
      data_final: "",
      id_chain: "",
    },
    onSubmit: values => { },
  });

  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [selectedData, setSelectedData] = useState<any[]>([]);
  const [selectedChain, setSelectedChain] = useState<any>("");
  const [fetchedOrder, setFetchedOrder] = useState(false);
  const [fetchedChains, setFetchedChains] = useState<any[]>([]);
  const [initialValues, setInitialValues] = useState<any>({
    data_inicial: "",
    data_final: "",
    id_chain: "",
  });
  const toggle = () => {
    setModal(prevModal => !prevModal);
    if (!modal) {
      formik.resetForm();
    }
  };

  const showReport = async (values: any) => {
    try {
      setLoading(true);
      const response = await http.get(
        `v1 / managers / manager - report ? start = ${values.data_inicial}& end=${values.data_final}& users=${datas
          .map((data: any) => data.id)
          .join(",")
        }& chain=${values.id_chain} `,
        {
          responseType: "blob",
        },
      );
      if (response.status === 403) {
        setError("Você não tem permissão para visualizar este relatório");
      }
      if (response.status === 200) {
        const href = URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "report.pdf");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (chains) {
      setFetchedChains(chains);
    }
  }, [chains]);

  const footer = (
    <div className="flex flex-wrap justify-end gap-x-4">
      <Button
        label="LIMPAR"
        disabled={!formik.values.data_inicial && !formik.values.data_final && !formik.values.id_chain}
        type="reset"
        onClick={() => {
          formik.resetForm();
          setSelectedChain("");
        }}
        className="w-full md:w-auto flex-shrink-0 align-middle uppercase bg-[#FFFFFF] hover:bg-[#28A745] text-[#146600] font-bold hover:border-[#28A745] text-[15px] font-nunito h-[38px] border-0 hover:border-0"
      />
      <Button
        label="Cancelar"
        onClick={toggle}
        disabled={loading}
        className="uppercase text-[15px] font-nunito h-[38px] font-bold text-[#146600] hover:text-white bg-white hover:bg-[#85D46C] border-1 hover:border-0"
      />
      <Button
        label="Visualizar"
        onClick={() => showReport(formik.values)}
        disabled={loading || !formik.values.data_inicial || !formik.values.data_final || !selectedChain}
        className="uppercase text-[15px] font-nunito h-[38px] font-bold hover:bg-[#479632] bg-green-500 border-0 hover:border-0 flex flex-wrap gap-x-4"
      >
        {loading && <Loading />}
      </Button>
    </div>
  );

  return (
    <>
      <Button
        label="Relatório Geral"
        onClick={toggle}
        className="w-full md:w-auto flex-shrink-0 align-middle uppercase text-[15px] font-nunito h-[38px] font-bold hover:bg-[#479632] bg-green-500 border-0 hover:border-0"
      />
      <Dialog header="Relatório de Vendas Geral:" visible={modal} onHide={toggle} footer={footer} className="w-full md:w-2/3" modal>
        <Formik
          initialValues={initialValues}
          onSubmit={values => { }}
          onReset={(values, { resetForm }) => {
            setSelectedChain("");
          }}
          key={"form-search-percentages-probabilities"}
        >
          {({ values, resetForm }) => (
            <form className="flex flex-wrap space-y-1">
              <div className="flex flex-col md:flex-row items-center w-full justify-between p-4 space-y-2 md:space-y-0 md:space-x-1 mr-4">
                <div className="flex-initial md:flex-grow mb-2 md:mb-0">
                  <FormikInput
                    onChanged={formik.handleChange}
                    value={values.data_inicial}
                    name="data_inicial"
                    label="Data Inicial"
                    type="date"
                  />
                </div>
                <div className="flex-initial md:flex-grow mb-2 md:mb-0">
                  <FormikInput
                    onChanged={formik.handleChange}
                    value={values.data_final}
                    name="data_final"
                    label="Data Final"
                    type="date"
                  />
                </div>
                <div className="flex-initial md:flex-grow mb-2 md:mb-0">
                  <FormikSelect
                    label="Cadeia"
                    name="id_chain"
                    options={[
                      { value: "", label: "Selecione", isDisabled: true },
                      ...(fetchedChains?.map((chain: any) => ({
                        label: chain.name,
                        value: chain.id,
                        isDisabled: false,
                      })) || []),
                    ]}
                    onChange={e => setSelectedChain(parseInt(e))}
                  />
                </div>
              </div>
            </form>
          )}
        </Formik>
        {error && (
          <Message severity="error" className="w-full mt-4">
            {error}
          </Message>
        )}
      </Dialog>
    </>
  );
}

export default ModalReportGeneral;