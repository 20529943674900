import { useEffect, useState } from "react"
import http from "../../../plugins/http"
import { Button } from "primereact/button"
import { InputText } from "primereact/inputtext"
import Loading from "../../../components/Loading"
import { useToast } from "../../../components/Toast"
import { Message } from "primereact/message"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { Dropdown } from "primereact/dropdown"
import { BreadCrumb } from "primereact/breadcrumb"
import { exportBlobToFile } from "../../../utils/save"
import { toMoney } from "../../../utils/money"
import { dateBR } from "../../../utils/data"

const SalesPage: React.FC = () => {
	const today = new Date()
	const prevMonth = new Date()
	prevMonth.setMonth(today.getMonth() - 1)
	const toast = useToast()
	const [sales, setSales] = useState([])
	const [loading, setLoading] = useState(false)
	const [productGroups, setProductGroups] = useState<any[]>([])
	const [productTypes, setProductTypes] = useState<any[]>([])
	const [productSubtypes, setProductSubtypes] = useState<any[]>([])
	const [filters, setFilters] = useState({
		dateInitial: prevMonth.toISOString().split("T")[0],
		dateFinal: new Date().toISOString().split("T")[0],
		page: 1,
		perPage: 100,
		product_group: null,
		product_type: null,
		product_subtype: null,
		status: null,
		detail: true,
	})

	const fetchSales = async (filter: any) => {
		try {
			setLoading(true)
			console.log(filter)
			const response = await http.post("v1/reports/sales", filter)
			if (response.status === 200) {
				setSales(response.data)
			}
		} catch (error) {
			console.error(error)
		} finally {
			setLoading(false)
		}
	}

	async function fetchProductGroups() {
		try {
			setLoading(true)
			const response = await http.get("v1/product-groups?all=1")
			if (response.status === 200) {
				setProductGroups([{ id: null, name: "Todos" }, ...response.data])
			}
			return []
		} catch (error) {
			console.log(error)
		} finally {
			setLoading(false)
		}
	}

	async function fetchProductTypes() {
		try {
			setLoading(true)
			const responseTypes = await http.get("v1/product-types?all=1")
			if (responseTypes.status === 200) {
				return responseTypes.data
			}
			return []
		} catch (error) {
			console.log(error)
			return []
		} finally {
			setLoading(false)
		}
	}

	async function fetchProductSubtypes() {
		try {
			setLoading(true)
			const responseSubtypes = await http.get("v1/product-subtypes?all=1")
			if (responseSubtypes.status === 200) {
				return responseSubtypes.data
			}
			return []
		} catch (error) {
			console.log(error)
			return []
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		fetchSales(filters)
		fetchProductGroups()
	}, [])

	const handleFilterChange = (e: any) => {
		const { name, value } = e.target
		if (name === "product_group") {
			fetchProductTypes().then((response) => {
				setProductTypes([{ id: null, name: "Todos" }, ...response])
				setProductSubtypes([])
			})
		}
		if (name === "product_type") {
			fetchProductSubtypes().then((response) => {
				setProductSubtypes([{ id: null, name: "Todos" }, ...response])
			})
		}
		fetchSales({ ...filters, [name]: value })
		setFilters((prevFilters) => ({ ...prevFilters, [name]: value }))
	}

	const generateReport = async () => {
		http
			.post("v1/reports/export-sales", filters, {
				responseType: "blob",
			})
			.then((response) => {
				exportBlobToFile(response.data, "relatorio-vendas.xlsx")
				toast.current?.show({
					severity: "success",
					summary: "Sucesso",
					detail: "Relatório gerado com sucesso",
				})
			})
			.catch((error) => {
				console.log(error)
				toast.current?.show({
					severity: "error",
					summary: "Erro",
					detail: "Erro ao gerar relatório",
				})
			})
	}

	const userSituation = [
		{ public_name: "Todos", value: null },
		{ public_name: "Ativados", value: 1 },
		{ public_name: "Desativados", value: 0 },
	]

	const statusTemplate = (rowData: any) => {
		return (
			<div className='text-center'>
				{rowData?.status === 1 ? (
					<p className='text-[#28A745]'>Ativo</p>
				) : (
					<p className='text-orange-500'>Aguardando envio</p>
				)}
			</div>
		)
	}

	const dateTemplate = (field: string) => {
		return (
			<div className='text-center'>{new Date(field).toLocaleDateString()}</div>
		)
	}

	const items = [
		{ label: "Relatórios", url: "/reports" },
		{ label: "Relatório de Vendas" },
	]
	const home = { icon: "pi pi-home", url: "/home" }

	return (
		<div className='w-full p-10'>
			<BreadCrumb model={items} home={home} className='mb-4' />
			<div className='flex flex-wrap'>
				<div className='w-4/12 xl:w-3/12 flex flex-col'>
					<label className='text-md font-bold mb-1'>Cadeia</label>

					<Dropdown
						name='product_group'
						value={filters.product_group}
						options={productGroups}
						onChange={handleFilterChange}
						optionLabel='name'
						className='!w-full'
						placeholder='Cadeia'
					/>
				</div>
				<div className='w-4/12 xl:w-3/12 flex flex-col pl-2'>
					<label className='text-md font-bold mb-1'>Tipo</label>
					<Dropdown
						name='product_type'
						value={filters.product_type}
						options={productTypes}
						onChange={handleFilterChange}
						optionLabel='name'
						className='!w-full'
						placeholder='Tipo'
					/>
				</div>
				<div className='w-4/12 xl:w-3/12 flex flex-col pl-2'>
					<label className='text-md font-bold mb-1'>Subtipo</label>
					<Dropdown
						name='product_subtype'
						value={filters.product_subtype}
						options={productSubtypes}
						onChange={handleFilterChange}
						optionLabel='name'
						className='!w-full'
						placeholder='Subtipo'
					/>
				</div>
				<div className='w-4/12 xl:w-3/12 flex flex-col pl-2'>
					<label className='text-md font-bold mb-1'>Situação</label>
					<Dropdown
						name='status'
						value={filters.status}
						options={userSituation}
						onChange={handleFilterChange}
						optionLabel='public_name'
						placeholder='Situação'
						className='!w-full'
					/>
				</div>
				<div className='w-6/12 flex'>
					<div className='w-1/2 flex flex-col pr-2'>
						<label className='text-md font-bold mb-1'>Data Inicial</label>
						<InputText
							value={filters.dateInitial}
							name='dateInitial'
							onChange={handleFilterChange}
							type='date'
						/>
					</div>
					<div className='w-1/2 flex flex-col pl-2'>
						<label className='text-md font-bold mb-1'>Data Final</label>
						<InputText
							value={filters.dateFinal}
							name='dateFinal'
							onChange={handleFilterChange}
							type='date'
						/>
					</div>
					<div className='flex flex-col pl-2'>
						<label className='text-md font-bold mb-1'>&nbsp;</label>
						<Button
							label='Filtrar'
							disabled={loading}
							onClick={() => fetchSales(filters)}
							severity='success'
						/>
					</div>
					<div className='flex flex-col pl-2'>
						<label className='text-md font-bold mb-1'>&nbsp;</label>
						<Button
							label='Exportar'
							disabled={loading}
							onClick={generateReport}
							severity='success'
						/>
					</div>
				</div>
			</div>

			{loading && (
				<div className='w-full h-36 grid place-content-center p-20'>
					<Loading color='#212529' size='w-10' />
				</div>
			)}
			{!loading && sales.length === 0 && (
				<div className='w-full h-36 grid place-content-center p-20'>
					<p>Nenhuma venda encontrada com os filtros aplicados</p>
				</div>
			)}
			{!loading && sales.length > 0 && (
				<div className='h-full w-full mt-10 overflow-auto'>
					<Message
						severity='info'
						className='w-full'
						text={`Total de registros: ${sales.length}`}
					/>
					<DataTable
						showGridlines
						value={sales}
						className='w-full mt-4 font-roboto text-black'
					>
						<Column
							body={(row) => <span>{row?.user?.name}</span>}
							header='Comprador'
						/>
						<Column
							body={(row) => <span>{row?.items[0].product?.user?.name}</span>}
							header='Vendedor'
						/>
						<Column
							body={(row) => (
								<div className='flex flex-col space-y-2'>
									<p>
										<b>Cadeia:</b>{" "}
										<span>{row?.items[0].product?.product_group?.name}</span>
									</p>

									<p>
										<b>Tipo:</b>{" "}
										<span>{row?.items[0].product?.product_type?.name}</span>
									</p>

									<p>
										<b>Subtipo:</b>{" "}
										<span>{row?.items[0].product?.product_subtype?.name}</span>
									</p>
									<p>
										<b>Quantidade:</b>{" "}
										<span>
											{row?.items[0].quantity}
											{row?.items[0].unit_of_mensure}
										</span>
									</p>
									<p>
										<b>Valor do produto:</b>{" "}
										<span>{toMoney(row?.total_without_frete)}</span>
									</p>
									<p>
										<Button
											size='small'
											onClick={() => {
												window.open(
													`https://marketplace.bioconex.com.br/#/marketplace/produto?id=${row?.items[0].product.id}&key=${row?.items[0].product?.url}`,
													"_blank",
												)
											}}
										>
											Ver no marketplace
										</Button>
									</p>
								</div>
							)}
							header='Produto'
						/>

						<Column
							body={(row) => (
								<div className='flex flex-col space-y-2'>
									<p>
										<b>Cidade:</b>
										<span>
											{" "}
											{row?.address?.city?.name}/
											{row?.address?.state?.short_name}
										</span>
									</p>
									<p>
										<b>Logradouro:</b>
										<span> {row?.address?.address}</span>
									</p>
									<p>
										<b>CEP:</b>
										<span> {row?.address?.cep}</span>
									</p>
									<p>
										<b>Transportadora:</b>
										<span>
											<img
												width={100}
												src={row?.freight?.company?.picture}
												alt=''
											/>
										</span>
									</p>
									<p>
										<b>Código de rastreio:</b>
										<span> Não informado</span>
									</p>
									<p>
										<b>Valor do frete:</b>
										<span> {toMoney(row?.freight?.price)}</span>
									</p>
								</div>
							)}
							header='Entrega'
						/>
						<Column body={statusTemplate} header='Situação' />
						<Column
							body={(row) => (
								<div className='flex flex-col space-y-2'>
									<p>
										<b>Valor:</b>
										<span>
											{toMoney(row?.payment?.mp_data?.transaction_amount)}
										</span>
									</p>
									<p>
										<b>Data do pagamento:</b>
										<span>{dateBR(row?.payment?.mp_data?.date_approved)}</span>
									</p>
								</div>
							)}
							header='Pagamento'
						/>
						<Column
							body={(row) => dateTemplate(row.created_at)}
							header='Data da venda'
						/>
					</DataTable>
				</div>
			)}
		</div>
	)
}

export default SalesPage
