import { cnpj } from "cpf-cnpj-validator";
import * as Yup from "yup";

const Schema = Yup.object().shape({
  social_reason: Yup.string().required("A Razão social é obrigatória"),
  fantasy_name: Yup.string().required("O nome fantasia é obrigatório"),
  id_state: Yup.number().required("Estado é obrigatório"),
  id_city: Yup.number().required("Cidade é obrigatório"),
  cep: Yup.string().required("CEP é obrigatório").length(9, "CEP inválido"),
  address: Yup.string().required("Endereço é obrigatório"),
  neighborhood: Yup.string().required("Bairro é obrigatório"),
  number: Yup.string().required("Número é obrigatório"),
  responsible: Yup.string().required("Responsável é obrigatório"),
  role: Yup.string().required("O cargo é obrigatório"),
  contact_1: Yup.string().required("Contato 1 é obrigatório"),
  contact_2: Yup.string().required("Contato 2 é obrigatório"),
  email: Yup.string()
    .email("Digite um email correto")
    .required("Email obrigatório")
    .max(60, "Deve ter no maximo 60 caracteres"),

  cnpj: Yup.string()
    .required("CNPJ obrigatório")
    .test("cnpj", "CNPJ inválido", (value: string) => cnpj.isValid(value)),
  obs: Yup.string().required("Observação é obrigatório"),
  measure: Yup.string().required("Medida é obrigatório"),
});

export default Schema;
