import { useEffect, useState } from "react";
import { Button } from "primereact/button";
import PageHeader from "../../../components/PageHeader";
import { Block, CheckCircle, Plus } from "@styled-icons/boxicons-regular";
import http from "../../../plugins/http";
import Loading from "../../../components/Loading";
import ModalEditUser from "../../Users/Edit/Modal";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikInput from "../../../components/FormikInput";
import FormikSelect from "../../../components/FormikSelect";
import { fetchTypeOfChestnut } from "../../../api/typeOfChestnut";
import { Link } from "react-router-dom";

const TableRowActions = ({ toggle, user, update }: { toggle: (user: any) => void; user: any; update: () => void }) => {
  return (
    <td scope="row" className="flex space-x-0">
      <div className="col mr-[-30px]">
        {" "}
        {user.status === 1 ? (
          <Block onClick={() => toggle(user)} size={25} color="#F91B1B" className="cursor-pointer" />
        ) : (
          <CheckCircle onClick={() => toggle(user)} size={26} color="#28A745" className="cursor-pointer" />
        )}
      </div>
      <div className="col mx-[-1]">
        <ModalEditUser user={user} className="w-1/2" onEdit={() => update()} />
      </div>
      <div className="col">Visualizar</div>
    </td>
  );
};

const Schema = Yup.object().shape({
  email: Yup.string().email("Digite um email correto").required("Email obrigatório"),
  password: Yup.string().required("Senha obrigatória").min(6, "Deve ter no mínimo 6 caracteres"),
  name: Yup.string().required("Nome obrigatório").min(3, "Deve ter no mínimo 3 caracteres"),
  phone: Yup.string().required("Celular obrigatório"),
  // id_state: Yup.string().required("Estado obrigatório"),
  // id_city: Yup.string().required("Cidade obrigatória"),
  confirmPassword: Yup.string()
    .required("Confirmação de senha obrigatória")
    .equals([Yup.ref("password")], "As senhas devem ser iguais"),
});

function DemandsEditPage() {
  const [users, setUsers] = useState<any[]>([]);
  const [types, setTypes] = useState<any[]>([]);
  const [subtypes, setSubTypes] = useState<any[]>([]);
  const [states, setStates] = useState<any[]>([]);
  const [meta, setMeta] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);

  const initialValues = {
    name: "",
    cpf_or_cnpj: "",
    email: "",
    password: "",
    confirmPassword: "",
    phone: "",
    id_state: "",
    id_city: "",
    id_role: "",
  };

  async function fetchUsers({
    page = 1,
    perPage = 20,
    search,
  }: { page?: number; perPage?: number; search?: string } = {}) {
    try {
      setLoading(true);
      const searchData = search ? `&search=${search}` : "";
      const response = await http.get(`v1/users?all=1&perPage=${perPage}&page=${page}${searchData}`);
      if (response.status !== 200) {
        return;
      }
      setUsers(response.data.data);
      setMeta(response.data.meta);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  function searchUsers(val: string) {
    console.log(val);
    fetchUsers({ search: val });
  }

  async function toggleUserStatus(user: any) {
    try {
      setLoading(true);
      const response = await http.put(`v1/managers/users/${user.id}`, {
        status: user.status === 1 ? 0 : 1,
      });
      if (response.status !== 200) {
        console.log("Error toggling user status");
        return;
      }
      fetchUsers();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  /* Quando o usuário escolhe um tipo de castanha, ele carrega os subitpos */
  async function typeSelected(id: number) {
    setLoading(true);
    initialValues.id_city = "";
    /*  fetchCities({ id_state: id }).then(res => {
      setCities(res.data)
      setLoading(false)
    }) */
  }

  useEffect(() => {
    setLoading(true);
    fetchTypeOfChestnut().then(res => {
      setTypes(res.data);
      setLoading(false);
    });

    fetchUsers();
  }, []);

  return (
    <div className="w-full page-height px-10 overflow-scroll">
      <PageHeader className="mt-[100px] grid grid-cols-2">
        <div className="col w-[500px]">Edição da demanda:</div>
        <div className="text-end font-normal text-base ">12/12/12</div>
      </PageHeader>
      {loading && (
        <div className="w-full h-36 grid place-content-center p-20">
          <Loading color="#212529" size="w-10" />
        </div>
      )}

      <Formik
        initialValues={initialValues}
        validationSchema={Schema}
        onSubmit={(values, actions) => {
          console.log({ values, actions });
          //register(values);
        }}
        key={"form-register-user"}
      >
        <Form className="grid grid-cols-2 flex-wrap">
          <div className="col w-[1000px]">
            <div className="row w-[1040px]">
              <FormikInput className="w-full md:w-1/2  " name="name" label="Título de demanda:" placeholder="" />
            </div>
            <div className="row ml-[0px]">
              <label className="ml-[-10px]">Breve descrição</label>
              <textarea className="w-[495px] ml-[1px] p-2" cols={1} rows={5}></textarea>
            </div>
            <div className="row flex-col w-[495px] rounded-lg mt-3  ml-[1px] row border border-[#CED4DA]">
              <label className="mt-3 font-bold">Período do anúncio:</label>
              <div className="flex flex-row">
                <div className="mr-[150px]">
                  <label>Início:</label>
                </div>
                <div>
                  <label>Término:</label>
                </div>
              </div>
              <div className="flex flex-row">
                <div className="mr-[20px] cursor-pointer ">
                  <input className="cursor-pointer" type="date"></input>
                </div>
                à
                <div className="ml-[30px] mb-3">
                  <input type="date"></input>
                </div>
              </div>
            </div>
            <div className="w-[495px]">
              <FormikSelect
                label="Tipo de castanha"
                name="id"
                className="w-full mt-3"
                options={types?.map(type => ({ label: type.name, value: type.id }))}
                onChange={e => typeSelected(parseInt(e))}
              />
            </div>
            <div className="w-[495px]">
              <FormikSelect
                label="Subtipo de castanha"
                name="id"
                className="w-full mt-3"
                options={types?.map(type => ({ label: type.name, value: type.id }))}
                onChange={e => typeSelected(parseInt(e))}
              />
            </div>
          </div>
          {/* Segunda Colunda */}
          <div className="col">
            <div className="">
              <label>Previsão</label>
              <div className=" rounded-lg   ml-[1px] row border border-[#CED4DA]">
                {/* PREVISÃO  */}

                <div className="months mt-3">
                  <div className="row">
                    <div className="col-2">
                      <label>Mês</label>
                    </div>
                    <div className="col-3">
                      <label>Quantidade</label>
                    </div>
                    <div className="col-2">
                      <label>Unidade</label>
                    </div>
                    <div className="col-3">
                      <label>Valor/Mês</label>
                    </div>
                  </div>
                  <div className="row mb-2">
                    {/* Mês */}
                    <div className="col-12 col-md-2">
                      <input type="text" className="form-control" />
                    </div>
                    {/*  <!-- Quantidade --> */}
                    <div className="col-12 col-md-3 mt-2 mt-md-0">
                      <input placeholder="00000" className="form-control" type="number" min={1} />
                    </div>
                    {/*  <!--Medida--> */}
                    <div className="col-12 col-md-2">
                      <select className="form-select" id="unit">
                        <option>Kg</option>
                      </select>
                    </div>
                    <div className="col-12 col-md-3 mt-2 mt-md-0">
                      <input placeholder="00000" className="form-control" type="number" min={1} />
                    </div>
                    <div className="col-12 col-md-2 mt-2 mt-md-0" style={{ paddingRight: "0px" }}>
                      <p className=" text-[#479632] mt-1">excluir</p>
                    </div>
                  </div>
                  {/* Mensagem de erro */}
                  {/* <div className="my-2 bg-warning w-100 h6 px-5 py-4 rounded-2">
                    <b>Error:</b> Mensagem de erro
                  </div> */}
                  <div className="w-100 mb-3 text-center d-flex justify-content-center">
                    <p className="text-[#479632] mt-3">
                      Adicionar mais período
                      <Plus onClick={() => { }} size={25} color="#1D560F" className="cursor-pointer" />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" w-full flex justify-end p-4 gap-x-5 col-span-2">
            <div>
              <Link to={"/demands"}>
                <Button
                  disabled={loading}
                  className="uppercase text-[15px] font-nunito h-[38px] font-bold text-[#146600] hover:text-white  bg-white hover:!bg-[#85D46C] border-1 hover:border-0"
                >
                  Cancelar
                </Button>
              </Link>
            </div>
            <div>
              <Button
                type="submit"
                color="success"
                // disabled={loading}
                className="uppercase text-[15px] font-nunito h-[38px] font-bold  hover:bg-[#479632] bg-green-500 border-0 hover:border-0  flex flex-wrap gap-x-4"
              >
                {loading && <Loading />}
                CADASTRAR
              </Button>
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  );
}

export default DemandsEditPage;
