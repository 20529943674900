import http from "../plugins/http";

export const fetchSubtypes = async ({
  id_product_type,
  query = "&all=1",
}: {
  id_product_type: number;
  query?: string;
}) => {
  const data = await http.get(`/v1/product-subtypes?id_product_type=${id_product_type}&${query}`);
  return data;
};
