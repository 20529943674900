import { useState, memo, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import Loading from "../../../components/Loading";

const RegistrationVolumesTable = memo(function ProfileManager(args: any) {
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedDatas, setSelectedDatas] = useState<any[]>([]);
  const [selections, setSelections] = useState<Record<string, boolean>>({});
  const [datas, setDatas] = useState<any[]>([
    {
      id: 1,
      name: "Ana Clara Souza Matos",
      cpf: "813962652-49",
      vol_product: "cadastrado",
      val_product: "cadastrado",
      val_type: "Compra",
      lote: "Comprado",
      perfil: "Comprador",
      status: "Ativo",
    },
    {
      id: 2,
      name: "Ana Clara Silva",
      cpf: "757584848-00",
      vol_product: "anunciado",
      val_product: "anunciado",
      val_type: "Venda",
      lote: "Produzido",
      perfil: "Produtor",
      status: "Ativo",
    },
    {
      id: 3,
      name: "Ana Clara Souza Matos",
      cpf: "813962652-49",
      vol_product: "cadastrado",
      val_product: "cadastrado",
      val_type: "Venda",
      lote: "Comprado",
      perfil: "Comprador/Vendedor",
      status: "Ativo",
    },
    {
      id: 4,
      name: "Bruno Castro Vieira",
      cpf: "813962652-49",
      vol_product: "cadastrado",
      val_product: "cadastrado",
      val_type: "Compra",
      lote: "Produzido",
      perfil: "Produtor",
      status: "Inativo",
    },
    {
      id: 5,
      name: "Julia Martins Pereira",
      cpf: "813962652-49",
      vol_product: "cadastrado",
      val_product: "cadastrado",
      val_type: "Compra",
      lote: "Produzido",
      perfil: "Vendedor",
      status: "Ativo",
    },
    {
      id: 6,
      name: "João Souza Silva",
      cpf: "813962652-49",
      vol_product: "anunciado",
      val_product: "anunciado",
      val_type: "Venda",
      lote: "Comprado",
      perfil: "Comprador/Vendedor",
      status: "Ativo",
    },
  ]);

  const toggleItemSelection = (id: any) => {
    setSelections(prevState => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const details = () => {
    console.log("details");
  };

  const toggleAll = () => {
    const allSelected = Object.keys(selections).length === datas.length;
    if (allSelected) {
      setSelections({});
    } else {
      const newSelections: { [key: string]: boolean } = {};
      datas.forEach(data => {
        newSelections[data.id] = true;
      });
      setSelections(newSelections);
    }
  };

  useEffect(() => {
    const selected = datas.filter(data => selections[data.id]);
    setSelectedDatas(selected);
  }, [selections, datas]);

  return (
    <div className="h-screen bg-[#F2F2F2]">
      {loading && (
        <div className="w-full h-36 grid place-content-center p-20">
          <Loading color="#212529" size="w-10" />
        </div>
      )}

      {!loading && datas?.length === 0 && (
        <div className="w-full h-36 grid place-content-center p-20">
          <p>Nenhum usuário encontrado</p>
        </div>
      )}
      {!loading && datas?.length > 0 && (
        <div className="h-4/6">
          <DataTable value={datas} className="font-roboto">
            <Column
              header={
                <Checkbox
                  onChange={toggleAll}
                  checked={Object.keys(selections).length === datas.length}
                />
              }
              body={rowData => (
                <Checkbox
                  onChange={() => toggleItemSelection(rowData.id)}
                  checked={!!selections[rowData.id]}
                />
              )}
              className="text-center"
            />
            <Column field="name" header="Nome" className="text-left" />
            <Column field="cpf" header="CPF/CNPJ" className="text-center" />
            <Column field="vol_product" header="Volumes de produtos" className="text-center" />
            <Column field="val_product" header="Valores de produtos" className="text-center" />
            <Column field="val_type" header="Tipos de valores" className="text-center" />
            <Column field="lote" header="Lote" className="text-center" />
            <Column field="perfil" header="Perfil" className="text-center" />
            <Column
              field="status"
              header="Status"
              body={rowData =>
                rowData.status === "Ativo" ? (
                  <span className="text-[#28A745]">Ativo</span>
                ) : (
                  <span className="text-red-500">Inativo</span>
                )
              }
              className="text-center"
            />
            <Column
              header="Detalhes"
              body={() => (
                <Button
                  className="text-[16px] font-nunito h-[38px] font-bold  hover:bg-[#90A4B1] bg-white border-0 hover:border-0"
                  onClick={details}
                >
                  <span className="text-[#506471]">Visualizar</span>
                </Button>
              )}
              className="text-center"
            />
          </DataTable>
        </div>
      )}
    </div>
  );
});

export default RegistrationVolumesTable;
