import { Button } from "primereact/button";
import { useEffect, useState } from "react";
import FormikInput from "../../../components/FormikInput";
import Loading from "../../../components/Loading";
import http from "../../../plugins/http";
import { Form, useFormik } from 'formik';
import { Dialog } from "primereact/dialog";
import { Message } from "primereact/message";


function ModalFinancialDetails({
  filterSelectedUsers,
  hasSelected,
  datas,
  args,
  onOpen,
  cleanSelections,
}: {
  filterSelectedUsers: any;
  hasSelected: any;
  datas: any;
  args?: any;
  onOpen: any;
  cleanSelections: any;
}) {
  const formik = useFormik({
    initialValues: {
      data_inicial: "",
      data_final: "",
    },
    onSubmit: values => {

    },
  });

  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [selectedData, setSelectedData] = useState<any[]>([]);

  const toggle = () => {
    setModal(prevModal => !prevModal);
    if (!modal) {
      // Resets the form to its initial state.
      // This is used when the form is submitted successfully.
      formik.resetForm();

      formik.resetForm();
    }
  };

  const showReport = async (values: any) => {
    try {
      setLoading(true);
      const response = await http.get(
        `v1/percentages-and-probabilities/report/seller?start=${values.data_inicial}&end=${values.data_final}&users=${selectedData.map((data: any) => data.id).join(",")}`,
        {
          responseType: 'blob'
        }
      );
      if (response.status === 403) {
        setError("Você não tem permissão para visualizar este relatório");
      }
      if (response.status === 200) {
        const href = URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'report.pdf');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (modal) {
      onOpen();
    } else {
      cleanSelections({});
    }
  }, [modal]);
  useEffect(() => {
    setSelectedData(datas);
  }, [datas]);
  return (
    <>
      <Button
        onClick={toggle}
        className="w-full md:w-auto flex-shrink-0 align-middle uppercase text-[15px] font-nunito h-[38px] font-bold hover:bg-[#479632] bg-green-500 border-0 hover:border-0"
        color="success"
        disabled={!hasSelected()}
      >
        Visualizar
      </Button>
      <Dialog visible={modal} header="Detalhes FInanceiros individual:" onHide={toggle} centered size="lg" {...args}>
        <Form className="flex flex-wrap space-y-1">
          <div className="flex flex-col md:flex-row items-center w-full justify-between p-4 space-y-2 md:space-y-0 md:space-x-1 overflow-auto mr-4">
            <div className="flex-initial md:flex-grow mb-2 md:mb-0">
              <FormikInput onChanged={formik.handleChange} value={formik.values.data_inicial} className="rounded-md shadow-sm" name="data_inicial" label="Data Inicial" type="date" />
            </div>
            <div className="flex-initial md:flex-grow mb-2 md:mb-0">
              <FormikInput onChanged={formik.handleChange} value={formik.values.data_final} className="rounded-md shadow-sm mx-1" name="data_final" label="Data Final" type="date" />
            </div>
          </div>

          {!loading && datas?.length === 0 && (
            <div className="w-full h-36 grid place-content-center p-20">
              <p>Nenhum usuário encontrado</p>
            </div>
          )}
          {!loading && selectedData?.length > 0 && (
            <div className="relative overflow-x-auto overflow-y-auto max-h-[500px]">
              {!loading && selectedData?.length > 0 && (
                <div className="min-w-full">
                  <table className="min-w-full font-roboto divide-y divide-gray-200">
                    <thead className="bg-white sticky top-0">
                      <tr className="font-bold text-[18px] text-[#212529]">
                        <th className="px-8 py-3 text-left w-1/4">Nome</th>
                        <th className="px-6 py-3 text-center w-1/6">CPF/CNPJ</th>
                        <th className="px-6 py-3 text-center w-1/6">Email</th>
                        <th className="px-6 py-3 text-center w-1/6">Celular</th>
                        <th className="px-6 py-3 text-center w-1/6">Estado</th>
                        <th className="px-6 py-3 text-center w-1/6">Cidade</th>
                        <th className="px-6 py-3 text-center w-1/12">Perfil</th>
                        <th className="px-6 py-3 text-center w-1/12">Status</th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {selectedData.map(data => (
                        <tr key={data.id}>
                          <td className="px-8 py-4 whitespace-nowrap truncate">{data.name}</td>
                          <td className="px-6 py-4 text-center whitespace-nowrap">{data.cpf_or_cnpj}</td>
                          <td className="px-6 py-4 text-center whitespace-nowrap truncate">{data.email}</td>
                          <td className="px-6 py-4 text-center whitespace-nowrap">{data.phone}</td>
                          <td className="px-6 py-4 text-center whitespace-nowrap">{data?.state?.name}</td>
                          <td className="px-6 py-4 text-center whitespace-nowrap">{data?.city?.name}</td>
                          <td className="px-6 py-4 text-center whitespace-nowrap">{data.perfil}</td>
                          <td className="px-6 py-4 text-center">
                            {data.status === 1 ? (
                              <span className="px-3 py-1 rounded-full text-center bg-green-100 text-green-700">
                                Ativo
                              </span>
                            ) : (
                              <span className="px-3 py-1 rounded-full text-center bg-red-100 text-red-500">
                                Desativado
                              </span>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          )}
          <div className="w-full flex justify-end p-4 gap-x-4">
            <Button
              onClick={toggle}
              disabled={loading}
              className="uppercase text-[15px] font-nunito h-[38px] font-bold text-[#146600] hover:text-white  bg-white hover:!bg-[#85D46C] border-1 hover:border-0"
            >
              Cancelar
            </Button>
            <Button
              onClick={() => showReport(formik.values)}
              color="success"
              disabled={loading}
              className="uppercase text-[15px] font-nunito h-[38px] font-bold  hover:bg-[#479632] bg-green-500 border-0 hover:border-0  flex flex-wrap gap-x-4"
            >
              {loading && <Loading />}
              Visualizar
            </Button>
          </div>
          {error !== "" && (
            <Message className="w-full mt-4" color="danger">
              {error}
            </Message>
          )}
        </Form>
      </Dialog>
    </>
  );
}

export default ModalFinancialDetails;
