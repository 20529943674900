import { cnpj } from "cpf-cnpj-validator";
import * as Yup from "yup";
const Schema = Yup.object().shape({
  name_company: Yup.string().required("Nome da empresa é obrigatório"),
  id_state: Yup.number().required("Estado é obrigatório"),
  id_city: Yup.number().required("Cidade é obrigatório"),
  email: Yup.string()
    .email("Digite um email correto")
    .required("Email obrigatório")
    .max(60, "Deve ter no maximo 60 caracteres"),
  cnpj_company: Yup.string()
    .required("CNPJ é obrigatório")
    .test("cnpj_company", "CNPJ é inválido", (value: string) => cnpj.isValid(value)),
  phone: Yup.string().min(11, "deve ter pelo menos 11 digitos").required("Telefone é obrigatório"),
  mobile_phone: Yup.string().required("Celular é obrigatório"),
  inscricao_estadual: Yup.string(),
  spreadsheet_url: Yup.string(),
  nfe_percentage: Yup.number().required("Percentual de NFE é obrigatório").min(0).max(100, "A porcentagem deve ser menor ou igual que 100%"),
  max_weight: Yup.number(),
  id_finance_type: Yup.string().required("Tipo é obrigatório"),
  bank_name: Yup.string(),
  bank_agency: Yup.string(),
  bank_account: Yup.string(),
  token: Yup.string(),

});

export default Schema;
