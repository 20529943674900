import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import ItemModel from "./Item-model";
import { FilterCircleXmark } from "@styled-icons/fa-solid";
import { Filter } from "@styled-icons/foundation";
import { fetchModels } from "../../api/models";
import { InputText } from "primereact/inputtext";

const ModelPage: React.FC = () => {
  const [open, setOpen] = useState<any>({});
  const [models, setModels] = useState<any[]>([]);

  async function getModels() {
    const response = await fetchModels();
    const keys = Object.keys(response.data);
    const data: any[] = [];
    for (let key of keys) {
      data.push({ name: key, data: response.data[key] });
    }
    setModels(data);
  }

  function filterData(e: any) {
    const value = e.target.value;
    if (value === "") return getModels();
    const filtered = models.filter(model => model.name.toLowerCase().includes(value.toLowerCase()));
    setModels(filtered);
  }

  useEffect(() => {
    getModels();
  }, []);

  return (
    <div className="h-full 2xl:page-height sm:flex relative">
      <div className="w-full min-w-[300px] sm:w-[40%] lg:w-2/6 xl:w-1/4">
        <div className="select-sidebar-menu overflow-y-auto ">
          <div className="h-8 flex">
            <InputText className="border border-0 rounded-0 w-4/6 max-w-xs inline" onChange={filterData}></InputText>
            <div className="bg-[#205F16] rounded-0 mr-2 w-8 flex justify-center items-center">
              <Filter className="w-6 h-6 text-white"></Filter>
            </div>
            <div className="bg-[#33748B] rounded-0 w-8 flex justify-center items-center">
              <FilterCircleXmark className="w-5 h-5 text-white"></FilterCircleXmark>
            </div>
          </div>
          <div className="h-[84%] 2xl:h-[89%] overflow-auto absolute bottom-0 w-4/5">
            <section className="h-full">
              <div className="h-full">
                {models.length > 0 ? (
                  models?.map(model => (
                    <Button
                      key={`btn-${model.name}`}
                      className="btn-select-sidebar-menu"
                      onClick={() => (open.name === model.name ? setOpen("") : setOpen(model))}
                      disabled={open.name !== model.name}
                    >
                      {model.name}
                    </Button>
                  ))
                ) : (
                  <div className="h-full flex justify-center items-center overflow-y-auto">
                    <span className="text-white text-2xl">Nenhum modelo cadastrado</span>
                  </div>
                )}
              </div>
            </section>
          </div>
        </div>
      </div>
      <div className="h-full bg-[#F2F2F2]" style={{ margin: "0 auto" }}>
        {open?.name && <ItemModel model={open}></ItemModel>}
      </div>
    </div>
  );
};

export default ModelPage;
