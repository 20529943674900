import axios from "axios"

console.log(process.env.REACT_APP_API_URL)

const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  validateStatus: () => true,
})

http.interceptors.request.use(config => {
  const token = localStorage.getItem("token")
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})

http.interceptors.response.use(
  response => {
    if (response?.status === 401) {
      localStorage.removeItem("token")
      localStorage.removeItem("user")
      window.location.href = "/?sessionExpired=true"
    }
    return response
  },
  error => {
    console.log(error)
    if (error.response?.status === 401) {
      localStorage.removeItem("token")
      localStorage.removeItem("user")
      window.location.href = "/?sessionExpired=true"
    }
    return Promise.reject(JSON.stringify(error))
  }
)

export default http
