import React, { useState } from "react"
import { Menu } from "primereact/menu"
import "primeicons/primeicons.css"
import "./styles.css"
import { Footer } from "../Footer"
import { useNavigate } from "react-router-dom"

const Sidebar: React.FC = () => {
	const [selectedItem, setSelectedItem] = useState<string | null>(null)
	const navigate = useNavigate()

	const handleMenuItemClick = (label: string) => {
		setSelectedItem(label)
		navigate(label)
	}

	let items = [
		// {
		//   label: 'Models',
		//   icon: 'pi pi-sitemap',
		// },
		// {
		//   label: 'Endpoints',
		//   icon: 'pi pi-sitemap',
		// },
		{
			label: "Início",
			icon: "pi pi-home",
			command: () => handleMenuItemClick("home"),
			className: selectedItem === "home" ? "bg-green-200" : "",
		},
		{
			label: "Categorição de Dashboards",
			icon: "pi pi-chart-pie",
			command: () => handleMenuItemClick("data-categorization"),
			className: selectedItem === "data-categorization" ? "bg-green-200" : "",
		},
		{
			label: "Usuários",
			icon: "pi pi-users",
			command: () => handleMenuItemClick("users"),
			className: selectedItem === "users" ? "bg-green-200" : "",
		},
		{
			label: "Armazéns",
			icon: "pi pi-search-plus",
			command: () => handleMenuItemClick("warehouses"),
			className: selectedItem === "warehouses" ? "bg-green-200" : "",
		},
		{
			label: "Produtos",
			icon: "pi pi-tag",
			command: () => handleMenuItemClick("products"),
			className: selectedItem === "products" ? "bg-green-200" : "",
		},
		{
			label: "Transportadoras",
			icon: "pi pi-send",
			command: () => handleMenuItemClick("carriers"),
			className: selectedItem === "carriers" ? "bg-green-200" : "",
		},
		{
			label: "Cotações de Frete",
			icon: "pi pi-truck",
			command: () => handleMenuItemClick("freights"),
			className: selectedItem === "freights" ? "bg-green-200" : "",
		},
		{
			label: "Edição de dados gerais",
			icon: "pi pi-pen-to-square",
			command: () => handleMenuItemClick("parameterization"),
			className: selectedItem === "parameterization" ? "bg-green-200" : "",
		},
		{
			label: "Painel de relatórios",
			icon: "pi pi-print",
			command: () => handleMenuItemClick("reports"),
			className: selectedItem === "reports" ? "bg-green-200" : "",
		},
		{
			label: "Logs do sistema",
			icon: "pi pi-list",
			command: () => handleMenuItemClick("logs"),
			className: selectedItem === "logs" ? "bg-green-200" : "",
		},
		{
			label: "Sair",
			icon: "pi pi-sign-out",
			command: () => {
				localStorage.clear()
				window.location.href = "/login"
			},
		},
	]

	return (
		<div className='flex flex-col h-full w-16 sm:w-64 text-white bg-white'>
			<div className='w-full h-20 bg-green-500 flex flex-col items-center justify-center'>
				<p className='text-xl font-semibold'>BIOCONEX</p>
				<p className='text-primary font-normal'>Parametrização</p>
				<p className='font-normal text-sm'>v1.2.1</p>
			</div>
			<div className='flex-grow'>
				<Menu
					model={items}
					className='w-full bg-gray-200 border-0 shadow-none'
				/>
			</div>
			<div className='mt-auto'>
				<Footer />
			</div>
		</div>
	)
}

export default Sidebar
