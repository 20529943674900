import { Form, Formik, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import FormikInput from "../../../components/FormikInput";
import FormikSelect from "../../../components/FormikSelect";
import { fetchStates } from "../../../api/states";
import { fetchCities } from "../../../api/cities";
import http from "../../../plugins/http";
import Loading from "../../../components/Loading";
import FormikInputMask from "../../../components/FormikInputMask";
import { fetchRoles } from "../../../api/roles";
import Schema from "./validations";
import representativeSchema from "./representativeValidations";
import bancos from "../../../assets/json/bancos.json";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Message } from "primereact/message";

function ModalRegisterUser({ updated, args }: { updated?: any; args?: any }) {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [states, setStates] = useState<any[]>([]);
  const [cities, setCities] = useState<any[]>([]);
  const [roles, setRoles] = useState<any[]>([]);
  const [commision_percentage, setCommision] = useState<number>(0);
  const [role, setRole] = useState("");
  const [schema, setSchema] = useState<any>(Schema);
  const [isRepresentativeSelected, setIsRepresentativeSelected] = useState(false);

  function FormButtons() {
    const { submitForm } = useFormikContext();
    return (
      <div className="w-full flex justify-end p-4 gap-x-4">
        <Button
          onClick={toggle}
          disabled={loading}
          severity="danger"
        >
          Cancelar
        </Button>
        <Button
          type="submit"
          onClick={submitForm}
          disabled={loading}
          severity="success"
        >
          {loading && <Loading />}
          Incluir
        </Button>
      </div>
    );
  }

  const toggle = () => {
    setRole("");
    setIsRepresentativeSelected(false);
    setModal(!modal);
    console.log("toggle");
  };

  const handleCommisionPercentage = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = event.target;
    value = value.replace(/\D/g, "");
    if (parseInt(value) > 100) {
      value = "100";
    }
    setCommision(parseInt(value) || 0);
  };

  const initialValues = {
    name: "",
    cpf_or_cnpj: "",
    email: "",
    password: "",
    confirmPassword: "",
    phone: "",
    id_state: "",
    id_city: "",
    id_role: "",
    business_profile: "",
    id_area: "",
    commision_percentage: "",
    bank_name: "",
    bank_agency: "",
    bank_account: "",
    pix_key: "",
  };

  const perfilComercial = [
    { label: "Bebidas", value: "Bebidas" },
    { label: "Alimentos", value: "Alimentos" },
    { label: "Fármaco", value: "Fármaco" },
    { label: "Varejo", value: "Varejo" },
    { label: "Saúde", value: "Saúde" },
  ];

  async function stateSelected(id: number) {
    setLoading(true);
    initialValues.id_city = "";
    fetchCities({ id_state: id }).then(res => {
      setCities(res.data);
      setLoading(false);
    });
  }

  function showError(message: string) {
    setError(message);
    setTimeout(() => {
      setError("");
    }, 3000);
  }

  const loadRepresentativeForm = (e: any) => {
    const role = roles.find(x => x.value === e);
    setRole(role ? role.label : "");
    if (role.label === "Representante") {
      setSchema(representativeSchema);
      setIsRepresentativeSelected(role?.label === "Representante");
    } else {
      setSchema(Schema);
      setIsRepresentativeSelected(false);
    }
  };

  async function register(values: any) {
    try {
      setLoading(true);
      if (values.commision_percentage === "") values.commision_percentage = null;
      if (values.id_area === "") values.id_area = null;
      const response = await http.post("/v1/users", {
        ...values,
      });
      if (response.status === 200) {
        setRole("");
        toggle();
        if (updated) updated();
      } else if (response.data.hasOwnProperty("message")) {
        showError(response.data.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    setLoading(true);
    fetchStates().then(res => {
      setStates(res.data);
      setLoading(false);
    });
    fetchRoles().then(res => {
      setRoles(res.data.map((x: any) => ({ label: x.public_name, value: x.id })));
      setLoading(false);
    });
  }, []);

  return (
    <div className="inline-flex">
      <Button
        onClick={toggle}
        severity="success"
        size="small"
      >
        Novo cadastro
      </Button>

      <Dialog header="Cadastrar usuário" onHide={toggle} visible={modal} {...args}>
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={(values, actions) => {
            register(values);
          }}
          key={"form-register-user"}
        >
          <>
            <Form
              className="flex flex-wrap space-y-6 p-10"
              style={isRepresentativeSelected ? { height: "40rem", overflowY: "scroll" } : {}}
            >
              <FormikInput className="w-full" name="name" label="Nome completo" placeholder="Digite o nome" />
              <div className="flex w-full space-x-10">
                <FormikSelect
                  label="Tipo de usuário"
                  name="id_role"
                  className="w-1/2"
                  options={roles}
                  onChange={e => loadRepresentativeForm(e)}
                />
                <FormikInputMask
                  mask={["999.999.999-99", "99.999.999/9999-99"]}
                  className="w-1/2"
                  name="cpf_or_cnpj"
                  label="CPF/CNPJ"
                  placeholder="Digite o CPF/CNPJ"
                />
              </div>

              <div className="flex w-full space-x-10">
                <FormikInput className="w-1/2" name="email" label="Email" placeholder="Digite o email" />
                <FormikInputMask
                  className="w-1/2"
                  name="phone"
                  mask="(99) 99999-9999"
                  label="Celular"
                  placeholder="Digite o número de celular"
                />
              </div>

              <div className="flex w-full space-x-10">
                <FormikSelect
                  label="Estado"
                  name="id_state"
                  className="w-1/2"
                  options={states?.map(state => ({ label: state?.name, value: state?.id }))}
                  onChange={e => stateSelected(parseInt(e))}
                />
                <FormikSelect
                  label="Cidade"
                  name="id_city"
                  className="w-1/2"
                  options={cities.length > 0 ? cities?.map(city => ({ label: city.name, value: city.id })) : []}
                />
              </div>

              <div className="flex w-full space-x-10">
                <FormikInput
                  className="w-1/2"
                  name="password"
                  label="Senha de acesso"
                  placeholder="Digite a senha"
                  type="password"
                />
                <FormikInput
                  className="w-1/2"
                  name="confirmPassword"
                  label="Repita a senha de acesso"
                  placeholder="Digite a senha"
                  type="password"
                />
              </div>
              {role === "Representante" && (
                <>
                  <div className="flex w-full items-center space-x-50">
                    <div className="flex-grow h-px bg-gray-300"></div>
                  </div>
                  <div className="flex w-full items-start space-x-40">
                    <div className="flex items-center mr-5">
                      <h2 style={{ color: "#146600" }}>Dados Comerciais</h2>
                    </div>
                    <div className="flex items-center">
                      <h2 className="font-sans Roboto font-normal ml-12" style={{ color: "#146600" }}>
                        Dados Bancários
                      </h2>
                    </div>
                  </div>
                  <div className="flex w-full space-x-10">
                    <FormikSelect
                      label="Perfil Comercial"
                      name="business_profile"
                      className="w-1/2"
                      options={perfilComercial}
                    />
                    <FormikSelect
                      className="w-1/2"
                      name="bank_name"
                      label="Banco"
                      placeholder="Digite o nome do banco"
                      options={bancos}
                    />
                  </div>
                  <div className="flex w-full space-x-10">
                    <FormikSelect
                      label="Área/Região"
                      name="id_area"
                      className="w-1/2"
                      options={cities.length > 0 ? cities?.map(city => ({ label: city.name, value: city.id })) : []}
                    />
                    <FormikInput
                      className="w-1/2"
                      name="bank_agency"
                      label="Agência"
                      placeholder="Digite a agência"
                    />
                  </div>
                  <div className="flex w-full space-x-10">
                    <FormikInput
                      className="w-1/2"
                      type="number"
                      name="commision_percentage"
                      label="Percentual de Comissão (%)"
                      placeholder="Digite o percentual de comissão"
                      max={"100"}
                      value={commision_percentage?.toString()}
                      onChanged={handleCommisionPercentage}
                    />
                    <FormikInput className="w-1/2" name="bank_account" label="Conta" placeholder="Digite a conta" />
                  </div>
                  <div className="flex w-full space-x-10">
                    <div className="w-1/2"></div>
                    <FormikInput
                      className="w-1/2"
                      name="pix_key"
                      label="Chave pix"
                      placeholder="Digite a chave pix"
                    />
                  </div>
                </>
              )}
            </Form>
            <FormButtons />
            {error !== "" && (
              <Message className="w-full mt-4" color="danger">
                {error}
              </Message>
            )}
          </>
        </Formik>
      </Dialog>
    </div>
  );
}

export default ModalRegisterUser;
