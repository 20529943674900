import tw from "twin.macro";

export const HeaderContainer = tw.header`
  bg-[#146600]
`;
export const Content = tw.div`
  container
  mx-auto
  h-[56px]
`;
export const BtnLogout = tw.button`
  bg-[#D9534F]
  hover:bg-[#D43F3A]
  border-0
  w-[90px]
  h-[48px]

  text-white
  font-bold
  text-base

  mt-1
  mr-1
`;
