export const fetchBanks = async () => {
  try {
    const response = await fetch('https://brasilapi.com.br/api/banks/v1');
    if (!response.ok) {
      throw new Error('Erro ao buscar os bancos');
    }
    const banks = await response.json();
    return banks;
  } catch (error) {
    console.error('Erro na requisição:', error);
    throw error;
  }
};