import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import ItemEndpoint from "./Item-endpoint";
import { FilterCircleXmark } from "@styled-icons/fa-solid";
import { Filter, ClipboardNotes } from "@styled-icons/foundation";
import { fetchEndpoints } from "../../api/endpoints";
import useAlert from "../../hooks/useAlert";
import { BtnTabOption } from "../../components/BtnTab";
import { PrettierCode } from "../../components/PrettierCode";
import { InputText } from "primereact/inputtext";

enum Tabs {
  MAPPING = "mapping",
  REQUEST = "request",
  RESPONSE = "response",
}

const EndpointPage: React.FC = () => {
  const [open, setOpen] = useState<any>({});
  const [endpoints, setEndpoints] = useState<any[]>([]);
  const [tab, setTab] = useState<Tabs>(Tabs.MAPPING);
  const { setAlert } = useAlert();

  async function getEndpoints() {
    const response = await fetchEndpoints();
    const keys = Object.keys(response.data);
    const data: any[] = [];
    for (let key of keys) {
      for (let endpoint of response.data[key]) {
        if (endpoint !== null) {
          data.push(endpoint);
        }
      }
    }
    setEndpoints(data);
  }

  function filterData(e: any) {
    const value = e.target.value;
    if (value === "") return getEndpoints();
    const filtered = endpoints.filter(model => model.description.toLowerCase().includes(value.toLowerCase()));
    setEndpoints(filtered);
  }

  const setActiveTab = (tab: Tabs) => {
    setTab(tab);
  };

  const changeModel = (endpoint: any) => {
    if (open.endpoint === endpoint.endpoint) {
      return;
    }
    setActiveTab(Tabs.MAPPING);
    setOpen(endpoint);
  };

  useEffect(() => {
    getEndpoints();
  }, []);

  return (
    <div className="h-full 2xl:page-height sm:flex relative">
      <div className="w-full min-w-[300px] sm:w-[40%] lg:w-2/6 xl:w-1/4">
        <div className="select-sidebar-menu overflow-y-auto ">
          <div className="h-8 flex justify-between">
            <div className="w-full flex">
              <InputText className="border-0 rounded-0 w-full inline" onChange={filterData}></InputText>
              <div className="bg-[#205F16] rounded-0 mr-2 w-8 flex justify-center items-center">
                <Filter className="w-6 h-6 text-white"></Filter>
              </div>
            </div>
            <div className="bg-[#33748B] rounded-0 w-8 flex justify-center items-center">
              <FilterCircleXmark className="w-5 h-5 text-white"></FilterCircleXmark>
            </div>
          </div>
          <div className="max-h-screen mt-6 bottom-0 w-ful">
            {endpoints.length > 0 ? (
              endpoints?.map(endpoint => (
                <Button
                  key={`btn-${endpoint.endpoint}`}
                  className="btn-select-sidebar-menu relative text-sm font-roboto py-2 w-full h-14"
                  onClick={() => changeModel(endpoint)}
                  disabled={open.endpoint !== endpoint.endpoint}
                >
                  <span
                    className="rounded-md bg-green-400 px-1 py-1 text-sm mr-2 font-roboto
                      "
                  >
                    {endpoint.method}
                  </span>
                  {endpoint.description}
                </Button>
              ))
            ) : (
              <div className="h-full flex justify-center items-center">
                <span className="text-white text-2xl">Nenhum modelo cadastrado</span>
              </div>
            )}
          </div>
        </div>
      </div>
      {open?.endpoint && (
        <div className=" bg-[#F2F2F2] w-full px-4 overflow-y-scroll pb-10" key={"card-info-" + open?.endpoint}>
          <div className="mt-4">
            <BtnTabOption active={tab === Tabs.MAPPING} onClick={() => setActiveTab(Tabs.MAPPING)}>
              Preenchimento
            </BtnTabOption>
            <BtnTabOption active={tab === Tabs.REQUEST} onClick={() => setActiveTab(Tabs.REQUEST)}>
              Solicitação
            </BtnTabOption>
            <BtnTabOption active={tab === Tabs.RESPONSE} onClick={() => setActiveTab(Tabs.RESPONSE)}>
              Resposta
            </BtnTabOption>
          </div>
          {tab == Tabs.MAPPING && (
            <div className="mt-3">
              <div className="page-width-sidebar-menu">
                <ItemEndpoint model={open}></ItemEndpoint>
              </div>
            </div>
          )}
          {tab == Tabs.REQUEST && (
            <div className="mt-3">
              <h1 className="my-2 font-roboto font-semibold">Endpoint</h1>
              <PrettierCode code={{ endpoint: open?.endpoint, method: open?.method }}></PrettierCode>
              <h1 className="my-2 font-roboto font-semibold">Query String</h1>
              <PrettierCode code={open?.queryStrings}></PrettierCode>
              <h1 className="my-2 font-roboto font-semibold">Header</h1>
              <PrettierCode code={open?.header}></PrettierCode>
              <h1 className="my-2 font-roboto font-semibold">Body</h1>
              <PrettierCode code={open?.body}></PrettierCode>
            </div>
          )}
          {tab == Tabs.RESPONSE && (
            <div className="mt-3">
              <PrettierCode code={open?.response}></PrettierCode>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default EndpointPage;
