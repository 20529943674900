import { Form, Formik, FormikProps, useFormikContext } from "formik";
import { useRef, useState } from "react";
import FormikInput from "../../../components/FormikInput";
import http from "../../../plugins/http";
import { Block, CheckCircle } from "@styled-icons/boxicons-regular";
import Loading from "../../../components/Loading";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Message } from "primereact/message";

function ModalEnableAndDisableCarrier({
  carrier,
  className = "",
  changeStatus,
}: {
  carrier: any;
  className?: string;
  changeStatus: (carrierId: any) => void;
}) {
  const [modal, setModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const toggle = () => {
    setModal(prevModal => !prevModal);
  };
  return (
    <div className="inline-flex">
      {carrier.status === 1 ? (
        <Block onClick={toggle} size={25} color="#F91B1B" className="cursor-pointer" />
      ) : (
        <CheckCircle onClick={toggle} size={26} color="#28A745" className="cursor-pointer" />
      )}

      <Dialog header="Tem certeza que deseja ativar/desativar a Transportadora?" visible={modal} onHide={toggle} className={className}>
        <>
          <div className="w-full flex justify-end p-4 gap-x-4">
            <Button onClick={toggle} disabled={loading} severity="danger" outlined>
              Cancelar
            </Button>
            <Button type="submit" severity="success" onClick={() => changeStatus(carrier)} loading={loading}>
              {carrier.status === 1 ? "Desativar" : "Ativar"}
            </Button>
          </div>
          {error !== "" && (
            <Message className="w-full mt-4" color="danger">
              {error}
            </Message>
          )}
        </>
      </Dialog>
    </div>
  );
}

export default ModalEnableAndDisableCarrier;
