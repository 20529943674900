import React from "react";
import { useState } from "react";
import ModalError from "../DataCategorization/ModalError/ModalError";
import ProfileManager from "./ProfileManager/ProfileManager";

const tipo_perfil = [
  { value: "", label: "Selecione", isDisabled: true },
  { value: 1, label: "Atravessador" },
  { value: 2, label: "Cooperativa" },
  { value: 3, label: "Comprador" },
  { value: 4, label: "Produtor" },
];

const operacao = [
  { value: "", label: "Selecione", isDisabled: true },
  { value: 1, label: "Compra" },
  { value: 2, label: "Vende" },
  { value: 3, label: "Compra e vende" },
];

const ordem_valores = [
  { value: "", label: "Selecione", isDisabled: true },
  { value: 1, label: "Maior para o menor" },
  { value: 2, label: "Menor para o maior" },
  { value: 3, label: "Alfabética crescente" },
  { value: 4, label: "Alfabética decrescente" },
];

const paginacao_ops = [
  { value: "", label: "Selecione", isDisabled: true },
  { value: 1, label: 10 },
  { value: 2, label: 25 },
  { value: 3, label: 50 },
];

const PercentagesAndProbabilities: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [checkBoxElements, setCheckBoxElements] = useState<any[]>([]);
  const handleCheckBoxes = () => {
    // retorna true se tem pelo menos um checkbox selecionado
    checkBoxElements.forEach((checkbox: any) => {
      if (checkbox.checked) {
        return true;
      }
    });
    return false;
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  return (
    <div className="p-10">
      <ModalError isOpen={isOpen} closeModal={closeModal} message=""></ModalError>
      <ProfileManager
        checkBoxes={checkBoxElements}
        setCheckBoxes={setCheckBoxElements}
        tipo_perfil={tipo_perfil}
        operacao={operacao}
        ordem_valores={ordem_valores}
        paginacao_ops={paginacao_ops}
        handleCheckBoxes={handleCheckBoxes}
      />
    </div>
  );
};

export default PercentagesAndProbabilities;
