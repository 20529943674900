import React from "react";
import { useState } from "react";
import ModalError from "../DataCategorization/ModalError/ModalError";
import OrderManager from "./OrderManager/OrderManager";

const ReportCostGeneralIndex: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [checkBoxElements, setCheckBoxElements] = useState<any[]>([]);
  const handleCheckBoxes = () => {
    checkBoxElements.forEach((checkbox: any) => {
      if (checkbox.checked) {
        return true;
      }
    });
    return false;
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  return (
    <div className="page-height p-10">
      <ModalError isOpen={isOpen} closeModal={closeModal} message=""></ModalError>
      <OrderManager
        checkBoxes={checkBoxElements}
        setCheckBoxes={setCheckBoxElements}
        handleCheckBoxes={handleCheckBoxes}
      />
    </div>
  );
};

export default ReportCostGeneralIndex;
