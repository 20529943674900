import React, { useEffect, useState } from "react"
import styled from "styled-components"
import http from "../../../plugins/http"
import Loading from "../../../components/Loading"
import { Button } from 'primereact/button'
import { Message } from "primereact/message"
import { InputText } from "primereact/inputtext"

const ArrowRight = styled.div`
  height: 0px;
  width: 0px;
  border-top: 25px solid transparent;
  border-left: 20px solid #146600;
  border-bottom: 25px solid transparent;
`

const ItemEndpointPage: React.FC<any> = ({ model }: { model: any }) => {
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState("")
  const [mapped, setMapped] = useState<any[]>([])
  const [mappedValue, setMappedValue] = useState<any>({})

  async function save() {
    setLoading(true)
    await http.post("/v1/module-parameters/mapping-endpoint", { endpoint: model.endpoint, mapping: mappedValue })
    await loadAlias({ loading: false })
    setLoading(false)
    setMessage("Mapeamento salvo com sucesso!")
    setTimeout(() => {
      setMessage("")
    }, 2000)
  }

  async function loadAlias({ loading = true, justReturn = false }: { loading?: boolean; justReturn?: boolean } = {}) {
    if (loading) setLoading(true)
    const res = await http.post(`/v1/module-parameters/mapping-endpoint/detail`, {
      endpoint: model.endpoint,
    })
    if (justReturn) return res.data
    for (let key in res.data) {
      console.log(key)
      const idx = mapped.findIndex((x: any) => x.field === key)
      if (idx !== -1) {
        mapped[idx].as = res.data[key]
      }
    }
    if (loading) setLoading(false)
  }

  async function mapJsonKeys() {
    const alias = await loadAlias({ justReturn: true })
    let data = model.response
    if (model.response.hasOwnProperty("data")) {
      data = model.response.data
    }
    if (typeof data === "object" && !Array.isArray(data)) {
      const keys = Object.keys(data)
      const mapped = keys.map(key => {
        const item = alias.find((x: any) => x.field === key)
        console.log(item)
        return {
          field: key,
          type: typeof data[key],
          value: data[key],
          //as: item ? item.as : "",
        }
      })
      setMapped(mapped)
      console.log(mapped)

      return
    }
    const keys = Object.keys(data[0])
    const mapped = keys.map(key => {
      const item = alias.find((x: any) => x.field === key)
      return {
        field: key,
        type: typeof data[0][key],
        value: data[0][key],
        as: item ? item.as : "",
      }
    })
    setMapped(mapped)
    setMappedValue(mapped)
    loadAlias()
  }

  function setValueMapping(field: string, event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value
    mappedValue[field] = value

    setMappedValue(mappedValue)
  }

  useEffect(() => {
    mapJsonKeys()
  }, [])

  return (
    <div
      className="max-h-screen border-in items-center grid place-content-center 
    relative pt-3 pb-2 2xl:!pb-5"
    >
      <div>
        <div className="flex">
          <div
            className="h-[50px] 2xl:w-[440px] xl:w-[350px] lg:w-[270px] sm:w-[190px] 
          w-[130px] bg-[#146600] text-white text-lg inline-block  
          pt-2 pl-4 overflow-hidden"
          >
            Origem
          </div>
          <ArrowRight
            className="bg-[#85D46C] text-white text-lg inline-block 
          overflow-hidden"
          ></ArrowRight>
          <div
            className="h-[50px] 2xl:w-[440px] xl:w-[350px] lg:w-[270px] sm:w-[190px] 
          w-[130px] bg-[#85D46C] text-[#3D3D3D] text-lg inline-block  pt-2 pl-4 overflow-hidden"
          >
            Destino
          </div>
        </div>
        <div className="select-box-list" style={{ boxShadow: "0px 3px 6px #0000005C" }}>
          <div
            className="2xl:w-[440px] xl:w-[350px] lg:w-[270px] sm:w-[190px] 
        w-[calc((100%-20px)/2)] bg-[#02314F] overflow-y-scroll pl-4 pt-5"
          >
            {mapped.map((item: any) => (
              <div className="w-full relative" key={`prop-${model.name}-${item.field}`}>
                <InputText
                  disabled
                  className="border  rounded-0 w-5/6 max-w-xs inline-block"
                  placeholder={item.field}
                ></InputText>
                <div className="mt-1 ml-2 mb-2 text-white">Tipo: {item.type}</div>
              </div>
            ))}
          </div>

          <div
            className="2xl:w-[460px] xl:w-[370px] lg:w-[290px] sm:w-[210px] 
        w-[calc((100%+20px)/2)] bg-[#F9F9F9] flex flex-col items-center px-2 pt-5 overflow-y-scroll"
          >
            {mapped.map((item: any) => (
              <div className="w-full relative" key={`prop2-${model.name}-${item.field}`}>
                <InputText
                  className="border-[#33748B] rounded-0 w-full  inline-block"
                  placeholder={item.as}
                  onChange={event => setValueMapping(item.field, event)}
                ></InputText>
                <div className="mt-1 ml-2 mb-2 text-gray-400">{item.field}</div>
              </div>
            ))}
          </div>
        </div>

        <div className={`w-full flex flex-wrap ${message === "" ? "justify-end" : "justify-between"} `}>
          {message !== "" && (
            <Message color="success" className=" text-lg inline-block mt-3 pt-2 pl-4 overflow-hidden">
              <p>{message}</p>
            </Message>
          )}
          <Button
            onClick={save}
            className="bg-[#205F16] max-sm:text-xs hover:bg-[#479632]
         right-0 flex flex-wrap gap-x-2 mt-4 h-10"
            disabled={loading}
          >
            {loading && <Loading />}
            SALVAR
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ItemEndpointPage
