import { Header, Footer } from "../components";
import { MainContainer, MainContent } from "./styles";

interface IFullContainer {
  children: React.ReactNode;
}

export const FullLayout = ({ children }: IFullContainer) => {
  return (
    <MainContainer>
      <MainContent>
        <div className="w-full flex flex-col h-screen">
          <Header />
          <div className="w-full flex-1 bg-[#F2F2F2] p-2">{children}</div>
          <Footer />
        </div>
      </MainContent>
    </MainContainer >
  );
};
