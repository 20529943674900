import { Block } from "@styled-icons/boxicons-regular";
import { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

interface Props {
  isOpen: boolean;
  title: string;
  body: string;
  buttonLabel: string;
  urlToRedirect?: string | null;
  handleFunction?: () => void;
}

export const ConfirmDeleteDemand = ({
  title,
  body,
  buttonLabel,
  isOpen = false,
  urlToRedirect = null,
  handleFunction,
  ...args
}: Props) => {
  const [modal, setModal] = useState(isOpen);

  const toggle = () => {
    setModal(!modal);

    if (urlToRedirect !== null) {
      window.location.href = `/${urlToRedirect}`;
    }
  };

  const footer = (
    <div className="flex justify-end gap-2">
      <Button
        label="Cancelar"
        className="p-button-outlined p-button-secondary"
        onClick={toggle}
      />
      <Button
        label="Confirmar"
        className="p-button-danger"
        onClick={() => {
          if (handleFunction) handleFunction();
          toggle();
        }}
      />
    </div>
  );

  return (
    <div>
      <button onClick={toggle}>
        <Block size={22} color="#F91B1B" title="Desativar" />
      </button>

      <Dialog
        header={title}
        visible={modal}
        footer={footer}
        onHide={toggle}
        {...args}
        className="w-full md:w-2/3"
      >
        <div className="text-base">
          {body}
        </div>
      </Dialog>
    </div>
  );
};
