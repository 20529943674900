import React, { useState } from 'react';
import { useField } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Eye, EyeSlash } from '@styled-icons/fa-solid';
import ErrorMessage from './ErrorMessage';
import styled from 'styled-components';

const InputPassword = styled(InputText)`
  &:focus {
    border-color: transparent;
    box-shadow: 0 0 0 0.1rem rgb(166, 203, 243);
  }
`;

interface IFormikPassword {
  style?: object;
  label: string;
  placeholder: string;
  name: string;
}

const FormikPassword: React.FC<IFormikPassword> = ({ name, label, placeholder, style }: IFormikPassword) => {
  const [field, meta] = useField(name);
  const [type, setType] = useState('password');

  return (
    <div style={style} className="w-full flex flex-col items-start relative">
      <label className={`font-sans Roboto font-normal max-sm:w-64 mb-0 ${meta.touched && meta.error ? 'text-[#DC3545]' : ''}`}>
        {label}
      </label>
      <div className="w-full relative">
        <InputPassword
          {...field}
          className={`font-sans max-sm:w-64 w-full ${meta.touched && meta.error ? 'border-[#DC3545]' : ''}`}
          placeholder={placeholder}
          type={type}
        />
        <span
          className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
          onClick={() => {
            setType(type === 'password' ? 'text' : 'password');
          }}
        >
          {type === 'password' ? (
            <Eye className="opacity-25" size="18px" />
          ) : (
            <EyeSlash className="opacity-25" size="18px" />
          )}
        </span>
      </div>
      {meta.error && meta.touched && <ErrorMessage message={meta.error} />}
    </div>
  );
};

export default FormikPassword;
