import { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import http from "../../../plugins/http";
import Loading from "../../../components/Loading";
import { useToast } from "../../../components/Toast";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { BreadCrumb } from "primereact/breadcrumb";

const GeralReportTable: React.FC = () => {
  const today = new Date();
  const prevMonth = new Date();
  prevMonth.setMonth(today.getMonth() - 1);
  const toast = useToast();
  const [users, setUsers] = useState([]);
  const [meta, setMeta] = useState<any>({});
  const [productGroups, setProductGroups] = useState<any[]>([]);
  const [productTypes, setProductTypes] = useState<any[]>([]);
  const [productSubtypes, setProductSubtypes] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    status: 'null',
    role: 0,
    dateInitial: prevMonth.toISOString().split("T")[0],
    dateFinal: new Date().toISOString().split("T")[0],
    product_group: null,
    product_type: null,
    product_subtype: null,
    page: 1,
    perPage: 100,
  });

  const userProfiles = [
    { public_name: "Comprador e vendedor", value: 0 },
    { public_name: "Vendedor", value: 1 },
    { public_name: "Comprador", value: 2 },
    { public_name: "Administrativo", value: 3 },
    { public_name: "Quem já comprou", value: 4 },
    { public_name: "Quem já vendeu", value: 5 },
  ]

  const userSituation = [
    { public_name: "Todos", value: 'null' },
    { public_name: "Ativados", value: 1 },
    { public_name: "Desativados", value: 0 },
  ]

  async function fetchReportData(filter: any) {
    try {
      setLoading(true);
      let body = {
        id_role: filter.role,
        date_initial: filter.dateInitial,
        date_final: filter.dateFinal,
        id_product_group: filter.product_group?.id,
        id_product_type: filter.product_type?.id,
        id_product_subtype: filter.product_subtype?.id,
        status: filter.status,
      }
      const response = await http.post("v1/reports/geral", body);
      if (response.status === 200) {
        setMeta(response.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function fetchProductGroups() {
    try {
      setLoading(true);
      const response = await http.get("v1/product-groups?all=1");
      if (response.status === 200) {
        setProductGroups([{ id: undefined, name: 'Todas' }, ...response.data]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function fetchProductTypes() {
    try {
      setLoading(true);
      const responseTypes = await http.get("v1/product-types?all=1");
      setLoading(false);
      if (responseTypes.status === 200) {
        return responseTypes.data;
      }
      return [];
    } catch (error) {
      console.log(error);
      setLoading(false);
      return [];
    }
  }

  async function fetchProductSubtypes() {
    try {
      setLoading(true);
      const responseSubtypes = await http.get("v1/product-subtypes?all=1");
      setLoading(false);
      if (responseSubtypes.status === 200) {
        return responseSubtypes.data;
      }
      return [];
    } catch (error) {
      console.log(error);
      setLoading(false);
      return [];
    }
  }

  useEffect(() => {
    fetchProductGroups();
    fetchReportData(filters);
  }, []);


  const handleFilterChange = (e: any) => {
    const { name, value } = e.target;
    if (name === 'product_group') {
      setFilters({ ...filters, product_type: null, product_subtype: null, product_group: value });
      fetchProductTypes().then((data) => {
        setProductTypes([{ id: undefined, name: 'Todos' }, ...data.filter((type: any) => type.id_product_group === value.id)]);
      });
    } else if (name === 'product_type') {
      setFilters({ ...filters, product_subtype: null, product_type: value });
      fetchProductSubtypes().then((data) => {
        setProductSubtypes([{ id: undefined, name: 'Todos' }, ...data.filter((subtype: any) => subtype.id_product_type === value.id)]);
      });
    } else {
      setFilters({ ...filters, [name]: value });
    }
  };


  const changePage = (page: number) => {
    setFilters({ ...filters, page });
    fetchReportData({ ...filters, page });
  }

  const renderHeader = (title: any) => (
    <div className="font-bold text-green-500 text-[16px] ">
      {title}
    </div>
  );

  const renderValue = (value: any) => (
    <div className="text-sm">
      {value}
    </div>
  );

  const items = [{ label: 'Relatórios', url: '/reports' }, { label: 'Relatório Geral' }];
  const home = { icon: 'pi pi-home', url: '/home' }

  return (
    <div className="w-full p-10">
      <BreadCrumb model={items} home={home} className="mb-4" />
      <div className="flex flex-wrap">
        <div className="w-2/4 flex">
          <div className="w-1/2 flex flex-col pr-2">
            <label className="text-md font-bold mb-1">Data Inicial</label>
            <InputText
              value={filters.dateInitial}
              name="dateInitial"
              onChange={handleFilterChange}
              type="date"
            />
          </div>
          <div className="w-1/2 flex flex-col pl-2">
            <label className="text-md font-bold mb-1">Data Final</label>
            <InputText
              value={filters.dateFinal}
              name="dateFinal"
              onChange={handleFilterChange}
              type="date"
            />
          </div>
        </div>
        <div className="w-1/4 flex flex-col pl-2">
          <label className="text-md font-bold mb-1">Cadeia</label>
          <Dropdown name="product_group" value={filters.product_group} options={productGroups} onChange={handleFilterChange} optionLabel="name" className="!w-full" placeholder="Cadeia" />
        </div>
        <div className="w-1/4 flex flex-col pl-2">
          <label className="text-md font-bold mb-1">Tipo</label>
          <Dropdown name="product_type" value={filters.product_type} options={productTypes} onChange={handleFilterChange} disabled={!filters.product_group} optionLabel="name" className="!w-full" placeholder="Tipo" />
        </div>
        <div className="w-1/4 flex flex-col">
          <label className="text-md font-bold mb-1">Subtipo</label>
          <Dropdown name="product_subtype" value={filters.product_subtype} options={productSubtypes} onChange={handleFilterChange} disabled={!filters.product_type} optionLabel="name" className="!w-full" placeholder="Subtipo" />
        </div>
        <div className="w-1/4 flex flex-col pl-2">
          <label className="text-md font-bold mb-1">Tipo de usuário</label>
          <Dropdown name="role" value={filters.role} options={userProfiles} onChange={handleFilterChange} optionLabel="public_name" className="!w-full" placeholder="Tipo de usuário" />
        </div>
        <div className="w-1/4 flex flex-col pl-2">
          <label className="text-md font-bold mb-1">Situação</label>
          <Dropdown name="status" value={filters.status} options={userSituation} onChange={handleFilterChange} optionLabel="public_name" placeholder="Situação" className="!w-full" />
        </div>
        <div className="w-1/4 flex flex-col pl-2">
          <label className="text-md font-bold mb-1">&nbsp;</label>
          <Button
            disabled={loading}
            onClick={() => fetchReportData(filters)}
            className="uppercase text-center"
            severity="success"
          >
            Filtrar
          </Button>
        </div>
        {/* <div className="w-1/4 flex flex-col">
          <label className="text-md font-bold mb-1">&nbsp;</label>
          <Button
            disabled={loading}
            onClick={() => fetchReportData(filters)}
            className="uppercase w-1/4 text-center px-2 text-[15px] font-nunito h-[38px] font-bold text-green-500 hover:!bg-[#85D46C] border-1 hover:border-0 w-full"
          >
            Exportar
          </Button>
        </div> */}
      </div>

      {loading && (
        <div className="w-full h-36 grid place-content-center p-20">
          <Loading color="#212529" size="w-10" />
        </div>
      )}
      {/* {!loading && users.length === 0 && (
        <div className="w-full h-36 grid place-content-center p-20">
          <p>Nenhuma informação encontrada com os filtros aplicados</p>
        </div>
      )} */}
      <div className="h-full w-full mt-10 overflow-auto">

        <DataTable showGridlines value={[meta]} className="w-full font-roboto">
          <Column header={renderHeader("Qtd. demandas cadastradas")} body={(rowData: any) => renderValue(rowData?.demands?.length)} />
          <Column header={renderHeader("Qtd. propostas de vendedores cadastrados")} body={(rowData: any) => renderValue(rowData?.demandsOffers?.length)} />
          <Column header={renderHeader("Qtd. de Vendas efetuadas")} body={(rowData: any) => renderValue(rowData?.sells?.length)} />
          <Column header={renderHeader("Qtd. novos vendedores cadastrados")} body={(rowData: any) => renderValue(rowData?.novosVendedores?.length)} />
          <Column header={renderHeader("Qtd. novos compradores cadastrados")} body={(rowData: any) => renderValue(rowData?.novosCompradores?.length)} />
          <Column header={renderHeader("Qtd. vendedores que venderam pela primeira vez")} body={(rowData: any) => renderValue(rowData?.novosVendedoresQueVenderam?.length)} />
          <Column header={renderHeader("Qtd. novos compradores com demandas")} body={(rowData: any) => renderValue(rowData?.novosCompradoresComDemandas?.length)} />
          <Column header={renderHeader("Qtd. compradores que compraram pela primeira vez")} body={(rowData: any) => renderValue(rowData?.novosCompradoresQueCompraram?.length)} />
          <Column header={renderHeader("Qtd. de anuncios ativos")} body={(rowData: any) => renderValue(rowData?.novosCompradoresQueCompraram?.length)} />
          <Column header={renderHeader("Qtd. em KG futuros")} body={(rowData: any) => renderValue(rowData?.novosCompradoresQueCompraram?.length)} />
        </DataTable>

        <DataTable showGridlines value={[meta]} className="w-full font-roboto">
          <Column header={renderHeader("Qtd. de anúncios")} body={(rowData: any) => renderValue(rowData?.totalDeNovosProdutos?.length)} />
          {meta.totalDeVendasPorUnidadeDeMedida && Object.keys(meta.totalDeVendasPorUnidadeDeMedida).map((key) => (
            <Column key={key} header={renderHeader(`Total em ${key}`)} body={(rowData: any) => renderValue(rowData.totalDeVendasPorUnidadeDeMedida[key])} />
          ))}
          <Column header={renderHeader("Qtd. de ofertas em demandas")} body={(rowData: any) => renderValue(rowData?.totalDeOfertasEmDemandas?.length)} />
          <Column header={renderHeader("Total de vendas")} body={(rowData: any) => renderValue(rowData?.totalVendasEmReal)} />
        </DataTable>
      </div>
    </div>
  );
};

export default GeralReportTable;
