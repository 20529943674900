import { Form, Formik } from "formik";
import { useState, memo, useEffect } from "react";
import FormikSelect from "../../../components/FormikSelect";
import PageHeader from "../../../components/PageHeader";
import Loading from "../../../components/Loading";
import ModalFinancialDetails from "../ModalFinancialDetails/Modal";
import http from "../../../plugins/http";
import FormikInput from "../../../components/FormikInput";
import TablePagination from "../../../components/TablePagination";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

const ProfileManager = memo(function ProfileManager(args: any) {
  const [key_word, setKeyWord] = useState("");
  const [meta, setMeta] = useState<any>({});
  const [perfil, setPerfil] = useState(null);
  const [operation, setOperation] = useState(null);
  const [order, setOrder] = useState(null);
  const [pagination, setPagination] = useState(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedDatas, setSelectedDatas] = useState<any[]>([]);
  const [selections, setSelections] = useState<Record<string, boolean>>({});
  const [initialValues, setInitialValues] = useState<any>({
    key_word: "",
    perfil: "",
    operation: "",
    order: "",
    pagination: "",
  });
  const [datas, setDatas] = useState<any[]>([]);
  const [defaultDatas, setDefaultDatas] = useState<any[]>([]);
  const tipo_perfil = args.tipo_perfil;
  const operacao = args.operacao;
  const ordem_valores = args.ordem_valores;
  const pagination_op = args.paginacao_ops;

  const fetchByers = async ({
    page = 1,
    perPage = 10,
    search,
  }: { page?: number; perPage?: number; search?: string } = {}) => {
    try {
      setLoading(true);
      const searchData = search ? `&search=${search}` : "";
      const response = await http.get(
        `v1/percentages-and-probabilities/buyer?all=1&perPage=${perPage}&page=${page}${searchData}`,
      );
      if (response.status !== 200) {
        return;
      }
      setDatas(response.data.data);
      setMeta(response.data.meta);
      setDefaultDatas([...response.data.data]);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSellers = async ({
    page = 1,
    perPage = 10,
    search,
  }: { page?: number; perPage?: number; search?: string } = {}) => {
    try {
      setLoading(true);
      const searchData = search ? `&search=${search}` : "";
      const response = await http.get(
        `v1/percentages-and-probabilities/seller?all=1&perPage=${perPage}&page=${page}${searchData}`,
      );
      if (response.status !== 200) {
        return;
      }
      setDatas(response.data.data);
      setMeta(response.data.meta);
      setDefaultDatas([...response.data.data]);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchBuyersAndSellers = async ({
    page = 1,
    perPage = 10,
    search,
  }: { page?: number; perPage?: number; search?: string } = {}) => {
    try {
      setLoading(true);
      const searchData = search ? `&search=${search}` : "";
      const response = await http.get(
        `v1/percentages-and-probabilities/buyer-and-seller?all=1&perPage=${perPage}&page=${page}${searchData}`,
      );
      if (response.status !== 200) {
        return;
      }
      setDatas(response.data.data);
      setMeta(response.data.meta);
      setDefaultDatas([...response.data.data]);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const setPerfilValue = (value: string) => {
    if (value === "") return setPerfil(null);
    const perfilValue = args.tipo_perfil.find((p: { value: string }) => p.value === value) as any;
    setPerfil(perfilValue);
    setInitialValues({ ...initialValues, perfil: value });
  };

  const fetchByOperation = async (operation?: number | string | null, page = 1) => {
    if (operation == 1) fetchByers({ page });
    else if (operation == 2) fetchSellers({ page });
    else fetchBuyersAndSellers({ page });
  };

  const setOperationValue = (value: string) => {
    if (value === "") return setOperation(null);
    setOperation(operation);
    setInitialValues({ ...initialValues, operation: value });
    fetchByOperation(value);
  };

  const setOrderValue = (value: string) => {
    if (value === "") {
      return setOrder(null);
    }
    const order = args.ordem_valores.find((p: { value: string }) => p.value === value) as any;
    setOrder(order);
    setInitialValues({ ...initialValues, order: value });
  };

  const setPaginationValue = (value: string) => {
    if (value === "") {
      return setPagination(null);
    }
    const pagination = args.paginacao_ops.find((p: { value: string }) => p.value === value) as any;
    setPagination(pagination);
    setInitialValues({ ...initialValues, pagination: value });
  };

  function toggleItemSelection(id: any) {
    setSelections(prevState => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  }
  const uncheckAll = () => {
    const checkboxes = document.querySelectorAll("input[type=checkbox]");
    checkboxes.forEach((checkbox: any) => {
      checkbox.checked = false;
    });
  };

  function toggleAll() {
    const allSelected = Object.keys(selections).length === datas.length;

    if (allSelected) {
      setSelections({});
    } else {
      const newSelections: { [key: string]: boolean } = {};
      datas.forEach(data => {
        newSelections[data.id] = true;
      });
      setSelections(newSelections); // Seleciona todos
    }
  }

  //função que verificar se tem algum item selecionado
  const hasSelected = (): boolean => {
    return Object.values(selections).some(val => val);
  };

  const sortData = (ordemSelecionada: number) => {
    const dataSorted = [...datas];
    dataSorted.sort((a, b) => {
      let result = 0;

      switch (ordemSelecionada) {
        case 1: // "Maior para o menor"
          // Considerando que você queira ordenar pelo campo "name" como exemplo
          result = b.id - a.id;
          break;

        case 2: // "Menor para o maior"
          result = a.id - b.id;
          break;

        case 3: // "Alfabética crescente"
          result = a.name.localeCompare(b.name);
          if (result === 0) result = a.cpf_cnpj.localeCompare(b.cpf_cnpj);
          if (result === 0) result = a.email.localeCompare(b.email);
          if (result === 0) result = a.phone.localeCompare(b.phone);
          break;

        case 4: // "Alfabética decrescente"
          result = b.name.localeCompare(a.name);
          if (result === 0) result = b.cpf_cnpj.localeCompare(a.cpf_cnpj);
          if (result === 0) result = b.email.localeCompare(a.email);
          if (result === 0) result = b.phone.localeCompare(a.phone);
          break;

        default:
          break;
      }

      return result;
    });

    setDatas(dataSorted);
  };

  const filterData = (e: any, type: string) => {
    const filteredData = defaultDatas.filter((data: any) => {
      if (type === "input") {
        return (
          data.name?.toLowerCase().includes(e.toLowerCase()) ||
          data.cpf_or_cnpj?.toLowerCase().includes(e.toLowerCase()) ||
          data.email?.toLowerCase().includes(e.toLowerCase()) ||
          data.phone?.toLowerCase().includes(e.toLowerCase())
        );
      } else if (type === "perfil") {
        const perfilValue = args.tipo_perfil.find((p: { value: string }) => p.value === e) as any;
        return data.perfil?.toLowerCase().includes(perfilValue.label.toLowerCase());
      } else if (type === "operation") {
        // const operation = args.operacao.find((p: { value: string }) => p.value === e) as any;
        // return data.operation.toLowerCase().includes(operation.label.toLowerCase());
        return data;
      }
      return data;
    });
    setDatas(filteredData);
  };

  const filterSelectedUsers = () => {
    console.log("vai fazer a consulta no banco de dados do pessoal que foi mandado para a modal");
  };

  useEffect(() => {
    fetchBuyersAndSellers();
  }, []);

  useEffect(() => {
    const selected = datas.filter(data => selections[data.id]);
    setSelectedDatas(selected);
  }, [selections, datas]);

  return (
    <div className="h-screen bg-[#F2F2F2] px-5 py-5">
      <PageHeader>Porcentagens e Probabilidades de perfil </PageHeader>

      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          setPerfilValue(values.perfil);
          setOperationValue(values.operation);
          setOrderValue(values.order);
          setPaginationValue(values.pagination);
        }}
        onReset={(values, { resetForm }) => {
          setInitialValues({
            key_word: "",
            perfil: "",
            operation: "",
            order: "",
          });
          fetchByOperation("3");
        }}
        key={"form-search-percentages-probabilities"}
      >
        {({ values, resetForm }) => (
          <Form className="flex flex-col space-y-3">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 gap-4 items-center w-full relative rounded-lg p-6 border border-[#707070]">
              <FormikInput
                className="col-span-1 w-full max-w-full p-1"
                labelClass="block max-w-[80%] truncate"
                label="Palavra chave:"
                name="key_word"
                type="text"
                onChanged={(e: React.ChangeEvent<HTMLInputElement>) => {
                  filterData(e.target.value, "input");
                  setKeyWord(e.target.value);
                }}
              />
              <FormikSelect
                className="col-span-1"
                labelClass="block max-w-[80%] truncate"
                label="Tipo de perfil:"
                name="perfil"
                options={tipo_perfil}
                onChange={e => {
                  setPerfilValue(e);
                  filterData(e, "perfil");
                }}
                type="select"
              />
              <FormikSelect
                className="col-span-1"
                labelClass="block max-w-[80%] truncate"
                label="Operação realizada:"
                name="operation"
                options={operacao}
                onChange={e => {
                  setOperationValue(e);
                  filterData(e, "operation");
                }}
                type="select"
              />
              <FormikSelect
                className="col-span-1"
                labelClass="block max-w-[80%] truncate"
                label="Ordem dos valores:"
                name="order"
                options={ordem_valores}
                onChange={e => {
                  setOrderValue(e);
                  sortData(Number(e));
                }}
                type="select"
              />
              <FormikSelect
                className="col-span-1"
                labelClass="block max-w-[80%] truncate"
                label="Paginação"
                name="pagination"
                options={pagination_op}
                onChange={e => {
                  setPaginationValue(e);
                }}
                type="select"
              />
            </div>
            <div className="flex flex-col md:flex-row items-center justify-end gap-4 w-full">
              <Button
                disabled={
                  !(key_word.length !== 0) &&
                  !(values.perfil !== "") &&
                  !(values.operation !== "") &&
                  !(values.order !== "") &&
                  !(values.pagination !== "")
                }
                type="reset"
                className="w-full md:w-auto flex-shrink-0 align-middle uppercase bg-[#FFFFFF] hover:bg-[#28A745] text-[#146600] font-bold hover:border-[#28A745] text-[15px] font-nunito h-[38px]  border-0 hover:border-0"
                onClick={() => {
                  resetForm();
                  setKeyWord("");
                }}
              >
                LIMPAR
              </Button>
              <ModalFinancialDetails
                onOpen={uncheckAll}
                filterSelectedUsers={filterSelectedUsers}
                hasSelected={hasSelected}
                datas={[...selectedDatas]}
                cleanSelections={setSelections}
              />
            </div>
          </Form>
        )}
      </Formik>

      {loading && (
        <div className="w-full h-36 grid place-content-center p-20">
          <Loading color="#212529" size="w-10" />
        </div>
      )}

      {!loading && datas?.length === 0 && (
        <div className="w-full h-36 grid place-content-center p-20">
          <p>Nenhum usuário encontrado</p>
        </div>
      )}
      {!loading && datas?.length > 0 && (
        <div className="h-4/6 overflow-auto" style={{ marginTop: "94px" }}>
          <table className="font-roboto border-[#DEE2E6]">
            <thead className="bg-white">
              <tr className="font-bold-roboto text-[18px] text-[#212529]">
                <th>
                  <div className="flex justify-center items-center pb-2">
                    <InputText
                      type="checkbox"
                      onChange={toggleAll}
                      checked={Object.keys(selections).length === datas.length}
                    />
                  </div>
                </th>
                <th>
                  <div className="flex justify-left items-left">Nome</div>
                </th>
                <th>
                  <div className="flex justify-center items-center">CPF/CNPJ</div>
                </th>
                <th className="hidden md:table-cell">
                  <div className="flex justify-center items-center">Email</div>
                </th>
                <th className="hidden lg:table-cell">
                  <div className="flex justify-center items-center">Celular</div>
                </th>
                <th className="hidden xl:table-cell">
                  <div className="flex justify-center items-center">Estado</div>
                </th>
                <th className="hidden xl:table-cell">
                  <div className="flex justify-center items-center">Cidade</div>
                </th>
                <th>
                  <div className="flex justify-center items-center">Perfil</div>
                </th>
                <th>
                  <div className="flex justify-center items-center">Status</div>
                </th>
              </tr>
            </thead>
            <tbody className="border-b">
              {datas.map(data => (
                <tr key={data.id} className="bg-white">
                  <td>
                    <div className="flex justify-center items-center pt-1">
                      <InputText
                        type="checkbox"
                        onChange={() => toggleItemSelection(data.id)}
                        checked={!!selections[data.id]}
                      />
                    </div>
                  </td>
                  <td>{data.name}</td>
                  <td>{data.cpf_or_cnpj}</td>
                  <td className="hidden md:table-cell">{data.email}</td>
                  <td className="hidden lg:table-cell">{data.phone}</td>
                  <td className="hidden xl:table-cell">{data?.state?.name}</td>
                  <td className="hidden xl:table-cell">{data?.city?.name}</td>
                  <td>{data.perfil}</td>
                  <td className="text-center">
                    {data.status === 1 ? (
                      <p className="text-center text-[#28A745]">Ativo</p>
                    ) : (
                      <p className="text-center text-red-500">Desativado</p>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="flex justify-end">
            <TablePagination meta={meta} onChange={page => fetchByOperation(operation, page)} />
          </div>
        </div>
      )}
    </div>
  );
});

export default ProfileManager;
